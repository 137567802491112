import { parse } from 'query-string';
const destinationKeys = ['regionId', 'selected', 'latLong', 'destination'];
export const shouldSearchHashParams = query => typeof window !== 'undefined' && query && Object.keys(query).length === 0 && Boolean(window.location.hash && typeof window.location.hash === 'string' && window.location.hash.length > 0);
export const convertHashParams = () => {
  const {
    hash
  } = window.location;
  if (hash && hash.length > 0) {
    return parse(hash);
  }
};
export const shouldHashOverrideDefaultValues = params => {
  if (params && Object.keys(params).length > 0) {
    return destinationKeys.some(key => typeof params[key] !== 'undefined');
  }
  return false;
};