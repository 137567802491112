/**
 * adaptSelectionsFromEnums
 *
 * Does a lookup by value on a string enum with identical key/value pairs.
 * Ensures value is a valid enum member.
 *
 * @param id example: 'structureTypes'
 * @param key example: 'lodging'
 * @param criteria example: `{ lodging: ['CONDO', 'APARTMENT'] }`
 * @param type example: `StructureType`
 *
 * Output: `[
 *  { id: 'structureTypes', value: 'CONDO' },
 *  { id: 'structureTypes', value: 'APARTMENT' }
 * ]`
 */
export function adaptSelectionsFromEnums(id, key, criteria, type) {
  const criteriaValue = criteria[key];
  if ((criteriaValue === null || criteriaValue === void 0 ? void 0 : criteriaValue.length) > 0) {
    return criteriaValue.map(filter => {
      var _a;
      const filterType = (_a = type[filter]) !== null && _a !== void 0 ? _a : '';
      const value = type[filterType];
      if (value) {
        return {
          id,
          value
        };
      }
      return undefined;
    });
  }
  return [];
}
/**
 * adaptSelectionFromEnum
 *
 * Does a lookup by value on a string enum with identical key/value pairs.
 * Ensures value is a valid enum member.
 *
 * @param id example: 'sort'
 * @param key example: 'sort'
 * @param criteria example: `{ sort: 'VACATION_RENTAL' }`
 * @param type example: `PropertySort`
 *
 * Output: `{ id: 'sort', value: 'VACATION_RENTAL' }`
 */
export function adaptSelectionFromEnum(id, key, criteria, type) {
  var _a;
  const criteriaValue = criteria[key];
  if (criteriaValue) {
    const typeValue = (_a = type[criteriaValue]) !== null && _a !== void 0 ? _a : '';
    const value = type[typeValue];
    if (value) {
      return {
        id,
        value
      };
    }
  }
  return null;
}
/**
 * adaptSelectionFromString
 *
 * Transforms a key/value pair (string:string) into a generic object
 * with `id` and `value`
 *
 * @param id example: 'chain'
 * @param key example: 'chain'
 * @param criteria example: `{ chain: 'marriott' }`
 *
 * Output: `{ id: 'chain', value: 'marriott' }`
 */
export function adaptSelectionFromString(id, key, criteria) {
  const value = criteria[key];
  if (value && value.length > 0) {
    return {
      id,
      value
    };
  }
  return null;
}
/**
 * adaptSelectionFromString
 *
 * Transforms a key/value pair (string:strings) into a list of generic objects
 * with `id` and `value`
 *
 * @param id example: 'rewards'
 * @param key example: 'rewards'
 * @param criteria example: `{ rewards: ['first', 'second'] }`
 *
 * Output: `[
 *  { id: 'rewards', value: 'first' },
 *  { id: 'rewards', value: 'second' }
 * ]`
 */
export function adaptSelectionsFromStrings(id, key, criteria) {
  const criteriaValue = criteria[key];
  if ((criteriaValue === null || criteriaValue === void 0 ? void 0 : criteriaValue.length) > 0) {
    return criteriaValue.map(value => ({
      id,
      value
    }));
  }
  return [];
}
/**
 * adaptCountsFromStrings
 *
 * Transforms a key/value pair (string:strings) into a list of generic objects
 * with `id` and `value`
 *
 * @param id example: 'bedroomFilter'
 * @param key example: 'bedroomFilter'
 * @param criteria example: `{ bedroomFilter: ['2', '3', '4'] }`
 *
 * Output: `[
 *  { id: 'bedroomFilter', value: 2 },
 *  { id: 'bedroomFilter', value: 3 },
 *  { id: 'bedroomFilter', value: 4 }
 * ]`
 */
export function adaptCountsFromStrings(id, key, criteria) {
  const criteriaValue = criteria[key];
  if ((criteriaValue === null || criteriaValue === void 0 ? void 0 : criteriaValue.length) > 0) {
    return criteriaValue.map(value => ({
      id,
      value: parseInt(value, 10) || 0
    }));
  }
  return [];
}
/**
 * adaptRangesFromStrings
 *
 * Transforms a key/value pair (string:strings) into a list of generic objects
 * with `id`, `min` and `max`
 *
 * @param id example: 'price'
 * @param key example: 'price'
 * @param criteria example: `{ price: ['0', '100'] }`
 *
 * Output: `[
 *  { id: 'price', min: 0, max: 100 },
 * ]`
 */
export function adaptRangesFromStrings(id, key, criteria) {
  const criteriaValue = criteria[key];
  if ((criteriaValue === null || criteriaValue === void 0 ? void 0 : criteriaValue.length) === 2) {
    return [{
      id,
      min: parseInt(criteriaValue[0], 10) || 0,
      max: parseInt(criteriaValue[1], 10) || 0
    }];
  }
  return [];
}