import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { evaluateAndLogExperiment } from '../../../common/stores/utils';
export default function TaapRedirectPlugin() {
  return {
    name: 'taap-redirect',
    onServerCreateRenderContext(context, next) {
      var _a, _b;
      const {
        getAppData,
        response,
        request,
        logger
      } = context;
      try {
        const {
          experimentContextStore: experiments
        } = getAppData();
        const ENABLE_TAAP_REDIRECT = evaluateAndLogExperiment('TAAP_Use_ExpUserId_Routing', undefined, experiments);
        const TAAP_REDIRECT = evaluateAndLogExperiment('TAAP_New_Shopping_Experience_ExpUserId_Path_Switch', undefined, experiments);
        if (ENABLE_TAAP_REDIRECT && TAAP_REDIRECT && !((_a = context.request.context.deviceInformation) === null || _a === void 0 ? void 0 : _a.mobile)) {
          let redirectUrl = null;
          if (request.path.includes('Hotel-Search')) {
            redirectUrl = request.url.replace('Hotel-Search', 'Property-Search');
          } else {
            const captures = request.path.match(/(.*?)(?=\.).h(.*\d+).(Hotel-Information|Maklumat-Hotel|Description-Hotel|Hotelinfo|Hotellinformasjon|hotellitiedot|Hotel-Beschreibung|Hotel-Impormasyon|Informazioni-Hotel|Informacion-Hotel|Hotellbeskrivning|Description-Hotel|Hotelinfo|Thong-tin-khach-san|Hotel-Reservas)/);
            if (captures) {
              redirectUrl = `/Property-Details/${captures[2]}?`;
              redirectUrl += (_b = request.url.split('?')[1]) !== null && _b !== void 0 ? _b : '';
            }
          }
          if (redirectUrl) {
            response === null || response === void 0 ? void 0 : response.redirect(301, redirectUrl.toString());
          }
        }
      } catch (error) {
        const eventMessage = `Error constructing TAAP redirect URL, error=${error === null || error === void 0 ? void 0 : error.toString()}`;
        logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'TAAP_REDIRECT_UNKNOWN_FAILURE'), eventMessage);
      }
      return next(context);
    }
  };
}