export const isMobile = context => {
  var _a, _b;
  return Boolean(((_a = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _a === void 0 ? void 0 : _a.mobile) && !((_b = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _b === void 0 ? void 0 : _b.tablet));
};
export const isTablet = context => {
  var _a, _b;
  return Boolean(((_a = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _a === void 0 ? void 0 : _a.mobile) && ((_b = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _b === void 0 ? void 0 : _b.tablet));
};
export const isMobileOrTablet = context => {
  var _a, _b;
  return Boolean(((_a = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _a === void 0 ? void 0 : _a.mobile) || ((_b = context === null || context === void 0 ? void 0 : context.deviceInformation) === null || _b === void 0 ? void 0 : _b.tablet));
};