var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { clearCustomPlacesFromStorage, loadCustomPlacesFromStorage, saveCustomPlacesInStorage } from './map-store-utils';
export const CUSTOM_PLACES_KEY = 'custom_places';
export class MapStore extends Store {
  isServerSide() {
    return typeof window === 'undefined'; // eslint-disable-line
  }
  constructor(state = {}, logger = NOOP_LOGGER) {
    super(state, logger);
    this.id = Math.random();
    if (!this.isServerSide()) {
      this.customPlaces = loadCustomPlacesFromStorage();
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hydrate(data) {
    // dont need to hydrate
  }
  clearCustomPlaces() {
    this.customPlaces = MapStore.DEFAULTS.customPlaces;
    clearCustomPlacesFromStorage();
  }
  addCustomPlace(customPlace) {
    if (!this.customPlaces) {
      this.customPlaces = [];
    }
    this.customPlaces.push(customPlace);
    saveCustomPlacesInStorage(this.customPlaces);
  }
  setCustomPlace(customPlace) {
    if (customPlace) {
      this.customPlaces = [customPlace];
      saveCustomPlacesInStorage(this.customPlaces);
    } else {
      this.clearCustomPlaces();
    }
  }
}
MapStore.DEFAULTS = {
  customPlaces: []
};
__decorate([observable], MapStore.prototype, "customPlaces", void 0);
__decorate([action], MapStore.prototype, "clearCustomPlaces", null);
__decorate([action], MapStore.prototype, "addCustomPlace", null);
__decorate([action], MapStore.prototype, "setCustomPlace", null);