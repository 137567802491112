const RFRR_PAGE_IDENTIFIER = 'HOT.HIS.';
export const singleOfferLargeTemplate = {
  id: 'single-offer-large',
  version: '1',
  components: [{
    metadata: {
      id: 'LayoutFlex'
    },
    type: 'EGDSComponent',
    config: {
      type: 'EGDSLayoutFlex',
      grow: 1
    },
    children: [{
      type: 'EGDSComponent',
      metadata: {
        id: 'EGDSLayoutFlexItemMainArea'
      },
      config: {
        type: 'EGDSLayoutFlexItem',
        grow: 1
      },
      children: [{
        metadata: {
          id: 'simpleContainer1'
        },
        type: 'LayoutGrid',
        config: {
          columns: 3
        },
        children: [{
          metadata: {
            id: 'top-row'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'back-to-search-link'
            },
            type: 'PropertySummaryBackLinkWrapper'
          }]
        }, {
          metadata: {
            id: 'property-top'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'property-gallery-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyGallery`,
              linkName: 'Property Gallery'
            },
            children: [{
              metadata: {
                id: 'property-gallery'
              },
              type: 'PropertyGallery'
            }]
          }, {
            metadata: {
              id: 'property-navigation-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyNavigation`,
              linkName: 'Property Navigation'
            },
            children: [{
              metadata: {
                id: 'property-navigation'
              },
              type: 'PropertyNavigation',
              config: {
                showReserveBtn: true,
                showNightlyPrice: true,
                showReserveBtnOnLoad: false
              }
            }]
          }]
        }, {
          metadata: {
            id: 'column-1'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 2
          },
          children: [{
            metadata: {
              id: 'property-summary-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertySummary`,
              linkName: 'Property Summary'
            },
            children: [{
              metadata: {
                id: 'property-summary'
              },
              type: 'SingleOfferSummaryWrapper'
            }]
          }, {
            metadata: {
              id: 'property-level-message-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyLevelMessage`,
              linkName: 'Property Level Message'
            },
            children: [{
              metadata: {
                id: 'message-card'
              },
              type: 'SharedUiPropertyLevelOffersMessageWrapper'
            }]
          }, {
            metadata: {
              id: 'pre-notifications-banner-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PreNotificationBanner`,
              linkName: 'Pre Notification Banner'
            },
            children: [{
              metadata: {
                id: 'pre-notifications-banner'
              },
              type: 'SharedUiTravelAdvisory'
            }]
          }, {
            metadata: {
              id: 'property-space-details-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertySpaceDetails`,
              linkName: 'Property Space Details'
            },
            children: [{
              metadata: {
                id: 'property-spaces-details'
              },
              type: 'PropertySpaceDetails'
            }]
          }, {
            metadata: {
              id: 'property-about-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyAbout`,
              linkName: 'Property About'
            },
            children: [{
              metadata: {
                id: 'about-property'
              },
              type: 'AboutThisPropertyWrapper',
              config: {
                columns: 1
              }
            }]
          }]
        }, {
          metadata: {
            id: 'column-2'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 1
          },
          children: [{
            metadata: {
              id: 'property-level-offers-card-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyLevelOffersCard`,
              linkName: 'Property Level Offers Card'
            },
            children: [{
              metadata: {
                id: 'single-offer-property-level-offers-card'
              },
              type: 'SharedUiSingleOfferPropertyLevelOffersCardWrapper'
            }]
          }, {
            metadata: {
              id: 'SingleOfferPriceBox'
            },
            type: 'SingleOfferPriceWrapper'
          }]
        }, {
          metadata: {
            id: 'property-info'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'property-amenities-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyAmenities`,
              linkName: 'Property Amenities'
            },
            children: [{
              metadata: {
                id: 'property-amenities'
              },
              type: 'SharedUiAmenityDescriptionWrapper'
            }]
          }, {
            metadata: {
              id: 'answering-traveller-questions-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}AnsweringTravellerQuestions`,
              linkName: 'Answering Traveller Questions'
            },
            children: [{
              metadata: {
                id: 'answering-traveller-questions'
              },
              type: 'SharedUiAnsweringTravellerQuestionsWrapper'
            }]
          }, {
            metadata: {
              id: 'property-similar-ads-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyMesoCarousel`,
              linkName: 'Similar property ads'
            },
            children: [{
              metadata: {
                id: 'property-similar-ads'
              },
              type: 'SimilarHotelsMesoWrapper'
            }]
          }, {
            metadata: {
              id: 'property-policies-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyPolicies`,
              linkName: 'Property policies'
            },
            children: [{
              metadata: {
                id: 'property-policies'
              },
              type: 'SharedUiPoliciesWrapper'
            }]
          }, {
            metadata: {
              id: 'damage-and-incidentals-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.DamageAndIncidentalsCard`,
              linkName: 'Damage And Incidentals'
            },
            children: [{
              metadata: {
                id: 'damage-and-incidentals'
              },
              type: 'SharedUIDamageAndIncidentalsWrapper'
            }]
          }, {
            metadata: {
              id: 'in-page-cancellation-policy-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}CancellationPolicy.Seen`,
              linkName: 'In Page Cancellation Policy'
            },
            children: [{
              metadata: {
                id: 'cancellation-policy'
              },
              type: 'SharedUiCancellationPolicyInfoWrapper'
            }]
          }, {
            metadata: {
              id: 'property-important-info-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyImportantInfo`,
              linkName: 'Property Important Info'
            },
            children: [{
              metadata: {
                id: 'property-imp-info'
              },
              type: 'SharedUiImportantInfoWrapper'
            }]
          }, {
            metadata: {
              id: 'activities-recommendations-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}ActivitiesRecommendations`,
              linkName: 'activities recommendations'
            },
            children: [{
              metadata: {
                id: 'activities-recommendations'
              },
              type: 'ActivityRecommendationsWrapper'
            }]
          }, {
            metadata: {
              id: 'frequently-asked-questions-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyFaq`,
              linkName: 'frequently asked questions'
            },
            children: [{
              metadata: {
                id: 'frequently-asked-questions'
              },
              type: 'SharedUiPropertyFaqWrapper'
            }]
          }, {
            metadata: {
              id: 'property-reviews-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyReviews`,
              linkName: 'Property Reviews'
            },
            children: [{
              metadata: {
                id: 'property-reviews'
              },
              type: 'SharedUiPropertyReviewsWrapper'
            }]
          }, {
            metadata: {
              id: 'property-host-profile'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PropertyHostProfile`,
              linkName: 'Property Host Profile'
            },
            children: [{
              metadata: {
                id: 'host-profile'
              },
              type: 'SharedUiPropertyHostProfileWrapper',
              config: {
                columns: 1
              }
            }]
          }, {
            metadata: {
              id: 'contact-host'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}ContactHost`,
              linkName: 'contact Host Profile'
            },
            children: [{
              metadata: {
                id: 'contact-host-profile'
              },
              type: 'SharedUiContactHostWrapper',
              config: {
                columns: 1
              }
            }]
          }, {
            metadata: {
              id: 'suvr-carousel-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}Accessibility`,
              linkName: 'Accessibility wrapper'
            },
            children: [{
              metadata: {
                id: 'suvr-carousel'
              },
              type: 'SUVRCarousel'
            }]
          }, {
            metadata: {
              id: 'property-about-this-property-direct-feedback'
            },
            type: 'PropertyAboutThisPropertyDirectFeedbackWrapper'
          }, {
            metadata: {
              id: 'property-voice-of-customer-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyVoiceOfTheCustomer`,
              linkName: 'Property Voice Of The Customer'
            },
            children: [{
              metadata: {
                id: 'property-voice-of-the-customer'
              },
              type: 'SharedUiVoiceOfTheCustomerWrapper'
            }]
          }]
        }]
      }]
    }, {
      metadata: {
        id: 'LayoutFlexItemSiderail'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutFlexItem'
      },
      children: [{
        metadata: {
          id: 'sideRailRegion'
        },
        type: 'SideRailRegion',
        children: [{
          metadata: {
            id: 'mesoSideRail'
          },
          type: 'MesoSideRail'
        }]
      }]
    }]
  }]
};