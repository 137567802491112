/* eslint-disable max-lines */
import { getBexApiContext } from 'bernie-context';
import { Localization } from 'bernie-l10n';
import { ClosedPeriodBounding, DatePeriodUnit } from '__generated__/api';
import { parse } from 'query-string';
import { includeDynamicMapInSearchQuery } from 'src/common/utils/property-listing-utils';
import { adaptSearchCriteria } from 'stores/adapters/bex-api/common/search-criteria-adapter';
import { getShoppingContextInput } from 'stores/adapters/bex-api/search/shopping-context-adapter';
import { SearchQueryAdapter } from 'stores/adapters/search-query-adapter';
import { RoomModel } from 'stores/models/room-model';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { hasStoresChanged } from 'stores/utils';
import { DateUtils } from 'utils/date-utils';
const DEFAULT_STAY_LENGTH = '1';
export const getPropertySearchInputWithSearchId = (context, analyticsSearch, searchCriteria, multiItemContext, searchPagination, includeDynamicMap, primaryProductShoppingCriteriaInput, pageLayout) => ({
  context: getBexApiContext(context),
  criteria: adaptSearchCriteria(context, searchCriteria, searchPagination, undefined, analyticsSearch, pageLayout),
  destination: {
    regionName: null,
    regionId: null,
    coordinates: null,
    pinnedPropertyId: null,
    propertyIds: null,
    mapBounds: null
  },
  shoppingContext: getShoppingContextInput(multiItemContext),
  returnPropertyType: false,
  includeDynamicMap,
  productShoppingCriteriaInput: primaryProductShoppingCriteriaInput && {
    primarySearchCriteria: primaryProductShoppingCriteriaInput
  }
});
// Retrieves the inputs needed for Shared-UI Listings query components
export const getSharedUIListingsInputsWithSearchId = (listingsQueryInputs, context, analyticsSearch, location, pageLayout, searchCriteria, experiments) => {
  var _a, _b, _c;
  // TODO: improve isMapPwaOverlay logic
  const isMapPwaOverlay = location && parse(location.search).pwaOverlay === 'map';
  const includeDynamicMap = includeDynamicMapInSearchQuery(context, pageLayout, experiments, isMapPwaOverlay);
  const searchInputs = getPropertySearchInputWithSearchId(context, analyticsSearch, searchCriteria, undefined, undefined, includeDynamicMap, undefined, pageLayout);
  const rooms = (_a = listingsQueryInputs.propertySearchCriteriaInput) === null || _a === void 0 ? void 0 : _a.primary.rooms;
  if (((_b = searchInputs === null || searchInputs === void 0 ? void 0 : searchInputs.criteria) === null || _b === void 0 ? void 0 : _b.primary) && rooms) {
    searchInputs.criteria.primary.rooms = rooms;
  }
  // currently full map search on BEX/HCOM is fixed to 100 properties
  // TODO: try to handle this similarly to useSecondaryPropertyListingFetch in shared-ui property listing component
  const isClient = typeof window !== 'undefined';
  if (isClient && isMapPwaOverlay && ((_c = searchInputs === null || searchInputs === void 0 ? void 0 : searchInputs.criteria) === null || _c === void 0 ? void 0 : _c.secondary)) {
    searchInputs.criteria.secondary.counts = [...(searchInputs.criteria.secondary.counts || []), {
      id: 'resultsSize',
      value: 100
    }];
  }
  return searchInputs;
};
/**
 * Check if the search criteria stores has changes that would affect the query response
 * Example 1: hasSearchCriteriaChanged would be false if the only thing that changed in search criteria was the regionId
 * Example 2: hasSearchCriteriaChanged would be true if a filter was applied
 */
export const hasSearchCriteriaChanged = (context, searchCriteria, searchResults) => {
  const modelData = SearchQueryAdapter.parseIn(searchCriteria.toJSON());
  const incomingSearchCriteria = new SearchCriteriaModel(modelData, new Localization(context.locale));
  return searchResults.searchCriteria ? hasStoresChanged(searchResults.searchCriteria, incomingSearchCriteria) : undefined;
};
/**
 * Returns the BooleanValueInput[] necessary for the secondary.booleans on the searchCriteria/criteria query variable.
 * But before that it will filter out any undefined or false values from the list.
 * @param searchCriteria - list of available search criteria URL parameters
 * @param paramQueryIds - object list of all the parameter names that we want to add to the boolean array
 * @returns BooleanValueInput[] - contains as an array element the id as the parameter name and the value as the parameter value
 */
export const getSearchCriteriaSecondaryBooleans = (searchCriteria, paramQueryIds) => {
  if (paramQueryIds.length > 0) {
    return paramQueryIds.filter(id => String(searchCriteria[id.URL_PARAM_ID]) === 'true').map(id => ({
      id: id.QUERY_BOOLEAN_ID,
      value: true
    }));
  }
  return [];
};
export const getRooms = searchCriteria => {
  if (searchCriteria.rooms) {
    return searchCriteria.rooms.map(getRoomInput);
  }
  // Return room input with default data if searchCriteria is not present
  return [new RoomModel(Object.assign({}, RoomModel.DEFAULTS))].map(getRoomInput);
};
export const getFlexibility = flexibilityUrlParam => {
  if (flexibilityUrlParam) {
    const flexibilityUrlParamArray = typeof flexibilityUrlParam === 'string' ? [flexibilityUrlParam] : Array.from(flexibilityUrlParam);
    const flexibility = flexibilityUrlParamArray.reduce((acc, param) => {
      var _a, _b;
      const params = param.split('_');
      if (params.length > 1) {
        const length = params[0] ? parseInt(params[0], 10) : 0;
        const unit = DatePeriodUnit[(_b = (_a = params[1]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== null && _b !== void 0 ? _b : ''];
        const bound = params[2] && ClosedPeriodBounding[params[2].toUpperCase()];
        if (length && unit !== undefined) {
          acc.push(bound ? {
            length,
            unit,
            bound
          } : {
            length,
            unit
          });
        }
      }
      return acc;
    }, []);
    return flexibility.length > 0 ? flexibility : undefined;
  }
  return undefined;
};
export const getSearchRange = searchRangeUrlParam => {
  if (searchRangeUrlParam) {
    const searchRangeUrlParamArray = typeof searchRangeUrlParam === 'string' ? [searchRangeUrlParam] : searchRangeUrlParam;
    const searchRange = searchRangeUrlParamArray.reduce((searchRangeList, searchRangeParam) => {
      const dateParams = searchRangeParam.split('_');
      if (dateParams.length > 1) {
        const dates = dateParams.map(dateParam => DateUtils.parseDateString(dateParam));
        if (dates[0] && dates[1]) {
          searchRangeList.push({
            start: dates[0],
            end: dates[1]
          });
        }
      }
      return searchRangeList;
    }, []);
    return searchRange.length > 0 ? searchRange : undefined;
  }
  return undefined;
};
export const getFlexibleSearchRequirement = flexibleSearchRequirementUrlParam => {
  if (flexibleSearchRequirementUrlParam) {
    const flexibleSearchRequirementUrlParamArray = typeof flexibleSearchRequirementUrlParam === 'string' ? [flexibleSearchRequirementUrlParam] : Array.from(flexibleSearchRequirementUrlParam);
    return flexibleSearchRequirementUrlParamArray;
  }
  return undefined;
};
export const getFlexSearchCriteria = searchCriteria => {
  const flexibility = getFlexibility(searchCriteria.flexibility);
  const searchRange = getSearchRange(searchCriteria.searchRange);
  const flexibleSearchRequirement = getFlexibleSearchRequirement(searchCriteria.flexibleSearchRequirement);
  if (flexibility || searchRange || flexibleSearchRequirement) {
    return {
      flexibility,
      searchRange,
      flexibleSearchRequirement
    };
  }
  return null;
};
const generatePropertyDateRangeInput = (checkInDate, checkOutDate) => {
  const startDate = DateUtils.parseDateString(checkInDate);
  const endDate = DateUtils.parseDateString(checkOutDate);
  if (!startDate || !endDate) return null;
  return {
    checkInDate: startDate,
    checkOutDate: endDate
  };
};
export const generateSearchContextInput = searchCriteria => {
  const {
    startDate,
    endDate
  } = searchCriteria;
  const checkInDate = DateUtils.parseDateString(startDate);
  const checkOutDate = DateUtils.parseDateString(endDate);
  const destinationFromSearchCriteria = getDestinationInput(searchCriteria);
  const isDestinationNotEmpty = !Object.values(destinationFromSearchCriteria).every(x => x === null);
  const dateRangeInput = checkInDate && checkOutDate ? {
    start: checkInDate,
    end: checkOutDate
  } : null;
  const destinationInput = isDestinationNotEmpty ? destinationFromSearchCriteria : null;
  if (!dateRangeInput && !destinationInput) return null;
  return {
    dateRange: dateRangeInput,
    destination: destinationInput
  };
};
export const getDateRangeFromSearchCriteria = searchCriteria => {
  const {
    startDate,
    endDate,
    partialStay,
    partialStayStartDate,
    partialStayEndDate
  } = searchCriteria;
  if (partialStay === 'true') {
    return generatePropertyDateRangeInput(partialStayStartDate, partialStayEndDate);
  }
  return generatePropertyDateRangeInput(startDate, endDate);
};
export const getDateRangeInput = searchCriteria => {
  const dateRange = getDateRangeFromSearchCriteria(searchCriteria);
  if (!dateRange) {
    return generateFutureDates(searchCriteria);
  }
  return Object.assign({}, dateRange);
};
export const getDestinationInput = searchCriteria => {
  const coordinates = getCoordinates(searchCriteria.latLong);
  let mapBounds = null;
  if (searchCriteria.mapBounds && searchCriteria.mapBounds.length === 2) {
    const [xCoordinate, yCoordinate] = [getCoordinates(searchCriteria.mapBounds[0]), getCoordinates(searchCriteria.mapBounds[1])];
    if (xCoordinate && yCoordinate) {
      mapBounds = [xCoordinate, yCoordinate];
    }
  }
  return {
    regionName: searchCriteria.destination || null,
    regionId: getRegionId(searchCriteria.regionId),
    coordinates,
    pinnedPropertyId: searchCriteria.selected || null,
    propertyIds: getPropertyIds(searchCriteria),
    mapBounds
  };
};
const getRegionId = regionId => {
  if (!regionId) {
    return null;
  }
  const parsedRegionId = Number.parseInt(regionId, 10);
  if (isNaN(parsedRegionId)) {
    return null;
  }
  return regionId;
};
export const getCoordinates = latLong => {
  if (!latLong) {
    return null;
  }
  const latLongParts = latLong.toString().split(',') || [];
  if (!latLongParts || latLongParts.length !== 2 || !latLongParts[0] || !latLongParts[1]) {
    return null;
  }
  const parsedLat = Number.parseFloat(latLongParts[0]);
  const parsedLong = Number.parseFloat(latLongParts[1]);
  if (isNaN(parsedLat) || isNaN(parsedLong)) {
    return null;
  }
  return {
    latitude: Number.parseFloat(latLongParts[0]),
    longitude: Number.parseFloat(latLongParts[1])
  };
};
const getRoomInput = roomModel => {
  var _a, _b;
  return {
    adults: (_a = roomModel.adults) !== null && _a !== void 0 ? _a : 0,
    children: (_b = roomModel.children) === null || _b === void 0 ? void 0 : _b.map(getChildInput)
  };
};
const getChildInput = childModel => {
  var _a;
  return {
    age: (_a = childModel.age) !== null && _a !== void 0 ? _a : 0
  };
};
const getPropertyIds = searchCriteria => {
  if (searchCriteria.group && searchCriteria.group.length > 0) {
    return searchCriteria.group.split(',');
  }
  return null;
};
export const generateFutureDates = searchCriteria => {
  if (!(searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.daysInFuture)) {
    return null;
  }
  const stayLength = searchCriteria.stayLength;
  const daysInFuture = searchCriteria.daysInFuture;
  return getFutureDates(daysInFuture, stayLength);
};
export const getFutureDates = (daysInFuture, stayLength) => {
  const daysInFutureInt = parseInt(daysInFuture !== null && daysInFuture !== void 0 ? daysInFuture : '', 10);
  const stayLengthInt = parseInt(stayLength || DEFAULT_STAY_LENGTH, 10) || parseInt(DEFAULT_STAY_LENGTH, 10);
  if (!daysInFutureInt) {
    return null;
  }
  const checkInDate = new Date();
  checkInDate.setDate(checkInDate.getDate() + daysInFutureInt);
  const checkOutDate = new Date(checkInDate);
  checkOutDate.setDate(checkInDate.getDate() + stayLengthInt);
  if (isInvalidDate(checkInDate) || isInvalidDate(checkOutDate)) {
    return null;
  }
  return generatePropertyDateRangeInput(checkInDate.toISOString().split('T')[0], checkOutDate.toISOString().split('T')[0]);
};
const isInvalidDate = date => isNaN(date.getDate());