import { InventoryType } from '__generated__/api';
import { DateUtils } from 'utils/date-utils';
export function adaptToCartPropertySearchContextInput(searchCriteria, regionId) {
  return {
    checkIn: searchCriteria.startDate ? DateUtils.parseDateString(searchCriteria.startDate) : undefined,
    checkOut: searchCriteria.endDate ? DateUtils.parseDateString(searchCriteria.endDate) : undefined,
    rooms: toPropertyRoomInputs(searchCriteria),
    regionId
  };
}
// TODO Remove this adaption after moving this to shared-ui.
// checkIn and checkOut in propertyNaturalKeys has additional field which is not required by property add cart mutation
export function adaptToCartPropertyPrimaryKeyInput(propertyNaturalKeys) {
  return propertyNaturalKeys.map(propertyNaturalKey => {
    var _a, _b, _c, _d, _e, _f;
    const adaptedPropertyNaturalKey = Object.assign(Object.assign({}, propertyNaturalKey), {
      checkIn: {
        day: (_a = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkIn) === null || _a === void 0 ? void 0 : _a.day,
        month: (_b = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkIn) === null || _b === void 0 ? void 0 : _b.month,
        year: (_c = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkIn) === null || _c === void 0 ? void 0 : _c.year
      },
      checkOut: {
        day: (_d = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkOut) === null || _d === void 0 ? void 0 : _d.day,
        month: (_e = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkOut) === null || _e === void 0 ? void 0 : _e.month,
        year: (_f = propertyNaturalKey === null || propertyNaturalKey === void 0 ? void 0 : propertyNaturalKey.checkOut) === null || _f === void 0 ? void 0 : _f.year
      }
    });
    return adaptedPropertyNaturalKey;
  });
}
function toPropertyRoomInputs(searchCriteria) {
  if (!searchCriteria.rooms || searchCriteria.rooms.length === 0) {
    return;
  }
  return searchCriteria.rooms.map(room => ({
    childAges: room.children && room.children.map(child => child.age) || [],
    numberOfAdults: room.adults
  }));
}
const getRoomImage = (gallery, isMixMatchEnabled, offers) => {
  var _a, _b, _c, _d, _e;
  return isMixMatchEnabled ? (_d = (_c = (_b = (_a = offers[0]) === null || _a === void 0 ? void 0 : _a.offer) === null || _b === void 0 ? void 0 : _b.units[0]) === null || _c === void 0 ? void 0 : _c.photos[0]) === null || _d === void 0 ? void 0 : _d.url : (_e = gallery === null || gallery === void 0 ? void 0 : gallery.featured) === null || _e === void 0 ? void 0 : _e.url;
};
const getAdultsAndChildren = (firstNaturalKey, firstNaturalKeyRoom, isMixMatchEnabled) => {
  const adults = isMixMatchEnabled && firstNaturalKeyRoom ? firstNaturalKeyRoom.numberOfAdults : numberOfAdults(firstNaturalKey.rooms);
  const children = isMixMatchEnabled && (firstNaturalKeyRoom === null || firstNaturalKeyRoom === void 0 ? void 0 : firstNaturalKeyRoom.childAges) ? firstNaturalKeyRoom.childAges.length : numberOfChildren(firstNaturalKey.rooms);
  return {
    adults,
    children
  };
};
export function cartItemToInCartProperty(isMixMatchEnabled) {
  return (item, index) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const {
      id,
      summary,
      offers,
      gallery
    } = item;
    const offerIndex = (_b = (_a = offers[0]) === null || _a === void 0 ? void 0 : _a.offerIndex) !== null && _b !== void 0 ? _b : 0;
    const naturalKeys = (_c = offers[0]) === null || _c === void 0 ? void 0 : _c.naturalKeys;
    const firstNaturalKey = naturalKeys === null || naturalKeys === void 0 ? void 0 : naturalKeys[0];
    const rooms = (firstNaturalKey === null || firstNaturalKey === void 0 ? void 0 : firstNaturalKey.rooms) || [];
    const firstNaturalKeyRoom = rooms[index];
    const lastNaturalKey = naturalKeys === null || naturalKeys === void 0 ? void 0 : naturalKeys[naturalKeys.length - 1];
    const propertyId = Number(firstNaturalKey === null || firstNaturalKey === void 0 ? void 0 : firstNaturalKey.id);
    const roomImage = getRoomImage(gallery, isMixMatchEnabled, offers);
    const {
      adults,
      children
    } = getAdultsAndChildren(firstNaturalKey, firstNaturalKeyRoom, isMixMatchEnabled);
    return {
      id,
      name: summary === null || summary === void 0 ? void 0 : summary.name,
      checkin: (_e = (_d = firstNaturalKey === null || firstNaturalKey === void 0 ? void 0 : firstNaturalKey.checkIn) === null || _d === void 0 ? void 0 : _d.isoFormat) !== null && _e !== void 0 ? _e : '',
      checkout: (_g = (_f = lastNaturalKey === null || lastNaturalKey === void 0 ? void 0 : lastNaturalKey.checkOut) === null || _f === void 0 ? void 0 : _f.isoFormat) !== null && _g !== void 0 ? _g : '',
      propertyId,
      image: roomImage,
      city: ((_j = (_h = summary === null || summary === void 0 ? void 0 : summary.location) === null || _h === void 0 ? void 0 : _h.address) === null || _j === void 0 ? void 0 : _j.city) || '',
      adults,
      children,
      rooms: (_l = (_k = firstNaturalKey === null || firstNaturalKey === void 0 ? void 0 : firstNaturalKey.rooms) === null || _k === void 0 ? void 0 : _k.length) !== null && _l !== void 0 ? _l : 0,
      offerIndex
    };
  };
}
export function fromDateToBexApiDate(date) {
  if (!date) return;
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
}
export function numberOfAdults(rooms) {
  if (!(rooms === null || rooms === void 0 ? void 0 : rooms.length)) return 0;
  return rooms.reduce((acc, room) => acc + room.numberOfAdults, 0);
}
export function numberOfChildren(rooms) {
  if (!(rooms === null || rooms === void 0 ? void 0 : rooms.length)) return 0;
  return rooms.reduce((acc, room) => {
    var _a;
    const roomChildren = ((_a = room === null || room === void 0 ? void 0 : room.childAges) === null || _a === void 0 ? void 0 : _a.length) || 0;
    return acc + roomChildren;
  }, 0);
}
export function adaptInventoryType(inventoryType) {
  return inventoryType ? InventoryType[inventoryType] : InventoryType.MERCHANT;
}