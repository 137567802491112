var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action } from 'mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { v4 as uuid } from 'uuid';
import { publishPageViewEvents } from 'utils/analytics/analytics-util';
export class AnalyticsSearchStore extends Store {
  /* tslint:disable:unnecessary-constructor */
  constructor(state = {}, logger = NOOP_LOGGER) {
    super(state, logger);
    this.apolloLinkSetOnServer = false;
    this.apolloLinkSetInBrowser = false;
    this.searchId = this.generateSearchId();
    this.data = {
      payload: []
    };
    if (state.searchId) {
      this.searchId = state.searchId;
    }
  }
  withPublishingAnalyticsPayload(fn, analyticsStore, experiments) {
    return (...args) => {
      var _a;
      if (analyticsStore && ((_a = this.data.payload) === null || _a === void 0 ? void 0 : _a.length)) {
        this.publishPayload(analyticsStore, experiments);
      }
      return fn(...args);
    };
  }
  newSearchId() {
    this.searchId = this.generateSearchId();
  }
  generateSearchId() {
    return uuid();
  }
  hydrate(data) {
    Object.assign(this, data);
  }
  addPayload(payloadToPush) {
    this.data.payload.push(payloadToPush);
  }
  publishPayload(analyticsStore, experiment) {
    if (this.data.payload.length > 0) {
      publishPageViewEvents(this.data.payload, analyticsStore, this.logger, experiment);
      this.data.payload = [];
    }
  }
}
__decorate([action], AnalyticsSearchStore.prototype, "addPayload", null);
__decorate([action], AnalyticsSearchStore.prototype, "publishPayload", null);