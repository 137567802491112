import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
export const EMPTY_VERSION = 'empty-version';
export const EMPTY_ENV = 'empty-env';
export class DataDogStore extends Store {
  constructor(state = {}) {
    super(state, NOOP_LOGGER);
  }
  hydrate(data) {
    Object.assign(this, data);
  }
}
DataDogStore.DEFAULTS = {
  version: EMPTY_VERSION,
  env: EMPTY_ENV,
  sendContextData: true
};