/* eslint-disable max-lines */
/* eslint-disable dot-notation */
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, intercept, observable } from 'mobx';
import { Localization } from 'bernie-l10n';
import { getErrorString, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { STORE_EVENTS } from 'stores/events/store-events';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
export class CurrentHotelStore extends Store {
  constructor(bexApiSource, state = {}, l10n, logger = NOOP_LOGGER) {
    super(state, logger);
    this.bexApiSource = bexApiSource;
    this.isNavigationFromSRP = false;
    this.openNewDatePicker = false;
    this.poiData = CurrentHotelStore.DEFAULTS.poiData;
    this.apolloLinkSetOnBrowser = false;
    this.apolloLinkSetOnServer = false;
    this.showPetsOnSingleOffer = false;
    this.l10n = l10n;
    this.searchCriteria = new SearchCriteriaModel({}, this.l10n);
    this.numberInterceptor('hotelId');
  }
  hydrate(data) {
    if (data && data.hotel && data.hotel.code === 'INACTIVE_HOTEL_ID') {
      window.location.replace(`https://${window.location.host}/Hotels`);
    }
    delete data.bexApiSource;
    delete data.hotelSearchApiSource;
    this.searchCriteria.hydrate(data.searchCriteria || {});
    delete data.searchCriteria;
    Object.assign(this, data);
  }
  // setup mobx interceptor to wrap dates in a method to format data passed to setter
  numberInterceptor(prop) {
    if (typeof prop === 'string') {
      // Ignoring interceptors for now since they are going to be refactored out in the future
      // @ts-ignore
      intercept(this, prop, change => {
        if (change.newValue) {
          const newValue = this.sanitizeNumber(change.newValue);
          if (newValue) {
            change.newValue = newValue;
            return change;
          }
        }
      });
    }
  }
  sanitizeNumber(number) {
    try {
      const cleanNumber = typeof number === 'number' ? number : parseInt(number, 10);
      if (Number.isNaN(cleanNumber)) {
        throw new Error(`Invalid Number: cannot parse ${number} into a Number`);
      }
      return cleanNumber;
    } catch (error) {
      this.logErrorEvent(STORE_EVENTS.SANATIZE_NUMNBER_ERROR, getErrorString(error));
      return '';
    }
  }
  resetPayloads() {
    this.detailsPayload = null;
    this.offersPayload = null;
  }
  addPayload(operation, gqlExtensionsAnalyticsPayload, analytics) {
    if (gqlExtensionsAnalyticsPayload.traceId) {
      if (operation === 'PropertyDetailsQuery' || operation === 'PropertyDetailsBasicQuery') {
        this.detailsPayload = gqlExtensionsAnalyticsPayload;
      }
      if (operation === 'AncillaryPropertyOffersQuery') {
        this.offersPayload = gqlExtensionsAnalyticsPayload;
      }
      if (this.offersPayload && this.detailsPayload) {
        analytics.publishClientSidePayload([this.detailsPayload, this.offersPayload]);
        this.resetPayloads();
      }
    }
  }
  setIsNavigationFromSRP(isNavigationFromSRP) {
    this.isNavigationFromSRP = isNavigationFromSRP;
  }
  setPropertyListingAsCurrentHotel(lodgingCard) {
    if (!(lodgingCard === null || lodgingCard === void 0 ? void 0 : lodgingCard.id)) {
      return;
    }
    const {
      id
    } = lodgingCard;
    const propertyId = parseInt(id, 10);
    this.setIsNavigationFromSRP(true);
    if (this.hotelId === propertyId) {
      return;
    }
    this.shouldFetch = true;
    this.hotelId = propertyId;
  }
  updateSearchCriteria(criteria) {
    this.searchCriteria = criteria;
  }
  setHotelId(hotelId) {
    if (hotelId !== this.hotelId) {
      this.shouldFetch = true;
      this.hotelId = hotelId;
    }
  }
  logErrorEvent(name, err) {
    this.logger.logEvent(name, getErrorString(err));
  }
  setOfferData(offerData) {
    this.offerSearchData = Object.assign({}, this.offerSearchData, offerData);
  }
  clearPoiData() {
    this.poiData = CurrentHotelStore.DEFAULTS.poiData;
  }
  setSelectedMapMarker(markerId) {
    this.selectedMapMarker = markerId ? Number(markerId) : markerId;
  }
}
CurrentHotelStore.offerSearchKeys = ['startDate', 'endDate', 'adults', 'children', 'petsIncluded'];
CurrentHotelStore.DEFAULTS = {
  hotelId: undefined,
  isNavigationFromSRP: false,
  l10n: new Localization('en_US'),
  offerSearchData: {},
  reviewIdsUpvoted: [],
  poiData: {},
  selectedGalleryCategoryId: 'all'
};
__decorate([observable], CurrentHotelStore.prototype, "l10n", void 0);
__decorate([observable], CurrentHotelStore.prototype, "hotelId", void 0);
__decorate([observable], CurrentHotelStore.prototype, "isNavigationFromSRP", void 0);
__decorate([observable], CurrentHotelStore.prototype, "isLoading", void 0);
__decorate([observable], CurrentHotelStore.prototype, "isFailure", void 0);
__decorate([observable], CurrentHotelStore.prototype, "shouldFetch", void 0);
__decorate([observable], CurrentHotelStore.prototype, "openNewDatePicker", void 0);
__decorate([observable], CurrentHotelStore.prototype, "loadingETPData", void 0);
__decorate([observable], CurrentHotelStore.prototype, "creatingConversation", void 0);
__decorate([observable], CurrentHotelStore.prototype, "offerSearchData", void 0);
__decorate([observable], CurrentHotelStore.prototype, "travelAd", void 0);
__decorate([observable], CurrentHotelStore.prototype, "requests", void 0);
__decorate([observable.ref], CurrentHotelStore.prototype, "searchCriteria", void 0);
__decorate([observable], CurrentHotelStore.prototype, "poiData", void 0);
__decorate([observable], CurrentHotelStore.prototype, "showPetsOnSingleOffer", void 0);
__decorate([action], CurrentHotelStore.prototype, "setIsNavigationFromSRP", null);
__decorate([action], CurrentHotelStore.prototype, "setPropertyListingAsCurrentHotel", null);
__decorate([action], CurrentHotelStore.prototype, "updateSearchCriteria", null);
__decorate([action], CurrentHotelStore.prototype, "setHotelId", null);
__decorate([action], CurrentHotelStore.prototype, "setOfferData", null);
__decorate([action], CurrentHotelStore.prototype, "clearPoiData", null);