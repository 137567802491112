import { DEFAULT_CAR_SEARCH_PAGE_ID, DEFAULT_CAR_SEARCH_PAGE_NAME, DEFAULT_CRUISE_SEARCH_PAGE_ID, DEFAULT_CRUISE_SEARCH_PAGE_NAME, DEFAULT_GT_SEARCH_PAGE_ID, DEFAULT_GT_SEARCH_PAGE_NAME, DEFAULT_HOTEL_INFOSITE_PAGE_ID, DEFAULT_HOTEL_INFOSITE_PAGE_NAME, DEFAULT_HOTEL_SEARCH_PAGE_ID, DEFAULT_HOTEL_SEARCH_PAGE_NAME, DetailsClickstreamPageNameDetailed, INFOSITE_PAGE_EG_CLICKSTREAM_NAME, InfositePageName, SEARCH_PAGE_EG_CLICKSTREAM_NAME, SearchClickstreamPageNameDetailed, SearchPageName } from 'src/common/constants/pages';
import { getPackageType } from 'src/common/stores/multi-item-store-util';
export function getHotelSearchPageData(query) {
  const packageType = getPackageType(query);
  if (!packageType) {
    return {
      navigationId: 'hotel',
      clickstreamPageName: SEARCH_PAGE_EG_CLICKSTREAM_NAME,
      clickstreamPageNameDetailed: SearchClickstreamPageNameDetailed.SA,
      pageName: DEFAULT_HOTEL_SEARCH_PAGE_NAME,
      pageId: DEFAULT_HOTEL_SEARCH_PAGE_ID
    };
  }
  const pageName = SearchPageName[packageType];
  const pageId = `${pageName},P,20`;
  return {
    pageName,
    pageId,
    navigationId: 'package',
    clickstreamPageName: SEARCH_PAGE_EG_CLICKSTREAM_NAME,
    clickstreamPageNameDetailed: SearchClickstreamPageNameDetailed[packageType]
  };
}
export const getHotelInfositePageData = query => {
  const packageType = getPackageType(query);
  if (!packageType) {
    return {
      navigationId: 'hotel',
      clickstreamPageName: INFOSITE_PAGE_EG_CLICKSTREAM_NAME,
      clickstreamPageNameDetailed: DetailsClickstreamPageNameDetailed.SA,
      pageName: DEFAULT_HOTEL_INFOSITE_PAGE_NAME,
      pageId: DEFAULT_HOTEL_INFOSITE_PAGE_ID
    };
  }
  const pageName = InfositePageName[packageType];
  const pageId = `${pageName},P,30`;
  return {
    pageName,
    pageId,
    navigationId: 'package',
    clickstreamPageName: INFOSITE_PAGE_EG_CLICKSTREAM_NAME,
    clickstreamPageNameDetailed: DetailsClickstreamPageNameDetailed[packageType]
  };
};
export const getGroundTransportationSearchPageData = () => {
  const pageName = DEFAULT_GT_SEARCH_PAGE_NAME;
  const pageId = DEFAULT_GT_SEARCH_PAGE_ID;
  return {
    pageName,
    pageId
  };
};
export const getCruiseSearchPageData = () => {
  const pageName = DEFAULT_CRUISE_SEARCH_PAGE_NAME;
  const pageId = DEFAULT_CRUISE_SEARCH_PAGE_ID;
  return {
    pageName,
    pageId
  };
};
export const getCarSearchPageData = () => {
  const pageName = DEFAULT_CAR_SEARCH_PAGE_NAME;
  const pageId = DEFAULT_CAR_SEARCH_PAGE_ID;
  return {
    pageName,
    pageId
  };
};