import { SystemEvent, SystemEventLevel } from 'bernie-logger';
/**
 * Format: BEX_API_${SPINNER_NAME}_${QUERY_NAME}_ERROR
 *
 * QUERY_NAME should be followed by FETCH only if it's a query.
 */
export const SOURCE_EVENTS = {
  BEX_API_CUSTOMER_CUSTOMER_INTENT_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_CUSTOMER_CUSTOMER_INTENT_FETCH_ERROR'),
  BEX_API_LODGING_DESTINATION_REFINEMENT_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_DESTINATION_REFINEMENT_FETCH_ERROR'),
  BEX_API_LODGING_FULL_PROPERTY_SEARCH_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_FULL_PROPERTY_SEARCH_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_SEARCH_SORT_AND_FILTER_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_SEARCH_SORT_AND_FILTER_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_INFO_DETAILS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_INFO_DETAILS_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_INFO_OFFERS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_INFO_OFFERS_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_INFO_PAGE_LAYOUT_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_INFO_PAGE_LAYOUT_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_SEARCH_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_SEARCH_FETCH_ERROR'),
  BEX_API_LODGING_TRAVELAD_SEARCH_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_TRAVELAD_SEARCH_FETCH_ERROR'),
  BEX_API_LODGING_REVIEWS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_REVIEWS_FETCH_ERROR'),
  BEX_API_MULTIITEM_ADD_ITEM_TO_CART_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_ADD_ITEM_TO_CART_ERROR'),
  BEX_API_MULTIITEM_CART_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_CART_FETCH_ERROR'),
  BEX_API_MULTIITEM_CREATE_CART_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_CREATE_CART_ERROR'),
  BEX_API_MULTIITEM_PREPARE_CHECKOUT_BY_CART_ID_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_PREPARE_CHECKOUT_BY_CART_ID_ERROR'),
  BEX_API_MULTIITEM_PREPARE_CHECKOUT_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_PREPARE_CHECKOUT_ERROR'),
  BEX_API_MULTIITEM_REMOVE_ITEM_FROM_CART_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_REMOVE_ITEM_FROM_CART_ERROR'),
  BEX_API_MULTIITEM_CREATE_MULTIITEM_SESSION: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTIITEM_CREATE_MULTIITEM_SESSION'),
  BEX_API_SELECT_PRODUCTS: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_SELECT_PRODUCTS'),
  BEX_API_INITIATE_MULTIITEM_SESSION: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_INITIATE_MULTIITEM_SESSION'),
  BEX_API_UGC_CREATE_QUESTION_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_CREATE_QUESTION_ERROR'),
  BEX_API_UGC_FIND_ANSWERS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_FIND_ANSWERS_FETCH_ERROR'),
  BEX_API_UGC_QUESTION_ANSWERS_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_QUESTION_ANSWERS_FETCH_ERROR'),
  BEX_API_UGC_ROOM_SCORE_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_ROOM_SCORE_FETCH_ERROR'),
  BEX_API_UGC_TRANSLATE_REVIEW_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_TRANSLATE_REVIEW_ERROR'),
  BEX_API_UGC_UPVOTE_REVIEW_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_UPVOTE_REVIEW_ERROR'),
  BEX_API_UGC_LOCATION_SCORE_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UGC_LOCATION_SCORE_FETCH_ERROR'),
  BEX_API_LODGING_PROPERTY_POI_CONTENT_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_PROPERTY_POI_CONTENT_FETCH_ERROR'),
  BEX_API_LODGING_SHAREINFO_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_LODGING_SHAREINFO_FETCH_ERROR'),
  BEX_API_RATE_CALENDAR_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_RATE_CALENDAR_FETCH_ERROR')
};