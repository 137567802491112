import { merge } from 'bernie-core';
import { SEARCH_ID } from 'src/common/constants/analytics';
import { PDP_CHECKIN_QUERY_PARAM, PDP_CHECKOUT_QUERY_PARAM, PDP_DATELESS_QUERY_PARAM } from '../../constants/dates';
import { queryObject } from './adapters';
const {
  withoutKeys
} = merge;
const CHILD_UNDERSCORE_REGEX = /\d+_(\d+)/i;
const rooms = {
  parseInRoomParams: query => {
    const MAX_ROOM_PARAMS = 10;
    const roomParams = [];
    const roomsCollection = [];
    const adultsRegex = /a(\d+)/i;
    const childrenRegex = /c(\d+)/i;
    /*
     sample query object:
          {
       rm1: 'a2:c4:c0:c5',
       rm2: 'a1',
       foo: 'bar'
     }
     */
    // extract valid rooms from the query object
    for (let index = 1, key = '', value = ''; index <= MAX_ROOM_PARAMS; index++) {
      key = `rm${index}`;
      if (Object.prototype.hasOwnProperty.call(query, key)) {
        value = query[key];
        if (typeof value === 'string' && value.length > 0) {
          roomParams.push(query[key]);
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete query[key];
        }
        continue;
      }
      break;
    }
    // cycle through the rooms and parseIn their content
    roomParams.forEach(room => {
      var _a;
      if (adultsRegex.test(room)) {
        const adults = parseInt(((_a = adultsRegex.exec(room)) === null || _a === void 0 ? void 0 : _a[1]) || '', 10) || 0;
        const children = [];
        const roomParts = room.split(':');
        roomParts.forEach(part => {
          var _a;
          if (childrenRegex.test(part)) {
            children.push({
              age: parseInt(((_a = childrenRegex.exec(part)) === null || _a === void 0 ? void 0 : _a[1]) || '', 10) || 0
            });
          }
        });
        if (adults) {
          roomsCollection.push({
            adults,
            children
          });
        }
      }
    });
    query.rooms = roomsCollection;
    return query;
  },
  parseInGuestParams: query => {
    /*
    sample guest query object:
      {
        adults: '2,1',
        children: '3,0',
        childAge: '4,0,5'
      }
    */
    /*
    sample guest query object for vrbo:
      {
        adults: '2',
        children: '1_3',
      }
    */
    const roomsCollection = [];
    const adultsArray = getParameterArray(query, 'adults');
    const childrenArray = getParameterArray(query, 'children');
    const childAgeArray = getParameterArray(query, 'childAge');
    if (isChildrenParametersUnderscored(childrenArray)) {
      const underscoredChildParams = getUnderscoredChildParams(adultsArray, childrenArray);
      if (underscoredChildParams) {
        roomsCollection.push(underscoredChildParams);
      }
    } else {
      // bex logic
      adultsArray.forEach((adult, i) => {
        const adults = parseInt(adult, 10) || 0;
        const children = [];
        const ithChild = childrenArray[i];
        const childCount = childrenArray && ithChild ? parseInt(ithChild, 10) : 0;
        const childAges = childAgeArray && childAgeArray.splice(0, childCount) || [];
        childAges.forEach(childAge => {
          children.push({
            age: parseInt(childAge, 10) || 0
          });
        });
        if (adults) {
          roomsCollection.push({
            adults,
            children
          });
        }
      });
    }
    query.rooms = roomsCollection;
    delete query.adults;
    delete query.children;
    delete query.childAge;
    return query;
  },
  parseIn: query => {
    query = queryObject(query);
    const hasRoomParams = query && query.rm1 !== undefined;
    if (hasRoomParams) {
      return parseInRoomParams(query);
    }
    return parseInGuestParams(query);
  },
  parseOut: query => {
    query = queryObject(query);
    const roomsCollection = query && query.rooms;
    let count = 1;
    if (typeof roomsCollection === 'object' && roomsCollection && roomsCollection.length > 0) {
      roomsCollection.forEach(room => {
        if (room.adults > 0) {
          const roomStrings = [`a${room.adults}`];
          if (room.children && room.children.length > 0) {
            room.children.forEach(child => {
              roomStrings.push(`c${child.age || 0}`);
            });
          }
          query[`rm${count}`] = roomStrings.join(':');
          count += 1;
        }
      });
      delete query.rooms;
    }
    return query;
  }
};
function isDateStringValid(dateStr) {
  const dateMatcherRegex = /\d{4}-\d{2}-\d{2}/;
  if (!dateMatcherRegex.test(dateStr)) return false;
  const newDate = new Date(dateStr);
  return !isNaN(newDate.getTime());
}
function parseIn(query) {
  const startDate = query[PDP_CHECKIN_QUERY_PARAM] || query.startDate || null;
  const endDate = query[PDP_CHECKOUT_QUERY_PARAM] || query.endDate || null;
  const dateFields = {};
  if (startDate && isDateStringValid(startDate)) {
    dateFields.startDate = startDate;
  }
  if (endDate && isDateStringValid(endDate)) {
    dateFields.endDate = endDate;
  }
  return withoutKeys([PDP_CHECKIN_QUERY_PARAM, PDP_CHECKOUT_QUERY_PARAM]).merge({}, rooms.parseIn(query), dateFields);
}
function parseOut(query, l10n) {
  var _a, _b;
  if (!query) {
    return {};
  }
  const checkInDate = (_a = parseDate(query.startDate, query.localDateFormat, l10n)) !== null && _a !== void 0 ? _a : '';
  const checkOutDate = (_b = parseDate(query.endDate, query.localDateFormat, l10n)) !== null && _b !== void 0 ? _b : '';
  const dates = {};
  if (checkInDate || checkOutDate) {
    dates[PDP_DATELESS_QUERY_PARAM] = undefined;
  } else {
    dates[PDP_DATELESS_QUERY_PARAM] = true;
  }
  dates[PDP_CHECKIN_QUERY_PARAM] = checkInDate;
  dates[PDP_CHECKOUT_QUERY_PARAM] = checkOutDate;
  return Object.assign(Object.assign({}, withoutKeys(['localDateFormat', 'top_dp']).merge({}, rooms.parseOut(query, l10n), dates)), {
    startDate: dates[PDP_DATELESS_QUERY_PARAM] ? '' : checkInDate,
    endDate: dates[PDP_DATELESS_QUERY_PARAM] ? '' : checkOutDate,
    searchId: query[SEARCH_ID]
  });
}
function parseInRoomParams(query) {
  return rooms.parseInRoomParams(query);
}
function parseInGuestParams(query) {
  return rooms.parseInGuestParams(query);
}
function parseDate(date = '', outputFormat = 'yyyy-MM-dd', l10n) {
  try {
    const parsedDate = l10n.parseLocalDateString(date, {
      raw: 'yyyy-MM-dd'
    });
    if (parsedDate) {
      return l10n.formatDate(parsedDate, {
        raw: outputFormat
      });
    }
  } catch (error) {
    return date;
  }
  return '';
}
function getParameterArray(query, parameter) {
  const paramterValue = query && query[parameter];
  return paramterValue && typeof paramterValue === 'string' && paramterValue.split(',') || [];
}
function isChildrenParametersUnderscored(childrenArray) {
  return childrenArray.length > 0 && childrenArray[0] && CHILD_UNDERSCORE_REGEX.test(childrenArray[0]);
}
function getUnderscoredChildParams(adultsArray, childrenArray) {
  // vrbo logic
  const adults = adultsArray.reduce((count, adult) => count + (parseInt(adult, 10) || 0));
  const children = adults ? childrenArray.map(child => {
    var _a;
    const childAge = parseInt(((_a = CHILD_UNDERSCORE_REGEX.exec(child)) === null || _a === void 0 ? void 0 : _a[1]) || '', 10) || 0;
    return {
      age: childAge
    };
  }) : {};
  if (adults) {
    return {
      adults: parseInt(adults, 10),
      children
    };
  }
}
export const InfositeQueryAdapter = {
  parseIn,
  parseOut,
  parseInRoomParams,
  parseInGuestParams
};