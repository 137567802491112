const defaultTrackApiUrl = '/api/uisprime/track';
const hcomHost = /https:\/\/\w+.(staging1-)?(hotels|hoteis|hoteles).(com|cn)\/*/;
/*
  This is a copy of the UIS Publisher from
  https://github.expedia.biz/Brand-Expedia/platform-analytics-prime-js/blob/master/src/publishers/uis-publisher/index.js

  The reason for this it to try and solve pageview issues we are seeing in the clickstream events.
  This publisher has been slimmed down to support bex only.

  Avoided rewriting it because using fetch seemed to impact the ordering of the calls.
  Wish to keep this as similar as the UIS publisher as possible to avoid issues.
 */
function post(uisUrl, data, httpRequest) {
  return new Promise((resolve, reject) => {
    const init = {
      method: 'POST',
      body: data
    };
    httpRequest.onload = () => {
      if (httpRequest.status >= 200 && httpRequest.status < 300) {
        let response = JSON.parse(httpRequest.response);
        if (typeof response === 'string') {
          response = JSON.parse(response);
        }
        resolve(response);
      } else if (httpRequest.status === 405 && httpRequest.responseURL && httpRequest.responseURL.match(hcomHost)) {
        // the only way to pass cookies in a fetch is using the withCredential(true);
        // and it needs a XMLHttpRequest in a OPEN state (or it will throw an exception)
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.withCredentials = true;
        resolve(post(httpRequest.responseURL, data, xmlHttpRequest));
      } else {
        reject(new Error(`UIS (${uisUrl})replied with HTTP status code: ${httpRequest.status}`));
      }
    };
    httpRequest.onerror = () => reject(new Error('Could not connect.'));
    httpRequest.ontimeout = () => reject(new Error('Request timed out.'));
    httpRequest.open(init.method, uisUrl, true);
    httpRequest.setRequestHeader('Content-type', 'text/plain');
    const requestBody = JSON.stringify(init.body);
    httpRequest.send(requestBody);
  });
}
export class UisPublisher {
  constructor() {
    this.trackApiUrl = defaultTrackApiUrl;
    this.requestBody = {};
    this.xmlHttpRequest = new XMLHttpRequest();
  }
  publish(data) {
    this.requestBody = data;
    return post(this.trackApiUrl, this.requestBody, this.xmlHttpRequest);
  }
}