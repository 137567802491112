import { SystemEvent, SystemEventLevel } from 'bernie-logger';
export const STORE_EVENTS = {
  CURRENT_HOTEL_STORE_PARALLEL_FETCH: new SystemEvent(SystemEventLevel.WARN, 'CURRENT_HOTEL_STORE_PARALLEL_FETCH'),
  STORE_INTERCEPT_VALUE_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'STORE_INTERCEPT_VALUE_ERROR'),
  ANALYTICS_TRACK_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'ANALYTICS_TRACK_ERROR'),
  FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'FETCH_ERROR'),
  MOBX_INTERCEPTOR_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'MOBX_INTERCEPTOR_ERROR'),
  SANATIZE_NUMNBER_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'SANATIZE_NUMNBER_ERROR'),
  BEX_API_DESTINATION_REFINEMENT_TIMEOUT: new SystemEvent(SystemEventLevel.WARN, 'BEX_API_DESTINATION_REFINEMENT_TIMEOUT'),
  BEX_API_CURRENT_HOTEL_STORE_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_CURRENT_HOTEL_STORE_FETCH_ERROR'),
  BEX_API_SEARCH_RESULTS_STORE_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_SEARCH_RESULTS_STORE_FETCH_ERROR'),
  BEX_API_SEARCH_RESULTS_STORE_EXPIRED_PAGINATION_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_SEARCH_RESULTS_STORE_EXPIRED_PAGINATION_FETCH_ERROR'),
  BEX_API_SEARCH_RESULTS_STORE_RATE_CALENDAR_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_SEARCH_RESULTS_STORE_RATE_CALENDAR_FETCH_ERROR'),
  BEX_API_SEARCH_RESULTS_OMNITURE_PARSE_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_SEARCH_RESULTS_OMNITURE_PARSE_ERROR'),
  BEX_API_UPVOTE_PROPERTY_REVIEW_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_UPVOTE_PROPERTY_REVIEW_ERROR'),
  BEX_API_MULTI_ITEM_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_FETCH_ERROR'),
  BEX_API_MULTI_ITEM_ADD_PROPERTY_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_ADD_PROPERTY_ERROR'),
  BEX_API_MULTI_ITEM_CHECKOUT_PROPERTY_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_CHECKOUT_PROPERTY_ERROR'),
  BEX_API_MULTI_ITEM_REMOVE_PROPERTY_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_REMOVE_PROPERTY_ERROR'),
  BEX_API_MULTI_ITEM_CREATE_SESSION_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_CREATE_SESSION_ERROR'),
  BEX_API_MULTI_ITEM_INITIATE_SESSION_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_INITATE_SESSION_ERROR'),
  BEX_API_MULTI_ITEM_SELECT_PRODUCTS_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_MULTI_ITEM_SELECT_PRODUCTS_ERROR'),
  BEX_API_FIND_ANSWERS_STORE_FETCH_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_FIND_ANSWERS_STORE_FETCH_ERROR'),
  BEX_API_CREATE_QUESTION_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_CREATE_QUESTION_ERROR'),
  BEX_API_CUSTOMER_STORE_FETCH_ERROR_WITH_SEMDTL: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_CUSTOMER_STORE_FETCH_ERROR_WITH_SEMDTL'),
  BEX_API_CUSTOMER_STORE_FETCH_ERROR_NO_SEMDTL: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_CUSTOMER_STORE_FETCH_ERROR_NO_SEMDTL'),
  BEX_API_PDP_MIGRATION_MISSING_FIELD: new SystemEvent(SystemEventLevel.WARN, 'BEX_API_PDP_MIGRATION_MISSING_FIELD'),
  BEX_API_PROPERTY_OFFERS_BFF_FALLBACK: new SystemEvent(SystemEventLevel.ERROR, 'BEX_API_PROPERTY_OFFERS_FALLBACK'),
  BEX_API_PROPERTY_OFFERS_NO_ROOMS: new SystemEvent(SystemEventLevel.INFO, 'BEX_API_PROPERTY_OFFERS_NO_ROOMS'),
  BEX_API_PROPERTY_PAYMENT_PLAN_MIX_MISMATCH: new SystemEvent(SystemEventLevel.WARN, 'BEX_API_PROPERTY_PAYMENT_PLAN_MIX_MISMATCH'),
  BEX_API_OFFERS_ADAPT_TIME: new SystemEvent(SystemEventLevel.INFO, 'BEX_API_OFFERS_ADAPT_TIME'),
  DEFAULT_PDP_PAGE_LAYOUT: new SystemEvent(SystemEventLevel.WARN, 'DEFAULT_PDP_PAGE_LAYOUT_USED'),
  DEFAULT_SRP_PAGE_LAYOUT: new SystemEvent(SystemEventLevel.WARN, 'DEFAULT_SRP_PAGE_LAYOUT_USED'),
  PROPERTY_LISTING_ANALYTICS_PUBLISH_ERROR: new SystemEvent(SystemEventLevel.WARN, 'PROPERTY_LISTING_ANALYTICS_PUBLISH_ERROR'),
  QUERY_PARSING_ERROR: new SystemEvent(SystemEventLevel.ERROR, 'QUERY_PARSING_ERROR')
};