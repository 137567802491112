import { PaginationAttribute } from 'bernie-http';
const CHINA_POS_SITE_ID = 100001;
export const getSeoData = context => {
  var _a, _b;
  const additionalMeta = [];
  switch ((_a = context === null || context === void 0 ? void 0 : context.site) === null || _a === void 0 ? void 0 : _a.brand) {
    case 'vrbo':
      additionalMeta.push(new PaginationAttribute('preconnect', 'https://media.vrbo.com/'));
      break;
    default:
      additionalMeta.push(new PaginationAttribute('preconnect', 'https://images.trvl-media.com/'));
  }
  ((_b = context === null || context === void 0 ? void 0 : context.site) === null || _b === void 0 ? void 0 : _b.id) !== CHINA_POS_SITE_ID && additionalMeta.push(new PaginationAttribute('preconnect', 'https://maps.googleapis.com/'));
  return {
    seo: Object.assign({
      additionalMeta
    }, (context === null || context === void 0 ? void 0 : context.seoData) && {
      canonical: context === null || context === void 0 ? void 0 : context.seoData.url
    })
  };
};