import { isObservableArray } from 'mobx';
import { getLodgingSearchImpactingKeys, PropertySort, SearchCriteriaUtils } from 'stores/models';
import { multiItemCompleteSearchCriteria } from '../components/multi-item/utils';
/**
 * This utility function is meant for store operations ONLY,
 * where a determination must be made about which network call to make.
 * All attempts should be made first to either log server-side or use <Experiment />.
 */
export function evaluateAndLogExperiment(experimentName, context, experiments, pageData) {
  var _a;
  const experiment = (_a = experiments === null || experiments === void 0 ? void 0 : experiments.exposures) === null || _a === void 0 ? void 0 : _a[experimentName];
  if (experiment) {
    if (context && pageData) {
      experiments === null || experiments === void 0 ? void 0 : experiments.logExperiment(context, pageData, experiment);
    }
    return experiment.bucket;
  }
  return 0;
}
function isKeyUsedForGeo(key) {
  return key === 'regionId' || key === 'latLong' || key === 'mapBounds' || key === 'destination';
}
function getComparableKeys(prevStore, newStore) {
  let compareKeys = [...getLodgingSearchImpactingKeys(), ...multiItemCompleteSearchCriteria];
  if (!didGeoChange(prevStore, newStore)) {
    compareKeys = compareKeys.filter(key => !isKeyUsedForGeo(key));
  }
  // If we're asking to sort by values that's equivalent to what we currently have,
  // remove sort field from the comparison to avoid another service call
  const askingForEmptySortWhileWeHaveRecommended = newStore.sort === '' && prevStore.sort === PropertySort.RECOMMENDED;
  const askingForMctc2SortWhileWeHaveDeals = !newStore.sort && newStore.mctc === '2';
  const isSameSortParam = newStore.sort && prevStore.sort && newStore.sort.toLowerCase() === prevStore.sort.toLowerCase();
  const equivalentSort = askingForEmptySortWhileWeHaveRecommended || askingForMctc2SortWhileWeHaveDeals || isSameSortParam;
  if (equivalentSort) {
    compareKeys = compareKeys.filter(key => key !== 'sort');
  }
  return compareKeys;
}
function serializeMapBounds(mapBounds) {
  if (mapBounds) {
    if (Array.isArray(mapBounds)) return mapBounds.join(';');
    if (typeof mapBounds === 'string') return mapBounds;
    if (isObservableArray(mapBounds)) return mapBounds.slice().join(';');
    return JSON.stringify(mapBounds);
  }
  return '';
}
function didGeoChange(prevStore, newStore) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    regionId: prevRegionId,
    latLong: prevLatLong,
    mapBounds: prevMapBounds,
    destination: prevDestination
  } = prevStore;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    regionId: newRegionId,
    latLong: newLatLong,
    mapBounds: newMapBounds,
    destination: newDestination
  } = newStore;
  // VRBO: workaround for typeahead setting `regionId` to null after the second search
  const isNewRegionIdEmpty = newRegionId === '' || newRegionId === null;
  const isNewLatLongEmpty = newLatLong === '';
  const isNewDestinationEmpty = newDestination === '';
  const didRegionIdChange = !isNewRegionIdEmpty && newRegionId !== prevRegionId;
  const didLatLongChange = !isNewLatLongEmpty && newLatLong !== prevLatLong;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const didMapBoundsChange = serializeMapBounds(newMapBounds) !== serializeMapBounds(prevMapBounds);
  const didDestinationChange = !isNewDestinationEmpty && newDestination !== prevDestination;
  return didRegionIdChange || didMapBoundsChange || isNewRegionIdEmpty && (didLatLongChange || isNewLatLongEmpty && didDestinationChange);
}
export function hasStoresChanged(prevStore, newStore) {
  if (Boolean(newStore) && !prevStore) {
    return true;
  }
  return SearchCriteriaUtils.hasSearchCriteriaChanged(prevStore, newStore, getComparableKeys(prevStore, newStore));
}
export function isServerSide() {
  return typeof window === 'undefined';
}