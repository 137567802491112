export const CUSTOM_PLACES_KEY = 'map_custom_places';
export function loadCustomPlacesFromStorage() {
  const customPlacesAsJSON = localStorage.getItem(CUSTOM_PLACES_KEY);
  if (!customPlacesAsJSON) return null;
  return JSON.parse(customPlacesAsJSON);
}
export function clearCustomPlacesFromStorage() {
  localStorage.removeItem(CUSTOM_PLACES_KEY);
  return null;
}
export function saveCustomPlacesInStorage(customPlaces) {
  localStorage.setItem(CUSTOM_PLACES_KEY, JSON.stringify(customPlaces));
}