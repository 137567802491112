var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { action, computed, observable } from 'mobx';
import { getBexApiContext } from 'bernie-context';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import * as cookie from 'cookie';
import { multiUnitVrTemplate } from 'src/common/templates/property-details/multi-unit-vr-template';
import { listViewPillsExperimentTemplate } from 'src/common/templates/search/list-view-pills-experiment';
import { conventionalLodgingTemplate } from 'templates/property-details/conventional-lodging.template';
import { singleOfferLargeTemplate } from 'templates/property-details/single-offer-large.template';
import { singleOfferSmallTemplate } from 'templates/property-details/single-offer-small.template';
import { listViewTemplate } from 'templates/search/list-view.template';
import { STORE_EVENTS } from '../events/store-events';
import { getProductCardAdaptiveViewType, isBasePdpTemplate, isPdpTemplate, isSrpTemplate, PdpTemplate, SrpTemplate, ProductCardAdaptiveView } from './page-layout-store-utils';
export class PageLayoutStore extends Store {
  get isSplitView() {
    return this.templateName === SrpTemplate.SplitView;
  }
  setProductCardAdaptiveViewType(type) {
    this.productCardAdaptiveViewType = type;
  }
  get isGridViewListingCards() {
    return this.productCardAdaptiveViewType === ProductCardAdaptiveView.GRID;
  }
  constructor(bexApiSource, state = {}, cookies = '', logger = NOOP_LOGGER) {
    super(state, logger);
    this.bexApiSource = bexApiSource;
    this.getSRPTemplate = templateName => {
      const templateMap = {
        LIST_VIEW: listViewTemplate,
        SPLIT_VIEW: null,
        LIST_VIEW_PILLS_EXP: listViewPillsExperimentTemplate
      };
      if (templateName && isSrpTemplate(templateName) && templateMap[templateName]) {
        return templateMap[templateName];
      }
      const message = `No matching templateName="${templateName}". Falling back to default.`;
      this.logger.logEvent(STORE_EVENTS.DEFAULT_SRP_PAGE_LAYOUT, message);
      return listViewTemplate;
    };
    this.getPDPTemplate = templateName => {
      const templateMap = {
        SINGLE_UNIT_VR_LARGE: singleOfferLargeTemplate,
        SINGLE_UNIT_VR_SMALL: singleOfferSmallTemplate,
        CONVENTIONAL_LODGING_LARGE: conventionalLodgingTemplate,
        CONVENTIONAL_LODGING_SMALL: conventionalLodgingTemplate,
        MULTI_UNIT_VR_LARGE: multiUnitVrTemplate,
        MULTI_UNIT_VR_SMALL: multiUnitVrTemplate
      };
      if (templateName && isPdpTemplate(templateName) && templateMap[templateName]) {
        return templateMap[templateName];
      }
      const message = `No matching templateName="${templateName}". Falling back to default.`;
      this.logger.logEvent(STORE_EVENTS.DEFAULT_PDP_PAGE_LAYOUT, message);
      return conventionalLodgingTemplate;
    };
    if (cookies && !this.productCardAdaptiveViewType) {
      const parsedCookies = cookie.parse(cookies);
      if (parsedCookies.productCardViewType) {
        this.productCardAdaptiveViewType = getProductCardAdaptiveViewType(parsedCookies.productCardViewType);
      }
    }
  }
  hydrate(data) {
    delete data.source;
    delete data.bexApiSource;
    this.templateName = data.templateName;
  }
  fetchPDP(propertyId, context, isPackagePath) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a, _b, _c, _d;
      if (!propertyId || propertyId === '' || isPackagePath) {
        this.templateName = PageLayoutStore.DEFAULT_PDP_TEMPLATE_NAME;
      } else if (!this.templateName || isSrpTemplate(this.templateName)) {
        const variables = this.buildVariables(context, propertyId);
        const result = yield this.bexApiSource.fetchPageLayoutTemplate(variables);
        const templateName = (_d = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.propertyInfo) === null || _b === void 0 ? void 0 : _b.experimental) === null || _c === void 0 ? void 0 : _c.pageLayoutExperimentalFeature) === null || _d === void 0 ? void 0 : _d.templateName;
        this.templateName = templateName !== null && templateName !== void 0 ? templateName : PageLayoutStore.DEFAULT_PDP_TEMPLATE_NAME;
      }
    });
  }
  setSRPTemplate(context, isPackagePath, useBEXQuickFiltersTemplate, isBEXGSplitViewExperimentEnabled) {
    if (isPackagePath) {
      this.templateName = SrpTemplate.ListView;
      return;
    }
    if (useBEXQuickFiltersTemplate) {
      this.templateName = SrpTemplate.listViewPillsExp;
      return;
    }
    if (isBEXGSplitViewExperimentEnabled) {
      this.templateName = SrpTemplate.SplitView;
      return;
    }
    if (!this.templateName || isBasePdpTemplate(this.templateName)) {
      switch (context.site.brand) {
        case 'vrbo':
          this.templateName = SrpTemplate.SplitView;
          break;
        default:
          this.templateName = SrpTemplate.ListView;
      }
    }
  }
  buildVariables(context, propertyId) {
    return {
      context: getBexApiContext(context),
      shoppingContext: null,
      propertyId
    };
  }
}
PageLayoutStore.DEFAULT_PDP_TEMPLATE_NAME = PdpTemplate.ConventionalLodging;
__decorate([observable], PageLayoutStore.prototype, "templateName", void 0);
__decorate([observable], PageLayoutStore.prototype, "productCardAdaptiveViewType", void 0);
__decorate([computed], PageLayoutStore.prototype, "isSplitView", null);
__decorate([action], PageLayoutStore.prototype, "setProductCardAdaptiveViewType", null);
__decorate([computed], PageLayoutStore.prototype, "isGridViewListingCards", null);