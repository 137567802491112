import { parseDate } from 'bernie-l10n';
export class DateUtils {
  constructor(l10n) {
    this.localization = l10n;
    this.getISODayAfter = this.getISODayAfter.bind(this);
    this.getISODayBefore = this.getISODayBefore.bind(this);
    this.getNDaysFrom = this.getNDaysFrom.bind(this);
    this.getToday = this.getToday.bind(this);
    this.isSame = this.isSame.bind(this);
  }
  getDaysBetween(startISODate, endISODate) {
    const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
    const endDateString = parseDate(endISODate).toString();
    const startDateString = parseDate(startISODate).toString();
    return Math.floor((Date.parse(endDateString) - Date.parse(startDateString)) / MILLISECONDS_PER_DAY);
  }
  getISODayAfter(currentISODate) {
    return this.getNDaysFrom(currentISODate, 1);
  }
  getISODayBefore(currentISODate) {
    return this.getNDaysFrom(currentISODate, -1);
  }
  getNDaysFrom(currentISODate, n) {
    const currentDate = parseDate(currentISODate);
    const isoDayBefore = parseDate(currentISODate);
    isoDayBefore.setDate(currentDate.getDate() + n);
    return this.localization.formatDate(isoDayBefore, {
      raw: 'yyyy-MM-dd'
    });
  }
  getToday() {
    const todayAsDate = new Date();
    const normalizedDate = new Date(todayAsDate.getFullYear(), todayAsDate.getMonth(), todayAsDate.getDate());
    return this.localization.formatDate(normalizedDate, {
      raw: 'yyyy-MM-dd'
    });
  }
  static isBefore(firstISODate, secondISODate) {
    const firstDate = parseDate(firstISODate);
    const secondDate = parseDate(secondISODate);
    return firstDate < secondDate;
  }
  isSame(firstISODate, secondISODate) {
    const firstDate = parseDate(firstISODate);
    const secondDate = parseDate(secondISODate);
    return firstDate.getTime() === secondDate.getTime();
  }
  static parseDateString(dateString) {
    const dateParts = dateString === null || dateString === void 0 ? void 0 : dateString.split('-');
    if (!dateParts || !dateParts[0] || !dateParts[1] || !dateParts[2]) {
      return;
    }
    return {
      day: Number.parseInt(dateParts[2], 10),
      month: Number.parseInt(dateParts[1], 10),
      year: Number.parseInt(dateParts[0], 10)
    };
  }
  static parseStringDate(date) {
    if (!(date === null || date === void 0 ? void 0 : date.day) || !(date === null || date === void 0 ? void 0 : date.month) || !(date === null || date === void 0 ? void 0 : date.year)) {
      return;
    }
    return `${date.year}-${date.month}-${date.day}`;
  }
  static hashDateRange(dateRange) {
    if (!dateRange.checkInDate || !dateRange.checkOutDate) {
      return '';
    }
    return `${DateUtils.hashDateInput(dateRange.checkInDate)}--${DateUtils.hashDateInput(dateRange.checkOutDate)}`;
  }
  static formatDateStringToIsoDate(dateString, localDateFormat, l10n) {
    let localDate = l10n.parseLocalDateString(dateString, {
      raw: localDateFormat
    });
    if (!localDate) {
      localDate = l10n.parseLocalDateString(dateString, {
        raw: 'yyyy-MM-dd'
      });
    }
    const cleanDate = l10n.formatDate(localDate, {
      raw: 'yyyy-MM-dd'
    });
    return cleanDate !== null && cleanDate !== void 0 ? cleanDate : undefined;
  }
  static hashDateInput(date) {
    return `${date.year}-${date.month}-${date.day}`;
  }
}