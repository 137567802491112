import { queryObject } from './adapters';
const rooms = {
  parseIn: query => {
    query = queryObject(query);
    const roomsCollection = [];
    const adultsRegex = /^(\d+)$/i;
    const childrenRegex = /^(\d+)_(\d+)$/i;
    const adults = String(query.adultsString || query.adults);
    const children = String(query.childrenString || query.children);
    /*
     sample query object:
          {
     adults: '2,2,1',
     children: '1_0,1_3,2_4,2_1,3_0',
     foo: 'bar'
     }
     */
    // extract valid rooms from the query object and parseIn the adults
    if (adults && adults.length > 0) {
      adults.split(',').forEach(adultCount => {
        if (adultsRegex.test(adultCount)) {
          roomsCollection.push({
            adults: parseInt(adultCount, 10) || 0,
            children: []
          });
        }
      });
      delete query.adults;
    }
    // cycle through the rooms and parseIn their children
    if (children && children.length > 0) {
      children.split(',').forEach(child => {
        if (childrenRegex.test(child)) {
          const childParts = childrenRegex.exec(child);
          const childRoom = parseInt((childParts === null || childParts === void 0 ? void 0 : childParts[1]) || '', 10);
          const childAge = parseInt((childParts === null || childParts === void 0 ? void 0 : childParts[2]) || '', 10);
          if (!isNaN(childRoom) && !isNaN(childAge) && roomsCollection[childRoom - 1]) {
            roomsCollection[childRoom - 1].children.push({
              age: childAge
            });
          }
        }
      });
      delete query.children;
    }
    query.rooms = roomsCollection;
    delete query.adultsString;
    delete query.childrenString;
    return query;
  },
  parseOut: query => {
    query = queryObject(query);
    const adults = [];
    const children = [];
    const roomsCollection = query && query.rooms;
    if (typeof roomsCollection === 'object' && roomsCollection && roomsCollection.length > 0) {
      roomsCollection.forEach((room, index) => {
        if (room.adults > 0) {
          adults.push(room.adults);
        }
        if (room.children) {
          room.children.forEach(child => {
            children.push(`${index + 1}_${child.age || 0}`);
          });
        }
      });
      delete query.rooms;
    }
    if (adults.length > 0) {
      query.adults = adults.join(',');
    }
    if (children.length > 0) {
      query.children = children.join(',');
    }
    return query;
  }
};
function parseIn(query) {
  return rooms.parseIn(query);
}
function parseOut(query) {
  return rooms.parseOut(query);
}
export const SearchQueryAdapter = {
  parseIn,
  parseOut
};