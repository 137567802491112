import { addTypenameToDocument } from '@apollo/client/utilities';
import { print } from 'graphql';
import { __PropertyListingQuery } from '@shared-ui/lodging-property-listing';
const defaultWrapper = action => action();
// This logic follows the existing pattern in types.ts
export function getSharedUiSdk(client, withWrapper = defaultWrapper) {
  const propertyListingQueryWithTypenames = addTypenameToDocument(__PropertyListingQuery);
  const PropertyListingQueryString = print(propertyListingQueryWithTypenames);
  return {
    propertyListing(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(PropertyListingQueryString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'PropertyListing', 'query');
    }
  };
}