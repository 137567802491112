var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable } from 'mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
export class UIPageStore extends Store {
  /* tslint:disable:unnecessary-constructor */
  constructor(state = {}, logger = NOOP_LOGGER) {
    super(state, logger);
  }
  hydrate(data) {
    Object.assign(this, data);
  }
  showProgressIndicator(minDisplayTime) {
    this.displayProgressIndicator = true;
    if (minDisplayTime) {
      setTimeout(() => {
        this.hideProgressIndicator();
      }, minDisplayTime);
    }
  }
  setFocusItem(targetKey) {
    let claimed = false;
    const executor = (executorTargetKey, callback) => {
      if (!claimed && executorTargetKey === targetKey && typeof callback === 'function') {
        claimed = true;
        if (typeof callback === 'function') {
          callback();
        }
      }
    };
    if (this.focusItem) {
      this.focusItem.targetKey = targetKey;
      this.focusItem.executor = executor;
    }
  }
  hideProgressIndicator() {
    this.displayProgressIndicator = false;
  }
  showNotification(message) {
    this.notifications.show = true;
    this.notifications.message = message;
    // clears any existing notifications
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
    this.notificationTimeout = setTimeout(() => {
      this.resetNotification();
    }, 3000);
  }
  resetNotification() {
    this.notifications.show = false;
    this.notifications.message = '';
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
  }
  dispatchOpenNewDatePickerEvent() {
    this.openNewDatePickerEvent = Symbol('date picker open event');
  }
}
UIPageStore.DEFAULTS = {
  displayProgressIndicator: false,
  focusItem: {
    targetKey: null,
    executor: null
  },
  notifications: {
    show: false,
    message: ''
  },
  openNewDatePickerEvent: null
};
__decorate([observable], UIPageStore.prototype, "displayProgressIndicator", void 0);
__decorate([observable], UIPageStore.prototype, "notifications", void 0);
__decorate([observable], UIPageStore.prototype, "notificationTimeout", void 0);
__decorate([observable], UIPageStore.prototype, "openNewDatePickerEvent", void 0);
__decorate([observable], UIPageStore.prototype, "focusItem", void 0);
__decorate([action], UIPageStore.prototype, "showProgressIndicator", null);
__decorate([action], UIPageStore.prototype, "setFocusItem", null);
__decorate([action], UIPageStore.prototype, "hideProgressIndicator", null);
__decorate([action], UIPageStore.prototype, "showNotification", null);
__decorate([action], UIPageStore.prototype, "resetNotification", null);
__decorate([action], UIPageStore.prototype, "dispatchOpenNewDatePickerEvent", null);