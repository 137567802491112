/* eslint-disable max-lines */
import { toJS } from 'mobx';
import { merge } from 'bernie-core';
import { getErrorString, NOOP_LOGGER } from 'bernie-logger';
import { SOURCE_EVENTS } from './events/source-events';
// Parse and visit will need to be added to graphql imports for __typename testing
const {
  withoutKeys
} = merge;
export class BexApiSource {
  constructor(api, logger = NOOP_LOGGER) {
    this.api = api;
    this.logger = logger;
  }
  fetchPageLayoutTemplate(variables) {
    return this.api.PropertyInfoPageLayout(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_LODGING_PROPERTY_INFO_PAGE_LAYOUT_FETCH_ERROR));
  }
  // TODO clean -- x-enable-apq extra header --  related code after shared-ui query migration is done
  searchProperties(variables, pageId) {
    return this.api.LodgingPwaPackagesSearch(variables, {
      'x-page-id': pageId,
      'x-enable-apq': 'true'
    }).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_LODGING_FULL_PROPERTY_SEARCH_FETCH_ERROR));
  }
  searchPropertyListings(variables, pageId) {
    return this.api.propertyListing(variables, {
      'x-page-id': pageId,
      'x-enable-apq': 'true'
    }).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_LODGING_FULL_PROPERTY_SEARCH_FETCH_ERROR));
  }
  createSessionCart(variables) {
    return this.api.CreateSessionCart(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_CREATE_CART_ERROR));
  }
  addPropertyToCart(variables) {
    return this.api.AddPropertyToCart(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_ADD_ITEM_TO_CART_ERROR));
  }
  createMultiItemSession(variables) {
    return this.api.CreateMultiItemSession(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_CREATE_MULTIITEM_SESSION));
  }
  selectProducts(variables) {
    return this.api.SelectProducts(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_SELECT_PRODUCTS));
  }
  initiateMultiItemSession(variables) {
    return this.api.InitiateMultiItemSession(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_INITIATE_MULTIITEM_SESSION));
  }
  fetchCart(variables) {
    return this.api.Cart(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_CART_FETCH_ERROR));
  }
  removeItemFromCart(variables) {
    return this.api.RemoveItemFromCart(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_REMOVE_ITEM_FROM_CART_ERROR));
  }
  emptyCart(variables) {
    return this.api.EmptyCart(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_REMOVE_ITEM_FROM_CART_ERROR));
  }
  prepareCheckout(variables) {
    return this.api.PrepareCheckout(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_PREPARE_CHECKOUT_ERROR));
  }
  prepareCheckoutByCartId(variables) {
    return this.api.PrepareCheckoutByCartId(variables).catch(this.getErrorHandler(variables, SOURCE_EVENTS.BEX_API_MULTIITEM_PREPARE_CHECKOUT_BY_CART_ID_ERROR));
  }
  getErrorHandler(variables, sourceEvent) {
    return error => {
      this.logger.logEvent(sourceEvent, variables, getErrorString(error));
      // Throw the error to the next catch block since it is not resolved here
      throw error;
    };
  }
  /**
   * Override the default `toJson` to exclude the Apollo client.
   * If we don't include this, we will get a JSON serialization error.
   *
   * Creates a basic JSON object from the store, excluding
   * any fields provided in `ignoreAdditionalKeys`.
   */
  toJSON(ignoreAdditionalKeys = []) {
    if (!Array.isArray(ignoreAdditionalKeys)) {
      ignoreAdditionalKeys = ignoreAdditionalKeys.constructor === String ? [ignoreAdditionalKeys] : [];
    }
    const regexIgnorePrivate = /^_.+/;
    const simpleObject = toJS(this);
    const ignoreKeys = Object.keys(simpleObject).filter(key => regexIgnorePrivate.test(key) || typeof simpleObject[key] === 'function').concat(ignoreAdditionalKeys, 'logger', 'api', 'headers', 'apiWithFederation');
    return withoutKeys(ignoreKeys).merge({}, simpleObject);
  }
}