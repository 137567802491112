import { comparer, toJS } from 'mobx';
// In a url, an empty array is equivalent to an empty string and a single element array is equivalent to a string of that element
const convertEmptyOrSingleElementArrayToString = param => {
  if (Array.isArray(param) && param.length < 2) {
    return param.toString();
  }
  return param;
};
export const haveQueryParamChanged = (currentParam, newParam) => {
  const convertedNewParam = convertEmptyOrSingleElementArrayToString(newParam);
  const convertedCurrentParam = convertEmptyOrSingleElementArrayToString(currentParam);
  return !comparer.structural(convertedCurrentParam, convertedNewParam);
};
export const processClientSideDefaultRequestFields = (isServer, store, nextQuery) => {
  const defaultRequestFields = toJS(store.defaultRequestFields);
  let newQuery;
  if (!isServer && defaultRequestFields && Object.keys(defaultRequestFields).length > 0) {
    const validQuery = !nextQuery || Object.keys(defaultRequestFields).some(key => Boolean(!nextQuery[key] || haveQueryParamChanged(nextQuery[key], defaultRequestFields[key])));
    if (validQuery) {
      const dialogPattern = /dialog/i;
      const dialogs = Object.fromEntries([...new URLSearchParams(window.location.search).entries()].filter(([key]) => dialogPattern.test(key)));
      newQuery = {
        query: Object.assign(Object.assign({}, dialogs), defaultRequestFields),
        replace: true
      };
    }
    store.defaultRequestFields = {};
  }
  return newQuery;
};