export var MarketingChannels;
(function (MarketingChannels) {
  MarketingChannels["mctc"] = "mctc";
  MarketingChannels["emlcid"] = "emlcid";
  MarketingChannels["affcid"] = "affcid";
  MarketingChannels["afmcid"] = "afmcid";
  MarketingChannels["icmcid"] = "icmcid";
  MarketingChannels["mdpcid"] = "mdpcid";
  MarketingChannels["semcid"] = "semcid";
  MarketingChannels["olacid"] = "olacid";
  MarketingChannels["brandcid"] = "brandcid";
  MarketingChannels["seocid"] = "seocid";
  MarketingChannels["emldtl"] = "emldtl";
  MarketingChannels["afflid"] = "afflid";
  MarketingChannels["kword"] = "kword";
  MarketingChannels["icmdtl"] = "icmdtl";
  MarketingChannels["mdpdtl"] = "mdpdtl";
  MarketingChannels["semdtl"] = "semdtl";
  MarketingChannels["oladtl"] = "oladtl";
  MarketingChannels["gclid"] = "gclid";
  MarketingChannels["lnkloc"] = "lnkloc";
  // eslint-disable-next-line camelcase
  MarketingChannels["k_user_id"] = "k_user_id";
  MarketingChannels["token"] = "token";
})(MarketingChannels || (MarketingChannels = {}));
export var MarketingChannelsTypeCodes;
(function (MarketingChannelsTypeCodes) {
  MarketingChannelsTypeCodes["KAYAK"] = "4";
  MarketingChannelsTypeCodes["TRIVAGO"] = "5";
  MarketingChannelsTypeCodes["TRIPADVISOR"] = "9";
  MarketingChannelsTypeCodes["GOOGLE_HA"] = "10";
})(MarketingChannelsTypeCodes || (MarketingChannelsTypeCodes = {}));