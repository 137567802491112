var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
import { action, computed, extendObservable, flow, observable } from 'mobx';
import { timeoutPromise } from 'bernie-core';
import { getErrorString, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { EGDSMapInitialViewport } from '__generated__/api';
import { mergeAndConcat } from 'merge-anything';
import { adaptPropertySearch, getPropertySearchInputWithSearchId } from 'stores/adapters';
import { mapOmnitureData } from 'stores/adapters/bex-api/search/search-results-adapter';
import { STORE_EVENTS } from 'stores/events/store-events';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { getProductCardAdaptiveViewTypeFromTestBucket } from 'stores/page-layout/page-layout-store-utils';
import { isServerSide } from 'stores/utils';
import { publishPageViewEvents } from 'utils/analytics/analytics-util';
import { isTablet } from 'utils/is-mobile';
import { isBexSplitView } from '../utils/is-bex-split-view';
import { isVrbo } from './context-store-utils';
export class SearchResultsStore extends Store {
  constructor(bexApiSource, state, logger = NOOP_LOGGER, l10n) {
    super(state, logger);
    this.bexApiSource = bexApiSource;
    this.prefetchedMultiItemSession = false;
    this.initialLoadSearchPagination = undefined;
    this.isSecondaryFetchDisabled = false;
    this.isScrollToTopOnSearchDisabled = false;
    // Currently reduced fetch is only enabled on Vrbo && 48871 and Bex Split view
    this.isReducedFetchEnabled = (context, experiments) => {
      var _a, _b;
      if (isVrbo(context)) {
        return Boolean(((_b = (_a = experiments.exposures) === null || _a === void 0 ? void 0 : _a.vrbex_lodging_search_results_reduced_property_listings_feature_toggle) === null || _b === void 0 ? void 0 : _b.bucket) === 1);
      }
      return isBexSplitView(context, experiments);
    };
    this.fitBoundsToMarkerAfterSecondFetch = (experiments, context) => {
      var _a, _b;
      return ((_b = (_a = experiments.exposures) === null || _a === void 0 ? void 0 : _a.VRBO_Gaia_Viewport_Bounding_Box) === null || _b === void 0 ? void 0 : _b.bucket) === 2 || isBexSplitView(context, experiments);
    };
    /*
     * The flow wrapper is an optional alternative to async / await that makes it easier
     * to work with MobX actions and allows the promise returned to be cancelled.
     * https://mobx.js.org/actions.html#using-flow-instead-of-async--await-
     */
    //
    this.createSearchResultsFetchFlow = flow(function* (timeout, stores, additionalCriteria = {}, includeDynamicMap, passPrimaryProductInput) {
      var _a, _b, _c;
      // @ts-expect-error self alias required for mobx flow syntax and typescript compatibility
      // eslint-disable-next-line @typescript-eslint/no-this-alias,@typescript-eslint/ban-ts-comment,consistent-this
      const self = this;
      self.prefetchedMultiItemSession = false;
      self.shouldFetch = false;
      self.isLoading = true;
      self.error = undefined;
      self.errors = [];
      self.propertySearchListings = [];
      self.omnitureData = [];
      // enable split view listing scroll to top on any new search
      self.isScrollToTopOnSearchDisabled = false;
      const {
        analytics,
        analyticsSearch,
        context,
        experiment,
        multiItem,
        searchCriteria,
        pageLayout
      } = stores;
      const multiItemContext = multiItem ? multiItem.multiItemContext : null;
      const criteriaJson = (_a = self.searchCriteria) === null || _a === void 0 ? void 0 : _a.toJSON();
      const bexApiCriteria = Object.assign(Object.assign({}, criteriaJson), additionalCriteria);
      const primaryProductShoppingCriteriaInput = (multiItem ? !multiItem.isServerSide() : false) && searchCriteria && passPrimaryProductInput && ((_b = experiment.exposures.TSDP_SRP_ServerDrivenSessionCreation) === null || _b === void 0 ? void 0 : _b.bucket) === 1 ? multiItem === null || multiItem === void 0 ? void 0 : multiItem.getPrimaryProductShoppingCriteriaInput(searchCriteria) : undefined;
      let propertySearchFetch;
      try {
        if (multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) {
          propertySearchFetch = timeoutPromise(self.searchProperties(context, multiItemContext, bexApiCriteria, analyticsSearch, self.initialLoadSearchPagination, includeDynamicMap, primaryProductShoppingCriteriaInput, pageLayout), timeout, 'propertySearchFetch timeout error');
        } else {
          propertySearchFetch = timeoutPromise(self.propertyListings(context, multiItemContext, bexApiCriteria, analyticsSearch, self.initialLoadSearchPagination, includeDynamicMap, pageLayout), timeout, 'propertyListings timeout error');
        }
        const [bexApiResults] = yield Promise.all([propertySearchFetch]);
        if ((multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) && bexApiResults) {
          const shoppingContextMultiItemId = self.getMultiItemId(bexApiResults.data);
          multiItem.updateSessionId(shoppingContextMultiItemId);
          self.shoppingContextMultiItemId = shoppingContextMultiItemId;
        }
        self.errors = bexApiResults === null || bexApiResults === void 0 ? void 0 : bexApiResults.errors;
        const data = adaptPropertySearch(bexApiResults, multiItem);
        self.pendingSearchResultsFetchFlow = null;
        if (data) {
          const searchCriteria = (_c = self.searchCriteria) === null || _c === void 0 ? void 0 : _c.clone();
          const defaultRequestFields = data.defaultRequestFields;
          if (searchCriteria && defaultRequestFields) {
            Object.assign(searchCriteria, defaultRequestFields);
          }
          self.searchCriteria = searchCriteria;
          Object.assign(self, data);
          if (self.numberOfListingsFetched() > 0 && !self.isFetchSecondaryListingBatch(context, experiment)) {
            publishPageViewEvents(self.omnitureData, analytics, self.logger, experiment);
            self.omnitureData = [];
          }
        }
        self.isLoading = false;
        self.handleVRBOSecondaryFetch(context, experiment, pageLayout);
        return data;
      } catch (err) {
        self.logErrorEvent(STORE_EVENTS.FETCH_ERROR, err);
        // don't assign the error to this.error, as errors in this catch are technical and NOT user-friendly
        self.error = true;
        self.isLoading = false;
        self.shouldFetch = true;
        // recover and pass object with error details
        return {
          error: err
        };
      }
    }.bind(this));
    this.searchCriteria = new SearchCriteriaModel({}, l10n);
    this.fetch = this.fetch.bind(this);
    this.searchProperties = this.searchProperties.bind(this);
  }
  hydrate(data) {
    var _a;
    delete data.source;
    delete data.bexApiSource;
    (_a = this.searchCriteria) === null || _a === void 0 ? void 0 : _a.hydrate(data.searchCriteria || {});
    delete data.searchCriteria;
    extendObservable(this, data);
  }
  getInitialListingFetchCount(context, experiments, pageLayout) {
    var _a, _b;
    if (!(pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.productCardAdaptiveViewType)) {
      const gridViewBucket = (_b = (_a = experiments.exposures) === null || _a === void 0 ? void 0 : _a.SRP_Grid_view_web) === null || _b === void 0 ? void 0 : _b.bucket;
      pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.setProductCardAdaptiveViewType(getProductCardAdaptiveViewTypeFromTestBucket(gridViewBucket));
    }
    if (isVrbo(context)) {
      // Vrbo: if the secondary fetch is disabled, return the default fetch count
      if (this.isSecondaryFetchDisabled) {
        return SearchResultsStore.PROPERTIES_FETCH_COUNT;
      }
      if (this.isReducedFetchEnabled(context, experiments)) {
        return (pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.isGridViewListingCards) ? 12 : 10;
      }
      // control: returning 0 will make `isFetchSecondaryListingBatch` returns false to prevent the secondary fetch
      return 0;
    }
    if (isBexSplitView(context, experiments)) {
      if (this.isSecondaryFetchDisabled) {
        return 50;
      }
      return (pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.isGridViewListingCards) ? 12 : 10;
    }
    // for desktop, mweb and tablet
    if (pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.isGridViewListingCards) {
      return 6;
    }
    if (isTablet(context)) {
      return 4;
    }
    // mobile or desktop
    return 3;
  }
  getSecondaryListingFetchCount(context, experiments, pageLayout) {
    let totalListingFetchCount = 100;
    // condition could be replaced with isSplitView, but that needs pageLayout store for decision
    if (isVrbo(context) || isBexSplitView(context, experiments)) {
      totalListingFetchCount = SearchResultsStore.PROPERTIES_FETCH_COUNT;
    }
    return totalListingFetchCount - this.getInitialListingFetchCount(context, experiments, pageLayout);
  }
  get isError() {
    var _a;
    return Boolean(!this.isLoading && (this.error || ((_a = this.errors) === null || _a === void 0 ? void 0 : _a.length) || this.overFiltered || this.errorResponse || this.dialogErrorResponse));
  }
  showNextPage(analyticsStore, context, multiItem, uiPage, analyticsSearch, experiments, pageLayoutStore) {
    var _a, _b;
    if (!((_b = (_a = this.pagination) === null || _a === void 0 ? void 0 : _a.subSets) === null || _b === void 0 ? void 0 : _b.nextSubSet)) return;
    const {
      startingIndex
    } = this.pagination.subSets.nextSubSet;
    const pagInput = {
      startingIndex,
      size: SearchResultsStore.PROPERTIES_FETCH_COUNT
    };
    this.showItemsByPage(analyticsStore, context, multiItem, uiPage, pagInput, analyticsSearch, experiments, pageLayoutStore);
  }
  showPreviousPage(analyticsStore, context, multiItem, uiPage, analyticsSearch, experiments, pageLayoutStore) {
    var _a, _b;
    if (!((_b = (_a = this.pagination) === null || _a === void 0 ? void 0 : _a.subSets) === null || _b === void 0 ? void 0 : _b.previousSubset)) return;
    const {
      startingIndex
    } = this.pagination.subSets.previousSubset;
    const pageLoadFetchCount = this.getInitialListingFetchCount(context, experiments) + this.getSecondaryListingFetchCount(context, experiments, pageLayoutStore);
    const isBackToFirstPage = startingIndex < pageLoadFetchCount;
    const pagInput = {
      startingIndex: isBackToFirstPage ? 0 : startingIndex,
      size: isBackToFirstPage ? pageLoadFetchCount : SearchResultsStore.PROPERTIES_FETCH_COUNT
    };
    this.showItemsByPage(analyticsStore, context, multiItem, uiPage, pagInput, analyticsSearch, experiments, pageLayoutStore);
  }
  clearListingsForPageLoad(isSplitViewTemplate, isSecondaryFetch) {
    if (isSplitViewTemplate && !isSecondaryFetch) {
      this.propertySearchListings = [];
    }
  }
  disableSecondaryFetch(context, experiments, isSecondaryFetch) {
    var _a, _b;
    const reducedListingBucket = (_b = (_a = experiments.exposures) === null || _a === void 0 ? void 0 : _a.vrbex_lodging_search_results_reduced_property_listings_feature_toggle) === null || _b === void 0 ? void 0 : _b.bucket;
    if (isBexSplitView(context, experiments) && isSecondaryFetch) {
      this.isSecondaryFetchDisabled = true;
      return;
    }
    // On Vrbo, secondary fetch should be executed only once after the initial server-side/SSR fetch, or not at all
    if (isVrbo(context) && (reducedListingBucket === 0 || reducedListingBucket === 1 && isSecondaryFetch)) {
      this.isSecondaryFetchDisabled = true;
    }
  }
  updateVRBOPaginationForSecondaryFetch(context, experiments, isSecondaryFetch) {
    var _a;
    // disable previous button for secondary fetch & update pagination label to start from 1 instead of secondary fetch count
    // TODO: this logic should be moved to EALS when EG_Property_Search_Query_Migration is finalized
    if (this.pagination && this.isReducedFetchEnabled(context, experiments) && isSecondaryFetch) {
      this.pagination.trigger.previousButton.disabled = true;
      this.pagination.paginationLabel = (_a = this.pagination.paginationLabel) === null || _a === void 0 ? void 0 : _a.replace(/\d+/, '1');
    }
  }
  showItemsByPage(analyticsStore, context, multiItem, uiPage, paginationInput, analyticsSearch, experiments, pageLayoutStore, showProgressIndicator = true, isSecondaryFetch = false) {
    var _a, _b;
    const initialSearchId = analyticsSearch.searchId;
    const multiItemContext = (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) !== null && _a !== void 0 ? _a : null;
    const isSplitViewTemplate = (_b = pageLayoutStore === null || pageLayoutStore === void 0 ? void 0 : pageLayoutStore.isSplitView) !== null && _b !== void 0 ? _b : false;
    const isMultiItemPath = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
    const propertySearchFetch = isMultiItemPath ? this.searchProperties(context, multiItemContext, this.searchCriteria, analyticsSearch, paginationInput, isSplitViewTemplate, undefined, pageLayoutStore) : this.propertyListings(context, multiItemContext, this.searchCriteria, analyticsSearch, paginationInput, isSplitViewTemplate, pageLayoutStore);
    this.isPaginationLoading = true;
    if (showProgressIndicator) {
      uiPage === null || uiPage === void 0 ? void 0 : uiPage.showProgressIndicator();
    }
    this.clearListingsForPageLoad(isSplitViewTemplate, isSecondaryFetch);
    // TODO: consider disabling the secondary fetch after the secondary fetch is done
    this.disableSecondaryFetch(context, experiments, isSecondaryFetch);
    this.isScrollToTopOnSearchDisabled = isSplitViewTemplate && isSecondaryFetch;
    propertySearchFetch
    // eslint-disable-next-line complexity
    .then(({
      data,
      errors,
      extensions
    }) => {
      var _a, _b;
      const currentSearchId = analyticsSearch.searchId;
      if (initialSearchId !== currentSearchId) {
        this.logger.logEvent(STORE_EVENTS.BEX_API_SEARCH_RESULTS_STORE_EXPIRED_PAGINATION_FETCH_ERROR, 'expired pagination query data');
        return;
      }
      // -----------------------------------------------------------------------------------
      this.errors = errors !== null && errors !== void 0 ? errors : [];
      if (data === null || data === void 0 ? void 0 : data.propertySearch.pagination) {
        this.pagination = data.propertySearch.pagination;
        this.updateVRBOPaginationForSecondaryFetch(context, experiments, isSecondaryFetch);
      } else {
        this.clearPagination();
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.clickstream) {
        this.clickstreamEvents = data === null || data === void 0 ? void 0 : data.propertySearch.clickstream;
      }
      if (multiItem) {
        multiItem.updateSessionId(this.getMultiItemId(data));
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.dynamicMap) {
        const shouldMergeData = isSecondaryFetch && (pageLayoutStore === null || pageLayoutStore === void 0 ? void 0 : pageLayoutStore.isSplitView);
        if (shouldMergeData && this.dynamicMap) {
          const dynamicMap = mergeAndConcat(this.dynamicMap, data.propertySearch.dynamicMap) || Array;
          if ((dynamicMap === null || dynamicMap === void 0 ? void 0 : dynamicMap.map) && this.fitBoundsToMarkerAfterSecondFetch(experiments, context)) {
            dynamicMap.map.initialViewport = EGDSMapInitialViewport.FIT_MARKERS;
            dynamicMap.map.bounds = null;
          }
          this.dynamicMap = dynamicMap;
        } else {
          this.dynamicMap = (data === null || data === void 0 ? void 0 : data.propertySearch.dynamicMap) || Array;
        }
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.shoppingSearchListingHeader) {
        this.shoppingSearchListingHeader = data.propertySearch.shoppingSearchListingHeader || Array;
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.summary.resultMessages) {
        this.resultMessages = data.propertySearch.summary.resultMessages;
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.summary.shoppingJoinListContainer) {
        this.shoppingJoinListContainer = data.propertySearch.summary.shoppingJoinListContainer;
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.summary.contents) {
        this.contents = data.propertySearch.summary.contents;
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.summary.shoppingInvokeFunctionParams) {
        this.shoppingInvokeFunctionParams = data.propertySearch.summary.shoppingInvokeFunctionParams;
      }
      if (data === null || data === void 0 ? void 0 : data.propertySearch.summary.priceDisplayChangeOnboardingAction) {
        this.priceDisplayChangeOnboardingAction = data.propertySearch.summary.priceDisplayChangeOnboardingAction;
      }
      if (this.omnitureData) {
        this.omnitureData.push(mapOmnitureData(extensions.analytics));
        publishPageViewEvents(this.omnitureData, analyticsStore, this.logger, experiments);
        this.omnitureData = [];
      }
      this.propertySearchListings = [...((_a = this.propertySearchListings) !== null && _a !== void 0 ? _a : []), ...((_b = data === null || data === void 0 ? void 0 : data.propertySearch.propertySearchListings) !== null && _b !== void 0 ? _b : [])] || Array;
    }).catch(error => {
      this.logErrorEvent(STORE_EVENTS.BEX_API_SEARCH_RESULTS_STORE_FETCH_ERROR, error);
    }).finally(() => {
      // isPaginationLoading needs to change with new omniture data in one step
      this.isPaginationLoading = false;
      if (showProgressIndicator) {
        uiPage === null || uiPage === void 0 ? void 0 : uiPage.hideProgressIndicator();
      }
    });
  }
  numberOfListingsFetched() {
    var _a, _b;
    const lodgingCardCount = (_a = this.propertySearchListings) === null || _a === void 0 ? void 0 : _a.filter(listing => listing.__typename === 'LodgingCard').length;
    if (lodgingCardCount && lodgingCardCount > 0) {
      return lodgingCardCount;
    }
    return ((_b = this.propertySearchListings) === null || _b === void 0 ? void 0 : _b.length) || 0;
  }
  get thereAreMoreListingsToFetch() {
    var _a, _b, _c;
    return this.numberOfListingsFetched() < ((_a = this.totalPropertyCount) !== null && _a !== void 0 ? _a : 0) && Boolean((_c = (_b = this.pagination) === null || _b === void 0 ? void 0 : _b.subSets) === null || _c === void 0 ? void 0 : _c.nextSubSet);
  }
  isFetchSecondaryListingBatch(context, experiments, pageLayout) {
    if (this.numberOfListingsFetched() === 0) return false;
    const initialFetchCount = this.getInitialListingFetchCount(context, experiments, pageLayout);
    return this.thereAreMoreListingsToFetch && this.numberOfListingsFetched() <= initialFetchCount;
  }
  updateSearchCriteria(searchCriteria) {
    this.searchCriteria = searchCriteria;
    this.shouldFetch = true;
  }
  updateIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
  createSessionBeforeMultiItemFetch(multiItem, context, incomingSearchCriteria) {
    this.isLoading = true;
    this.shouldFetch = false;
    this.propertySearchListings = [];
    this.prefetchedMultiItemSession = true;
    return multiItem.createMultiItemSession(context, incomingSearchCriteria).then(misId => incomingSearchCriteria.misId = misId).catch(() => {
      this.isLoading = false;
      this.shouldFetch = true;
      this.prefetchedMultiItemSession = false;
      this.error = true;
    });
  }
  fetch(timeout, additionalCriteria, stores, includeDynamicMap, passPrimaryProductInput) {
    var _a, _b;
    if (!this.shouldFetch && !this.prefetchedMultiItemSession) {
      return Promise.resolve(this);
    }
    // cancel previous search fetch promise to prevent a race condition
    (_a = this.pendingSearchResultsFetchFlow) === null || _a === void 0 ? void 0 : _a.cancel();
    // residual omnitureData to publish, TRUE if the promise was cancelled & there were results (e.g. 5). && true if parent scope reset (new query executed)
    if ((_b = this.omnitureData) === null || _b === void 0 ? void 0 : _b.length) {
      publishPageViewEvents(this.omnitureData, stores.analytics, this.logger, stores.experiment);
      this.omnitureData = [];
    }
    this.pendingSearchResultsFetchFlow = this.createSearchResultsFetchFlow(timeout, stores, additionalCriteria, includeDynamicMap, passPrimaryProductInput);
    return this.pendingSearchResultsFetchFlow;
  }
  handleVRBOSecondaryFetch(context, experiments, pageLayout) {
    // secondary fetch should show pagination loading indicator while fetching the secondary listings
    if (this.isReducedFetchEnabled(context, experiments) && isServerSide()) {
      if (this.isFetchSecondaryListingBatch(context, experiments, pageLayout)) {
        this.isPaginationLoading = true;
        // disable secondary fetch after the secondary fetch is executed
      } else {
        // disable secondary fetch right away as there is no more listings to fetch
        this.isSecondaryFetchDisabled = true;
      }
    }
  }
  searchProperties(context, multiItemContext, combinedCriteria, analyticsSearch, searchPagination, includeDynamicMap, primaryProductShoppingCriteriaInput, pageLayout) {
    var _a;
    const variables = getPropertySearchInputWithSearchId(context, analyticsSearch, combinedCriteria, multiItemContext, searchPagination, includeDynamicMap, primaryProductShoppingCriteriaInput, pageLayout);
    return this.bexApiSource.searchProperties(variables, (_a = this.pageId) !== null && _a !== void 0 ? _a : '');
  }
  propertyListings(context, multiItemContext, combinedCriteria, analyticsSearch, searchPagination, includeDynamicMap, pageLayout) {
    var _a;
    const variables = getPropertySearchInputWithSearchId(context, analyticsSearch, combinedCriteria, multiItemContext, searchPagination, includeDynamicMap, undefined, pageLayout);
    return this.bexApiSource.searchPropertyListings(variables, (_a = this.pageId) !== null && _a !== void 0 ? _a : '');
  }
  logErrorEvent(event, err) {
    if (this.logger) {
      this.logger.logEvent(event, getErrorString(err));
    }
  }
  clearDefaultRequestFields() {
    this.defaultRequestFields = {};
  }
  clearPagination() {
    // todo: simplify this to `this.pagination = null` after removing the nonnullable from generated types
    Object.assign(this, {
      pagination: null
    });
  }
  setExpandForm(shouldExpandForm) {
    this.expandForm = shouldExpandForm;
  }
  getMultiItemId(data) {
    var _a, _b, _c;
    return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.propertySearch) === null || _a === void 0 ? void 0 : _a.shoppingContext) === null || _b === void 0 ? void 0 : _b.multiItem) === null || _c === void 0 ? void 0 : _c.id;
  }
  isTotalPropertyCountLessThanInitialListingFetchCount(context, experiments, pageLayout) {
    var _a;
    return ((_a = this.totalPropertyCount) !== null && _a !== void 0 ? _a : 0) < this.getInitialListingFetchCount(context, experiments, pageLayout) + this.getSecondaryListingFetchCount(context, experiments, pageLayout);
  }
}
// public static INITIAL_LISTING_FETCH_COUNT = 5;
// public static SECONDARY_LISTING_FETCH_COUNT = 95;
SearchResultsStore.INITIAL_HOTEL_RENDER_COUNT = 50;
SearchResultsStore.PROPERTIES_FETCH_COUNT = 50;
SearchResultsStore.HOTEL_TOTAL_RENDER_COUNT = 50;
SearchResultsStore.DEFAULTS = {
  expandForm: false,
  foregoClientFetch: false,
  propertyListingAdaptexAnalyticsSuccessEvents: [],
  propertyListingToast: undefined,
  propertySearchListings: [],
  hotelsMaxDisplay: SearchResultsStore.INITIAL_HOTEL_RENDER_COUNT,
  isLoading: true,
  lodgingPriceBreakDown: {},
  loyaltyMessagingModel: undefined,
  messagingModule: undefined,
  messages: [],
  omnitureData: [],
  pageData: {},
  shouldFetch: true,
  totalPropertyCount: 0,
  isPaginationLoading: false,
  shoppingSearchListingHeader: []
};
__decorate([observable], SearchResultsStore.prototype, "error", void 0);
__decorate([observable], SearchResultsStore.prototype, "errors", void 0);
__decorate([observable], SearchResultsStore.prototype, "expandForm", void 0);
__decorate([observable], SearchResultsStore.prototype, "foregoClientFetch", void 0);
__decorate([observable], SearchResultsStore.prototype, "propertySearchListings", void 0);
__decorate([observable], SearchResultsStore.prototype, "shoppingSearchListingHeader", void 0);
__decorate([observable], SearchResultsStore.prototype, "propertyListingAdaptexAnalyticsSuccessEvents", void 0);
__decorate([observable], SearchResultsStore.prototype, "propertyListingToast", void 0);
__decorate([observable], SearchResultsStore.prototype, "hotelsMaxDisplay", void 0);
__decorate([observable], SearchResultsStore.prototype, "isLoading", void 0);
__decorate([observable], SearchResultsStore.prototype, "loyaltyMessagingModel", void 0);
__decorate([observable], SearchResultsStore.prototype, "omnitureData", void 0);
__decorate([observable], SearchResultsStore.prototype, "overFiltered", void 0);
__decorate([observable], SearchResultsStore.prototype, "shouldFetch", void 0);
__decorate([observable], SearchResultsStore.prototype, "resultMessages", void 0);
__decorate([observable], SearchResultsStore.prototype, "messagingModule", void 0);
__decorate([observable], SearchResultsStore.prototype, "totalPropertyCount", void 0);
__decorate([observable.ref], SearchResultsStore.prototype, "pageData", void 0);
__decorate([observable.ref], SearchResultsStore.prototype, "searchCriteria", void 0);
__decorate([observable], SearchResultsStore.prototype, "shoppingContextMultiItemId", void 0);
__decorate([observable], SearchResultsStore.prototype, "errorResponse", void 0);
__decorate([observable], SearchResultsStore.prototype, "dialogErrorResponse", void 0);
__decorate([observable], SearchResultsStore.prototype, "pagination", void 0);
__decorate([observable], SearchResultsStore.prototype, "priceDisplayChangeOnboardingAction", void 0);
__decorate([observable], SearchResultsStore.prototype, "contents", void 0);
__decorate([observable], SearchResultsStore.prototype, "shoppingInvokeFunctionParams", void 0);
__decorate([observable], SearchResultsStore.prototype, "shoppingJoinListContainer", void 0);
__decorate([observable], SearchResultsStore.prototype, "mapTrigger", void 0);
__decorate([observable], SearchResultsStore.prototype, "isPaginationLoading", void 0);
__decorate([observable], SearchResultsStore.prototype, "dynamicMap", void 0);
__decorate([observable], SearchResultsStore.prototype, "packageProductCard", void 0);
__decorate([observable], SearchResultsStore.prototype, "clickstreamEvents", void 0);
__decorate([computed], SearchResultsStore.prototype, "isError", null);
__decorate([action], SearchResultsStore.prototype, "showNextPage", null);
__decorate([action], SearchResultsStore.prototype, "showPreviousPage", null);
__decorate([computed], SearchResultsStore.prototype, "thereAreMoreListingsToFetch", null);
__decorate([action], SearchResultsStore.prototype, "updateSearchCriteria", null);
__decorate([action], SearchResultsStore.prototype, "updateIsLoading", null);
__decorate([action], SearchResultsStore.prototype, "createSessionBeforeMultiItemFetch", null);
__decorate([action], SearchResultsStore.prototype, "fetch", null);
__decorate([action], SearchResultsStore.prototype, "createSearchResultsFetchFlow", void 0);
__decorate([action], SearchResultsStore.prototype, "clearDefaultRequestFields", null);
__decorate([action], SearchResultsStore.prototype, "clearPagination", null);
__decorate([action], SearchResultsStore.prototype, "setExpandForm", null);