/* eslint-disable camelcase */
export const meta = {
  home: {
    robots: 'noodp, noydir',
    canonical: site => `https://${site.domain}/`,
    1: {
      default: {
        title: 'Expedia Travel: Vacations, Cheap Flights, Airline Tickets & Airfares',
        description: 'Plan your trip with Expedia. Buy airline tickets, read reviews & reserve a hotel. Find deals on vacations, rental cars & cruises. Great prices guaranteed!'
      },
      zh_CN: {
        title: '旅行预订网站：特价酒店预订，机票查询，自游行网上优惠 | Expedia.com',
        description: ' Expedia 作为一站式旅游服务平台，为您提供特价酒店预订、机票查询和自助游网上优惠套餐。立即到 Expedia.com 体验方便快速旅游度假预订服务。'
      },
      es_MX: {
        title: 'Expedia: hoteles, vuelos, renta de autos, cruceros y rentas vacacionales | Expedia',
        description: 'Planea tu viaje con Expedia. Compra boletos de avión, lee reseñas y reserva tu hotel. Descubre paquetes vacacionales, renta de autos y cruceros a los mejores precios.'
      }
    },
    8: {
      default: {
        title: 'Hotel, Voli e Noleggio Auto | Prenota viaggi e vacanze con Expedia.it',
        description: 'Scegli su Expedia tra migliaia di opportunità di viaggio'
      }
    },
    17: {
      default: {
        title: 'จองโรงแรม ตั๋วเครื่องบิน แพ็คเกจท่องเที่ยวออนไลน์ | Expedia.co.th',
        description: 'Expedia บริษัทท่องเที่ยวชั้นนำ รับจองโรงแรม เที่ยวบิน และแพ็คเกจท่องเที่ยว(โรงแรม+เที่ยวบิน) ครบวงจร เสนอโรงแรมและสายการบินจากทั่วโลก ราคาหลากหลายและพิเศษ'
      },
      en_GB: {
        title: 'Travel: Cheap Hotels, Air Tickets & Packages | Expedia.co.th',
        description: 'Expedia.co.th: your one-stop online travel agent for the perfect holiday. Bringing you 1000s of cheap hotels, flights and holiday package deals.'
      }
    },
    18: {
      default: {
        title: 'Expedia 智遊網 旅遊: 渡假, 平價航班, 航空公司機票及票價',
        description: '智遊網(Expedia HK): 您的一站式網上旅行社服務平台,為您提供超過321,000酒店, 400間航空公司以及自由行套票服務。立刻預定機票，酒店及自由行套票享受獨家優惠折扣。'
      },
      en_HK: {
        title: 'Travel: Cheap Hotels, Air Tickets & Packages | Expedia.com.hk',
        description: 'Expedia.com.hk: your one-stop online travel agent for the perfect holiday. Bringing you 1000s of cheap hotels, flights and holiday package deals.'
      },
      zh_CN: {
        title: '旅行：廉价酒店和机票、自由行套票优惠在线预订 | 智游网 Expedia.com.hk',
        description: ' 旅行：廉价酒店和机票、自由行套票优惠在线预订 | 智游网 Expedia.com.hk'
      }
    },
    70201: {
      default: {
        title: 'Orbitz Travel: Vacations, Cheap Flights, Airline Tickets & Airfares',
        description: 'Plan your trip with Orbitz. Buy airline tickets, read reviews & reserve a hotel. Find deals on vacations, rental cars & cruises. Great prices guaranteed!'
      },
      es_MX: {
        title: 'Orbitz Travel: Boletos de aerolíneas, hoteles baratos, renta de autos, vacaciones y cruceros',
        description: 'Reserva boletos de aerolíneas, hoteles baratos, renta de autos, vacaciones y ofertas de viajes en Orbitz. Obtén nuestras mejores ofertas de tarifas de vuelos y hoteles, o un reembolso automático del 110% con la Garantía de precios de Orbitz.'
      }
    },
    80001: {
      default: {
        title: 'Travelocity | Cheap Hotels, Flights, Vacations &amp; Travel Deals',
        description: 'Book Cheap Travel with Discount Vacations, Hotels , Flights, Cars &amp; More. Save Big On Packages &amp; Get The Best Price Guarantee Only Available @ Travelocity'
      }
    }
  },
  'hotel-home': {
    robots: 'noodp, noydir',
    canonical: () => undefined,
    1: {
      default: {
        title: 'Hotels: Search Cheap Hotel Deals, Discounts, & Reservations | Expedia',
        description: 'Cheap Hotel Deals - Discover great hotel prices while planning the right reservations at the best resorts, even if you need last minute rates.'
      },
      zh_CN: {
        title: '酒店预订：全球特价酒店查询，旅馆住宿特价优惠 | Expedia.com',
        description: '特价酒店预订： Expedia 为您提供全球各地 51 万间的酒店预订优惠。立即到 Expedia.com 预订特价酒店、宾馆等各种住宿优惠，变更或取消预订不收手续费！。'
      },
      es_MX: {
        title: 'Hoteles Baratos: Promociones y ofertas de hoteles económicos | Expedia',
        description: 'Expedia ofrece hoteles baratos en tus destinos favoritos. Visítanos para leer reseñas, descubrir ofertas y reservar el hotel perfecto para tu siguiente viaje.'
      }
    },
    8: {
      default: {
        title: 'Hotel | Confronta Prezzi Hotel & Alberghi con Expedia',
        description: 'Cerca il tuo hotel su Expedia.it e scopri le nostre imperdibili offerte: approfitta delle tariffe più convenienti grazie alla Garanzia del miglior prezzo',
        path: '/Hotel'
      }
    },
    17: {
      default: {
        title: 'จองโรงแรม: ที่พักราคาพิเศษ และโรงแรมราคาประหยัด | Expedia.co.th',
        description: 'ค้นหาที่พักถูกที่สุด และ จองโรงแรมราคาประหยัด กว่า 321,000 โรงแรมทั่วโลก จองโรงแรมออนไลน์ กับ Expedia.co.th ประหยัดสูงสุด!'
      },
      en_GB: {
        title: 'Hotels: Budget Accommodation &amp; Cheap Hotel Room | Expedia.co.th',
        description: 'Book cheap hotels and find hotel deals for accommodations worldwide. Compare, book &amp; save with Expedia.co.th - no cancellation fees!'
      }
    },
    18: {
      default: {
        title: '酒店訂房優惠,酒店預訂 | 智遊網 Expedia.com.hk',
        description: '用智遊網以優惠價預訂酒店，我們有超過 321,000 間位於熱門旅遊城市的酒店可供選擇。各種優惠和格價資訊，幫您節省開支。'
      },
      en_HK: {
        title: 'Hotels &amp; Discount Hotel Booking | Expedia.com.hk',
        description: 'Booking cheap hotels with Expedia.com.hk is simple - with over 321,000 hotels around the world, compare hotel rates for top destinations and save today!'
      },
      zh_CN: {
        title: '酒店预订：提供国外及国内酒店在线订房服务 | 智游网Expedia.com.hk',
        description: '提供全球超过321,000间合作酒店的订房服务，酒店实时报价并提供各种特价优惠。立即通过智游网Expedia.com.hk体验方便快速的酒店预订服务，改期或取消订房不收取手续费！'
      }
    },
    70201: {
      default: {
        title: 'Hotels: Find, book & reserve Cheap Hotels | Orbitz',
        description: 'Find discount hotels, motels, and cheap hotel rooms with hotel reviews on Orbitz. Get our lowest hotel rates on our entire selection of hotels from Orbitz.com!'
      },
      es_MX: {
        title: 'Hoteles: Encuentra ofertas de hoteles baratos, descuentos y reservaciones | Orbitz',
        description: 'Ofertas de hoteles baratos: descubre los mejores precios de hoteles mientras planeas las reservaciones correctas en los mejores resorts, incluso si necesitas tarifas de último minuto.'
      }
    },
    80001: {
      default: {
        title: 'Cheap Hotels: Book Hotel Deals With Our Hotel Finder | Travelocity',
        description: 'Use Travelocity to book your next hotel to find the best prices guaranteed. Search hotel deals, read reviews and Wander Wisely!'
      }
    }
  },
  'hotel-infosite': {
    robots: 'noodp, noydir',
    canonical: () => undefined,
    30031: {
      default: {
        robots: 'noindex, nofollow',
        canonical: () => 'https://www.hotwire.com/'
      }
    }
  },
  'hotel-search': {
    robots: 'noindex',
    canonical: () => undefined
  }
};