var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import { Sha256 } from '@aws-crypto/sha256-browser';
import { getSdk } from '__generated__/api';
import crossFetch from 'cross-fetch';
import { GraphQLClient } from 'graphql-request';
import { isAPQDisabledOnWeb } from 'utils/bex-api-utils';
import { getSharedUiSdk } from './shared-ui-query-bex-api-source';
// We want to eventually load this from config with the current git hash and environment
const CLIENT_INFO = 'shopping-pwa,unknown,unknown';
const sha256 = data => __awaiter(void 0, void 0, void 0, function* () {
  const hash = new Sha256();
  hash.update(data);
  const result = yield hash.digest();
  const hashArray = Array.from(result);
  const digest = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return digest;
});
export const getBexApiSdk = (headers, url = '/graphql') => {
  const sdkHeaders = Object.assign(Object.assign({}, headers), {
    'Client-Info': CLIENT_INFO
  });
  const options = {
    headers: new Headers(sdkHeaders),
    credentials: 'same-origin',
    cache: 'no-cache',
    fetch: isAPQDisabledOnWeb() ? crossFetch : fetchWithAPQConfig
  };
  const client = new GraphQLClient(url, options);
  return Object.assign(Object.assign({}, getSdk(client)), getSharedUiSdk(client));
};
// APQ is an acronym for Automatic Persisted Queries
const fetchWithAPQConfig = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
  var _a;
  const {
    body,
    headers = new Headers()
  } = options;
  if (!('x-enable-apq' in headers && headers['x-enable-apq'] === 'true')) {
    return crossFetch(url, options);
  }
  const requestBody = JSON.parse(body);
  const getSha256Hash = yield sha256(requestBody.query);
  const requestBodyWithPersistedQuery = Object.assign(Object.assign({}, requestBody), {
    extensions: Object.assign(Object.assign({}, requestBody.extensions), {
      persistedQuery: {
        sha256Hash: getSha256Hash,
        version: 1
      }
    })
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
      query
    } = requestBodyWithPersistedQuery,
    requestBodyWithoutQuery = __rest(requestBodyWithPersistedQuery, ["query"]);
  const results = yield crossFetch(url, Object.assign(Object.assign({}, options), {
    body: JSON.stringify(requestBodyWithoutQuery)
  }));
  // workaround node-fetch clone hang -----------------------
  const text = yield results.text();
  const results2 = new Response(text, {
    status: results.status,
    statusText: results.statusText,
    headers: Object.fromEntries(results.headers.entries())
  });
  // ----------------------------------------------------------
  const res = JSON.parse(text);
  if ((_a = res === null || res === void 0 ? void 0 : res.errors) === null || _a === void 0 ? void 0 : _a.find(error => {
    var _a;
    return error.message === 'PersistedQueryNotFound' || ((_a = error.extensions) === null || _a === void 0 ? void 0 : _a.code) === 'PERSISTED_QUERY_NOT_FOUND';
  })) {
    return crossFetch(url, Object.assign(Object.assign({}, options), {
      body: JSON.stringify(requestBodyWithPersistedQuery)
    }));
  }
  return Promise.resolve(results2);
});