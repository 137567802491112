import { getErrorString } from 'bernie-logger';
import { PackageType } from 'models/multi-item/multi-item-types';
import { getMultiItemMutationImpactingKeys, SearchCriteriaUtils } from 'stores/models';
import { STORE_EVENTS } from './events/store-events';
const TIMEOUT_MULTI_ITEM_SEARCH = 60000;
const TIMEOUT_DEFAULT = 35000;
export function getSearchTimeout(packageType) {
  return packageType ? TIMEOUT_MULTI_ITEM_SEARCH : TIMEOUT_DEFAULT;
}
export function getDefaultTimeout() {
  return TIMEOUT_DEFAULT;
}
export var CollectionBusinessModel;
(function (CollectionBusinessModel) {
  CollectionBusinessModel["EC"] = "EC";
  CollectionBusinessModel["HC"] = "HC";
  CollectionBusinessModel["NA"] = "NA";
})(CollectionBusinessModel || (CollectionBusinessModel = {}));
export var MultiItemStoreStatus;
(function (MultiItemStoreStatus) {
  MultiItemStoreStatus[MultiItemStoreStatus["IDLE"] = 0] = "IDLE";
  MultiItemStoreStatus[MultiItemStoreStatus["ADDING_PROPERTY_SUCCESS"] = 1] = "ADDING_PROPERTY_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["ADDING_PROPERTY_FAILURE"] = 2] = "ADDING_PROPERTY_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["PREPARE_CHECKOUT_PROCESSING"] = 3] = "PREPARE_CHECKOUT_PROCESSING";
  MultiItemStoreStatus[MultiItemStoreStatus["PREPARE_CHECKOUT_SUCCESS"] = 4] = "PREPARE_CHECKOUT_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["PREPARE_CHECKOUT_FAILURE"] = 5] = "PREPARE_CHECKOUT_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["ADDING_PROPERTY"] = 6] = "ADDING_PROPERTY";
  MultiItemStoreStatus[MultiItemStoreStatus["FETCHING"] = 7] = "FETCHING";
  MultiItemStoreStatus[MultiItemStoreStatus["FETCH_FAILURE"] = 8] = "FETCH_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["FETCH_SUCCESS"] = 9] = "FETCH_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["REMOVE_PROPERTY_SUCCESS"] = 10] = "REMOVE_PROPERTY_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["REMOVE_PROPERTY_FAILURE"] = 11] = "REMOVE_PROPERTY_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["EMPTY_CART_SUCCESS"] = 12] = "EMPTY_CART_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["EMPTY_CART_FAILURE"] = 13] = "EMPTY_CART_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["ADDING_PROPERTY_SUCCESS_CHECKOUT_NOW"] = 14] = "ADDING_PROPERTY_SUCCESS_CHECKOUT_NOW";
  MultiItemStoreStatus[MultiItemStoreStatus["CREATE_SESSION_SUCCESS"] = 15] = "CREATE_SESSION_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["CREATE_SESSION_FAILURE"] = 16] = "CREATE_SESSION_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["INITIATE_SESSION_SUCCESS"] = 17] = "INITIATE_SESSION_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["SELECT_PRODUCTS_SUCCESS"] = 18] = "SELECT_PRODUCTS_SUCCESS";
  MultiItemStoreStatus[MultiItemStoreStatus["INITIATE_SESSION_FAILURE"] = 19] = "INITIATE_SESSION_FAILURE";
  MultiItemStoreStatus[MultiItemStoreStatus["SELECT_PRODUCTS_FAILURE"] = 20] = "SELECT_PRODUCTS_FAILURE";
})(MultiItemStoreStatus || (MultiItemStoreStatus = {}));
export const SHOPPING_CART_KEY = 'curr_cart';
export const MIX_MATCH_CART_KEY = 'mix_match_cart';
export const CART_EXPIRATION_IN_HOURS = 168;
export const INCOMPATIBLE_RATEPLAN = 'trying to add a rateplan with incompatible business model';
export function updateStatus(logger, setError, status, l10n, error, userError) {
  if (logger && typeof logger.logEvent === 'function') {
    switch (status) {
      case MultiItemStoreStatus.ADDING_PROPERTY_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_ADD_PROPERTY_ERROR, getErrorString(new Error(`Multi Item - Adding property failure, ${error}`)));
        setError({
          message: l10n.formatText('user.errorSummary.default.heading')
        });
        break;
      case MultiItemStoreStatus.FETCH_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_FETCH_ERROR, getErrorString(new Error(`Multi Item - Fetch failure, ${error}`)));
        setError({
          message: l10n.formatText('user.errorSummary.default.heading')
        });
        break;
      case MultiItemStoreStatus.PREPARE_CHECKOUT_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_CHECKOUT_PROPERTY_ERROR, getErrorString(new Error(`Multi Item - Prepare checkout failure, ${error}`)));
        setError(userError || {
          message: l10n.formatText('user.errorSummary.default.heading')
        });
        break;
      case MultiItemStoreStatus.REMOVE_PROPERTY_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_REMOVE_PROPERTY_ERROR, getErrorString(new Error(`Multi Item - Remove property failure, ${error}`)));
        setError({
          message: l10n.formatText('user.errorSummary.default.heading')
        });
        break;
      case MultiItemStoreStatus.CREATE_SESSION_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_CREATE_SESSION_ERROR, getErrorString(new Error(`Multi Item - Create session failure, ${error}`)));
        break;
      case MultiItemStoreStatus.INITIATE_SESSION_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_INITIATE_SESSION_ERROR, getErrorString(new Error(`Multi Item - Initiate session failure, ${error}`)));
        break;
      case MultiItemStoreStatus.SELECT_PRODUCTS_FAILURE:
        logger.logEvent(STORE_EVENTS.BEX_API_MULTI_ITEM_SELECT_PRODUCTS_ERROR, getErrorString(new Error(`Multi Item - Select products failure, ${error}`)));
        break;
      default:
        setError(null);
        break;
    }
  }
  return status;
}
export function getPackageType(query = {}) {
  return query.misId && query.packageType && PackageType[query.packageType.toString().toUpperCase()];
}
export function buildMultiItemContext(query = {}) {
  const packageType = getPackageType(query);
  return packageType ? {
    id: query.misId,
    packageType
  } : null;
}
export function isStoredUserCartExpired(cartInfo) {
  if (!cartInfo) {
    return true;
  }
  const currentTimestamp = new Date().valueOf();
  const cartTimestamp = cartInfo.timestamp;
  const cartDate = new Date(cartTimestamp);
  const cartExpiryTimestamp = cartDate.setHours(cartDate.getHours() + CART_EXPIRATION_IN_HOURS);
  return currentTimestamp > cartExpiryTimestamp;
}
export function loadCartFromStorage() {
  const localStorageCartInfo = localStorage.getItem(SHOPPING_CART_KEY);
  let cartInfo = localStorageCartInfo && JSON.parse(localStorageCartInfo) || null;
  if (!cartInfo) {
    const localStorageMixMatchCartInfo = localStorage.getItem(MIX_MATCH_CART_KEY);
    cartInfo = localStorageMixMatchCartInfo && JSON.parse(localStorageMixMatchCartInfo) || null;
  }
  if (isStoredUserCartExpired(cartInfo)) {
    cartInfo = clearCartFromStorage();
  }
  return cartInfo;
}
export function clearCartFromStorage() {
  localStorage.removeItem(SHOPPING_CART_KEY);
  localStorage.removeItem(MIX_MATCH_CART_KEY);
  return null;
}
export function clearMixAndMatchCartFromStorage() {
  localStorage.removeItem(MIX_MATCH_CART_KEY);
}
export function saveCartInStorage(cartInfo) {
  if (!cartInfo) return;
  if (cartInfo.identifier === 'mixAndMatch') {
    localStorage.setItem(MIX_MATCH_CART_KEY, JSON.stringify(cartInfo));
  } else {
    localStorage.setItem(SHOPPING_CART_KEY, JSON.stringify(cartInfo));
  }
}
export function shouldCallMultiItemSessionMutation(prevSearchCriteria, newSearchCriteria, packageType) {
  if (!prevSearchCriteria || !newSearchCriteria) {
    return false;
  }
  return SearchCriteriaUtils.hasSearchCriteriaChanged(prevSearchCriteria, newSearchCriteria, getMultiItemMutationImpactingKeys(packageType));
}