var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
import { action, computed, observable } from 'mobx';
import { merge } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { RoomModel } from './room-model';
import { SearchCriteriaUtils } from './search-criteria-utils';
const {
  withoutKeys
} = merge;
/**
 * We'll soon phase out the bex-api-lodging definition of search criteria,
 * so we need this separate definition.
 */
export var PropertySort;
(function (PropertySort) {
  PropertySort["AARP_MEMBER_DISCOUNT"] = "AARP_MEMBER_DISCOUNT";
  PropertySort["BEST_DEAL"] = "BEST_DEAL";
  PropertySort["DISTANCE"] = "DISTANCE";
  PropertySort["MEMBER_PRICING"] = "MEMBER_PRICING";
  PropertySort["PRICE_HIGH_TO_LOW"] = "PRICE_HIGH_TO_LOW";
  PropertySort["PRICE_LOW_TO_HIGH"] = "PRICE_LOW_TO_HIGH";
  PropertySort["PRICE_RELEVANT"] = "PRICE_RELEVANT";
  PropertySort["PROPERTY_CLASS"] = "PROPERTY_CLASS";
  PropertySort["PROPERTY_NAME"] = "PROPERTY_NAME";
  PropertySort["RECOMMENDED"] = "RECOMMENDED";
  PropertySort["REVIEW"] = "REVIEW";
  PropertySort["REVIEW_COUNT"] = "REVIEW_COUNT";
  PropertySort["VACATION_RENTAL"] = "VACATION_RENTAL";
})(PropertySort || (PropertySort = {}));
const hasRooms = state => 'rooms' in state;
export class SearchCriteriaModel extends Store {
  static getDefaults() {
    return {
      destination: '',
      endDate: null,
      group: '',
      latLong: '',
      regionId: '',
      selected: '',
      startDate: null,
      userIntent: '',
      neighborhood: '',
      sort: PropertySort.RECOMMENDED
    };
  }
  constructor(state = {}, l10n) {
    super({}, NOOP_LOGGER);
    this.amenities = [];
    this.destination = '';
    this.rooms = [];
    this.house_rules_group = [];
    this.l10n = l10n || new Localization('en_US');
    this.localDateFormat = state.localDateFormat || 'yyyy-MM-dd';
    Object.assign(this, SearchCriteriaModel.acceptedKeys(state));
    this.setRooms(state);
  }
  hydrate(data) {
    if (typeof data === 'object' && data !== null) {
      Object.assign(this, SearchCriteriaModel.acceptedKeys(data));
      this.setRooms(data);
    }
  }
  get isPartialStay() {
    return this.partialStay === 'true';
  }
  get adults() {
    if (this.rooms && this.rooms.length > 0) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return this.rooms.reduce((base, room) => base + (room.adults || 0), 0);
    }
    return 0;
  }
  get children() {
    if (this.rooms && this.rooms.length > 0) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return this.rooms.reduce((base, room) => base + (room.children && room.children.length || 0), 0);
    }
    return 0;
  }
  get guestCount() {
    return this.adults + this.children;
  }
  get isDateless() {
    return !this.startDate && !this.endDate;
  }
  clone() {
    return new SearchCriteriaModel(this.toJSON(), this.l10n);
  }
  setRooms(state = {}) {
    var _a;
    if (hasRooms(state) && ((_a = state.rooms) === null || _a === void 0 ? void 0 : _a.length)) {
      this.rooms = state.rooms.map(room => new RoomModel(Object.assign({}, room)));
      return;
    }
    this.rooms = [new RoomModel(Object.assign({}, RoomModel.DEFAULTS))];
  }
  static acceptedKeys(data = {}) {
    return withoutKeys(['adults', 'children', 'dateless', 'rooms']).merge({}, data);
  }
  toJSON(ignoreAdditionalKeys) {
    if (!Array.isArray(ignoreAdditionalKeys)) {
      ignoreAdditionalKeys = ignoreAdditionalKeys && ignoreAdditionalKeys.constructor === String ? [ignoreAdditionalKeys] : [];
    }
    const json = super.toJSON(ignoreAdditionalKeys.concat('rooms', 'l10n'));
    json.rooms = this.rooms && this.rooms.map(room => room.toJSON()) || undefined;
    return json;
  }
  setValues(query = {}) {
    if (!query.adults) {
      query.adults = '2';
    }
    if (!query.children || query.children === '0' || query.children === 0) {
      query.children = '';
    }
    query = SearchCriteriaUtils.ensureCorrectTypes(query);
    query.adultsString = query.adults;
    query.childrenString = query.children;
    // prevent duplicate query for dateless search on VRBO -- undefined query.startDate & query.endDate
    if (query.startDate) {
      query.startDate = SearchCriteriaUtils.sanitizeDateToISO(decodeURIComponent(query.startDate), this.l10n, this.localDateFormat);
    }
    if (query.endDate) {
      query.endDate = SearchCriteriaUtils.sanitizeDateToISO(decodeURIComponent(query.endDate), this.l10n, this.localDateFormat);
    }
    if (!query.flexibility) {
      query.flexibility = null;
    }
    if (!query.searchRange) {
      query.searchRange = null;
    }
    if (!query.flexibleSearchRequirement) {
      query.flexibleSearchRequirement = null;
    }
    Object.assign(this, SearchCriteriaModel.acceptedKeys(query));
  }
  setHighlightedPropertyId(id) {
    this.highlightedPropertyId = id;
  }
  get getHighlightedPropertyId() {
    return this.highlightedPropertyId === '' ? undefined : this.highlightedPropertyId;
  }
}
SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS = 4;
SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE = 500;
// Should not use `null` as a default; may deserialize as empty string.
SearchCriteriaModel.DEFAULTS = {
  accessibility: [],
  agencyBusinessModels: [],
  amenities: [],
  bedroomFilter: [],
  chain: '',
  commissionTiers: [],
  daysInFuture: '',
  deals: [],
  destination: '',
  group: '',
  guestRating: '',
  hotelName: '',
  latLong: '',
  lodging: [],
  mealPlan: [],
  misId: '',
  dropOffTime: '',
  pickUpTime: '',
  airlineCode: '',
  cabinClass: '',
  directFlights: '',
  driverAge: '',
  tripType: '',
  origin: '',
  infantsInSeats: '',
  paymentType: [],
  bedType: [],
  cleaningAndSafetyPractices: [],
  poi: '',
  price: [],
  propertyStyle: [],
  regionId: '',
  neighborhood: '',
  rewards: [],
  roomIndex: '',
  rooms: [],
  selected: '',
  sort: PropertySort.RECOMMENDED,
  star: [],
  stayLength: '',
  theme: '',
  travelerType: [],
  useRewards: '',
  userIntent: '',
  partialStay: 'false',
  mapBounds: [],
  stayType: [],
  expediaPropertyId: '',
  categorySearch: '',
  // eslint-disable-next-line camelcase
  house_rules_group: [],
  allowPreAppliedFilters: true,
  vacationRentalsOnly: false,
  highlightedPropertyId: '',
  // eslint-disable-next-line camelcase
  bed_type_group: [],
  // eslint-disable-next-line camelcase
  stay_options_group: [],
  // eslint-disable-next-line camelcase
  hotel_brand: [],
  // eslint-disable-next-line camelcase
  multi_neighborhood_group: [],
  // eslint-disable-next-line camelcase
  highly_rated_for_group: [],
  showFilterNotification: false,
  // eslint-disable-next-line camelcase
  ai_search_query: ''
};
__decorate([observable], SearchCriteriaModel.prototype, "l10n", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "adultsString", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "allowPreAppliedFilters", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "amenities", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "chain", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "childrenString", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "daysInFuture", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "origin", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "destination", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "_endDate", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "group", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "guestRating", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "hotelName", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "latLong", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "localDateFormat", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "lodging", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "paymentType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "bedType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "cleaningAndSafetyPractices", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "poi", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "price", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "regionId", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "neighborhood", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "roomIndex", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "rooms", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "selected", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "semdtl", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "sort", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "star", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "_startDate", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "stayLength", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "theme", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "travelerType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "useRewards", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "userIntent", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "bedroomFilter", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "deals", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "propertyStyle", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "multiProp", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "misId", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "referrerUrl", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "rewards", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "pickUpTime", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "dropOffTime", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "commissionTiers", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "agencyBusinessModels", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "mealPlan", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "cabinClass", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "tripType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "airlineCode", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "directFlights", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "infantsInSeats", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "driverAge", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "paandi", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "partialStay", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "partialStayStartDate", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "partialStayEndDate", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "packageRates", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "vacationRentalsOnly", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "mapBounds", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "stayType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "expediaPropertyId", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "typeaheadRegionType", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "categorySearch", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "house_rules_group", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "highlightedPropertyId", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "flexibility", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "searchRange", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "flexibleSearchRequirement", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "paginationSubSets", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "bed_type_group", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "stay_options_group", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "hotel_brand", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "multi_neighborhood_group", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "highly_rated_for_group", void 0);
__decorate([observable], SearchCriteriaModel.prototype, "showFilterNotification", void 0);
__decorate([observable
// eslint-disable-next-line camelcase
], SearchCriteriaModel.prototype, "ai_search_query", void 0);
__decorate([computed], SearchCriteriaModel.prototype, "isPartialStay", null);
__decorate([computed], SearchCriteriaModel.prototype, "adults", null);
__decorate([computed], SearchCriteriaModel.prototype, "children", null);
__decorate([computed], SearchCriteriaModel.prototype, "guestCount", null);
__decorate([computed], SearchCriteriaModel.prototype, "isDateless", null);
__decorate([action], SearchCriteriaModel.prototype, "setValues", null);
__decorate([action], SearchCriteriaModel.prototype, "setHighlightedPropertyId", null);
__decorate([computed], SearchCriteriaModel.prototype, "getHighlightedPropertyId", null);