export var PdpTemplate;
(function (PdpTemplate) {
  PdpTemplate["SingleUnitVr"] = "SINGLE_UNIT_VR";
  PdpTemplate["MultiUnitVr"] = "MULTI_UNIT_VR";
  PdpTemplate["ConventionalLodging"] = "CONVENTIONAL_LODGING";
})(PdpTemplate || (PdpTemplate = {}));
const PDP_BASE_TEMPLATES = Object.values(PdpTemplate);
export const isBasePdpTemplate = value => PDP_BASE_TEMPLATES.includes(value);
export const isPdpTemplate = value => PDP_BASE_TEMPLATES.some(baseTemplate => baseTemplate.concat('_LARGE') === value || baseTemplate.concat('_SMALL') === value);
export var SrpTemplate;
(function (SrpTemplate) {
  SrpTemplate["ListView"] = "LIST_VIEW";
  SrpTemplate["SplitView"] = "SPLIT_VIEW";
  SrpTemplate["listViewPillsExp"] = "LIST_VIEW_PILLS_EXP";
})(SrpTemplate || (SrpTemplate = {}));
const SRP_TEMPLATES = Object.values(SrpTemplate);
export const isSrpTemplate = value => SRP_TEMPLATES.includes(value);
export const ProductCardAdaptiveView = {
  LIST: 'LIST',
  GRID: 'GRID',
  DISABLED: 'DISABLED'
};
export const getProductCardAdaptiveViewType = type => ProductCardAdaptiveView[type] || ProductCardAdaptiveView.DISABLED;
/**
 * The Grid View (SRP_Grid_view_web|54458) MVP is a FE only feature.
 * Requirement is to change the value of getInitialListingFetchCount() when the user is on variant 2
 * because in variant 2 grid view is enabled by default and it will be enhanced by toggle persistence logic
 * Since, we can't use the gridViewContext for the SRP searchResults server-side call because of component lifecycle limitations.
 * So, we are using experiment bucketing to determine the grid view state and set the initial fetch count accordingly when the user is on variant 2.
 */
export const getProductCardAdaptiveViewTypeFromTestBucket = variant => {
  switch (variant) {
    case 1:
      return ProductCardAdaptiveView.LIST;
    case 2:
      return ProductCardAdaptiveView.GRID;
    default:
      return ProductCardAdaptiveView.DISABLED;
  }
};