import { SortAndFiltersViewType } from 'src/common/__generated__/api';
import { CustomerNotificationLocation } from '@shared-ui/customer-notifications';
export const listViewPillsExperimentTemplate = {
  id: 'list-view-filter-pills-large',
  version: '1',
  components: [{
    metadata: {
      id: 'ListViewLayout'
    },
    type: 'ListViewLayout',
    children: [{
      metadata: {
        id: 'LayoutGridItemMain'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutGridItem'
      },
      children: [{
        metadata: {
          id: 'toolbarRegion'
        },
        type: 'ToolbarRegion',
        children: [{
          metadata: {
            id: 'playback'
          },
          type: 'Playback'
        }]
      }, {
        metadata: {
          id: 'resultsWrapper'
        },
        type: 'ResultsWrapper',
        config: {
          columns: 1,
          space: 'six',
          bexQuickFiltersEnabled: true
        },
        children: [{
          metadata: {
            id: 'filterbarColumn'
          },
          type: 'EGDSComponent',
          config: {
            type: 'EGDSLayoutGridItem',
            colSpan: 1
          },
          children: [{
            metadata: {
              id: 'filterbar'
            },
            type: 'Filterbar',
            config: {
              view: SortAndFiltersViewType.FILTER_BAR,
              bexQuickFiltersEnabled: true,
              filterBarSpacing: {
                padding: {
                  small: {
                    block: 'two'
                  },
                  medium: {
                    block: 'three'
                  },
                  large: {
                    block: 'four'
                  }
                }
              },
              carouselEdgeItemsPadding: 'three'
            }
          }]
        }]
      }, {
        metadata: {
          id: 'mainRegion'
        },
        type: 'MainRegion',
        children: [{
          metadata: {
            id: 'mitHeader'
          },
          type: 'MitHeader'
        }, {
          metadata: {
            id: 'customerNotificationsAfterHeader'
          },
          type: 'CustomerNotificationTemplateComponent',
          config: {
            location: CustomerNotificationLocation.AFTER_HEADER
          }
        }, {
          metadata: {
            id: 'resultsWrapper'
          },
          type: 'ResultsWrapper',
          config: {
            columns: 4,
            space: 'six'
          },
          children: [{
            metadata: {
              id: 'resultsColumn'
            },
            type: 'EGDSComponent',
            config: {
              type: 'EGDSLayoutGridItem',
              colSpan: 3
            },
            children: [{
              metadata: {
                id: 'results-header'
              },
              type: 'ResultsHeader'
            }, {
              metadata: {
                id: 'search-results'
              },
              type: 'SearchResultsWrapper'
            }]
          }, {
            metadata: {
              id: 'sidebarColumn'
            },
            type: 'EGDSComponent',
            config: {
              type: 'EGDSLayoutGridItem',
              colSpan: 1
            },
            children: [{
              metadata: {
                id: 'sidebar'
              },
              config: {
                bexQuickFiltersEnabled: true
              },
              type: 'Sidebar'
            }]
          }]
        }, {
          metadata: {
            id: 'customerNotificationsFooter'
          },
          type: 'CustomerNotificationTemplateComponent',
          config: {
            location: CustomerNotificationLocation.FOOTER
          }
        }]
      }]
    }, {
      metadata: {
        id: 'LayoutGridItemSiderail'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutGridItem'
      },
      children: [{
        metadata: {
          id: 'sideRailRegion'
        },
        type: 'SideRailRegion',
        children: [{
          metadata: {
            id: 'mesoSideRail'
          },
          type: 'MesoSideRail'
        }]
      }]
    }]
  }]
};