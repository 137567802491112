var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { UisPublisher } from 'utils/analytics/uis-publisher';
const publishUisMicroMessages = (traceIdOfTheView, ...messages) => __awaiter(void 0, void 0, void 0, function* () {
  const publishPayload = {
    applicationName: 'experience-api-lodging-search',
    traceIdOfTheView,
    microMessages: messages
  };
  return new UisPublisher().publish(publishPayload).then(data => ({
    traceId: data === null || data === void 0 ? void 0 : data.traceId
  }));
});
export const publishPageViewEvents = (payloads, analyticsStore, logger, experiment) => {
  var _a, _b;
  const trackPayloads = [...payloads];
  if (((_b = (_a = experiment === null || experiment === void 0 ? void 0 : experiment.exposures) === null || _a === void 0 ? void 0 : _a.srp_move_searchresultshotel_to_clientside) === null || _b === void 0 ? void 0 : _b.bucket) === 1) {
    const traceOfTheView = (trackPayloads === null || trackPayloads === void 0 ? void 0 : trackPayloads[0]) || {};
    const traceIdOfTheView = traceOfTheView && 'traceId' in traceOfTheView && typeof traceOfTheView.traceId === 'string' ? traceOfTheView.traceId : undefined;
    publishUisMicroMessages(traceIdOfTheView, {
      schemaName: 'events',
      messageContent: {
        name: 'SearchResultsHotel'
      }
    }).then(trackEventPayload => {
      if (trackEventPayload) {
        trackPayloads.push(trackEventPayload);
      }
    }, error => {
      logger.logEvent(new SystemEvent(SystemEventLevel.WARN, `Error publishing SearchResultsHotel event to UIS for traceIdOfTheView: ${traceIdOfTheView} error:${error.message}`));
    }).finally(() => {
      analyticsStore.publishClientSidePayload(trackPayloads);
    });
  } else {
    analyticsStore.publishClientSidePayload(trackPayloads);
  }
};