const RFRR_PAGE_IDENTIFIER = 'HOT.HIS';
export const multiUnitVrTemplate = {
  id: 'multi-unit-vr-template',
  version: '1',
  components: [{
    metadata: {
      id: 'LayoutFlex'
    },
    type: 'EGDSComponent',
    config: {
      type: 'EGDSLayoutFlex',
      direction: 'row-reverse' // render the fixed-width side-rail region first to reduce cls //
    },
    children: [{
      metadata: {
        id: 'LayoutFlexItemSiderail'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutFlexItem'
      },
      children: [{
        metadata: {
          id: 'sideRailRegion'
        },
        type: 'SideRailRegion',
        children: [{
          metadata: {
            id: 'mesoSideRail'
          },
          type: 'MesoSideRail'
        }]
      }]
    }, {
      type: 'EGDSComponent',
      metadata: {
        id: 'EGDSLayoutFlexItemMainArea'
      },
      config: {
        type: 'EGDSLayoutFlexItem',
        grow: 1
      },
      children: [{
        metadata: {
          id: 'simpleContainer1'
        },
        type: 'LayoutGrid',
        config: {
          columns: 3
        },
        children: [{
          metadata: {
            id: 'top-row'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'back-to-search-link'
            },
            type: 'PropertySummaryBackLinkWrapper'
          }]
        }, {
          metadata: {
            id: 'property-top'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'property-gallery-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyGallery`,
              linkName: 'Property Gallery'
            },
            children: [{
              metadata: {
                id: 'property-gallery'
              },
              type: 'PropertyGallery'
            }]
          }, {
            metadata: {
              id: 'property-navigation-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyNavigation`,
              linkName: 'Property Navigation'
            },
            children: [{
              metadata: {
                id: 'property-navigation'
              },
              type: 'PropertyNavigation',
              config: {
                showReserveBtn: false,
                showNightlyPrice: false,
                showReserveBtnOnLoad: true
              }
            }]
          }]
        }, {
          metadata: {
            id: 'column-1'
          },
          type: 'LayoutGridItem',
          config: {
            colSpan: 3
          },
          children: [{
            metadata: {
              id: 'property-summary-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertySummary`,
              linkName: 'Property Summary'
            },
            children: [{
              metadata: {
                id: 'property-summary'
              },
              type: 'MultiOfferSummary'
            }]
          }, {
            metadata: {
              id: 'property-highlight-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyHighlight`,
              linkName: 'Property Highlight'
            },
            children: [{
              metadata: {
                id: 'property-highlight'
              },
              type: 'PropertyHighlight'
            }]
          }, {
            metadata: {
              id: 'property-level-offers-message-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyLevelOffersMessage`,
              linkName: 'Property Level Offers Message'
            },
            children: [{
              metadata: {
                id: 'property-level-offers-message'
              },
              type: 'PropertyLevelOffersMessage'
            }]
          }, {
            metadata: {
              id: 'pre-notifications-banner-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}PreNotificationBanner`,
              linkName: 'Pre Notification Banner'
            },
            children: [{
              metadata: {
                id: 'pre-notifications-banner'
              },
              type: 'SharedUiTravelAdvisory'
            }]
          }, {
            metadata: {
              id: 'property-trip-summary-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.TripSummary`,
              linkName: 'Trip Summary'
            },
            children: [{
              metadata: {
                id: 'property-trip-summary'
              },
              type: 'TripSummary'
            }]
          }, {
            metadata: {
              id: 'property-level-offers-card-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyLevelOffersCard`,
              linkName: 'Property Level Offers Card'
            },
            children: [{
              metadata: {
                id: 'property-level-offers-card'
              },
              type: 'SharedUiPropertyLevelOffersCardWrapper'
            }]
          }, {
            metadata: {
              id: 'property-offers-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyOffers`,
              linkName: 'Property Offers'
            },
            children: [{
              metadata: {
                id: 'property-offers'
              },
              type: 'RoomsAndRates'
            }]
          }, {
            metadata: {
              id: 'answering-traveller-questions-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.AnsweringTravellerQuestions`,
              linkName: 'Answering Traveller Questions'
            },
            children: [{
              metadata: {
                id: 'answering-traveller-questions'
              },
              type: 'SharedUiAnsweringTravellerQuestionsWrapper'
            }]
          }, {
            metadata: {
              id: 'property-amenities-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyAmenities`,
              linkName: 'Property Amenities'
            },
            children: [{
              metadata: {
                id: 'property-amenities'
              },
              type: 'SharedUiAmenityDescriptionWrapper'
            }]
          }, {
            metadata: {
              id: 'property-about-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyAbout`,
              linkName: 'Property About'
            },
            children: [{
              metadata: {
                id: 'about-property'
              },
              type: 'SharedUiAboutThisPropertyWrapper'
            }]
          }, {
            metadata: {
              id: 'property-bottom-carousel-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyBottomCarouselWrapper`,
              linkName: 'Property bottom carousel wrapper'
            },
            children: [{
              metadata: {
                id: 'property-bottom-carousel-wrapper'
              },
              type: 'PropertyBottomCarouselWrapper'
            }]
          }, {
            metadata: {
              id: 'property-special-features-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertySpecialFeatures`,
              linkName: 'Property special features'
            },
            children: [{
              metadata: {
                id: 'property-special-features'
              },
              type: 'SharedUiSpecialFeaturesWrapper'
            }]
          }, {
            metadata: {
              id: 'property-policies-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyPolicies`,
              linkName: 'Property policies'
            },
            children: [{
              metadata: {
                id: 'property-policies'
              },
              type: 'SharedUiPoliciesWrapper'
            }]
          }, {
            metadata: {
              id: 'property-important-info-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyImportantInfo`,
              linkName: 'Property Important Info'
            },
            children: [{
              metadata: {
                id: 'property-imp-info'
              },
              type: 'SharedUiImportantInfoWrapper'
            }]
          }, {
            metadata: {
              id: 'frequently-asked-questions-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyFaq`,
              linkName: 'frequently asked questions'
            },
            children: [{
              metadata: {
                id: 'frequently-asked-questions'
              },
              type: 'SharedUiPropertyFaqWrapper'
            }]
          }, {
            metadata: {
              id: 'property-reviews-tracker'
            },
            type: 'IntersectionTrackerWrapper',
            config: {
              rfrrId: `${RFRR_PAGE_IDENTIFIER}.PropertyReviews`,
              linkName: 'Property Reviews'
            },
            children: [{
              metadata: {
                id: 'property-reviews'
              },
              type: 'SharedUiPropertyReviewsWrapper'
            }]
          }, {
            metadata: {
              id: 'property-about-this-property-direct-feedback'
            },
            type: 'PropertyAboutThisPropertyDirectFeedbackWrapper'
          }]
        }]
      }]
    }]
  }]
};