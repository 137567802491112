import { Provider } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { NOOP_LOGGER } from 'bernie-logger';
import { AdvertisingStore } from 'bernie-plugin-ads-loader-script';
import { ContextStore } from 'bernie-plugin-mobx';
import { BexApiSource } from 'sources/bex-api-source';
import { getBexApiSdk } from 'sources/get-bex-api-sdk';
import { AnalyticsSearchStore } from './analytics-search-store';
import { CurrentHotelStore } from './current-hotel-store';
import { DataDogStore } from './datadog-store';
import { MapStore } from './map-store';
import { SearchCriteriaModel } from './models';
import { MultiItemStore } from './multi-item-store';
import { PageLayoutStore } from './page-layout/page-layout-store';
import { SearchResultsStore } from './search-results-store';
import { UIPageStore } from './ui-page-store';
// @ts-expect-error mobx provider doesn't have type name
Provider.displayName = 'MobxProvider';
export const buildClientSideBexApiSource = () => {
  const api = getBexApiSdk({});
  return new BexApiSource(api, ClientLogger.getLoggerWithIdentifier('pwa.source.bexapi'));
};
export const stores = {
  advertising: () => new AdvertisingStore({}, ClientLogger.getLoggerWithIdentifier('pwa.store.advertising')),
  // Ignoring next line cause ContextStore doesn't provide static DEFAULT values
  // @ts-ignore
  context: () => new ContextStore(null, NOOP_LOGGER),
  currentHotel: ({
    l10n
  }) => new CurrentHotelStore(buildClientSideBexApiSource(), {}, l10n, ClientLogger.getLoggerWithIdentifier('pwa.store.currenthotel')),
  searchCriteria: ({
    l10n
  }) => new SearchCriteriaModel({}, l10n),
  map: () => new MapStore({}, ClientLogger.getLoggerWithIdentifier('pwa.store.map')),
  searchResults: ({
    l10n
  }) => new SearchResultsStore(buildClientSideBexApiSource(), {}, ClientLogger.getLoggerWithIdentifier('pwa.store.searchresults'), l10n),
  multiItem: ({
    l10n
  }) => new MultiItemStore(buildClientSideBexApiSource(), {}, ClientLogger.getLoggerWithIdentifier('pwa.store.multiitem'), l10n),
  pageLayout: () => new PageLayoutStore(buildClientSideBexApiSource(), {}, document === null || document === void 0 ? void 0 : document.cookie, ClientLogger.getLoggerWithIdentifier('pwa.store.pagelayout')),
  datadog: () => new DataDogStore({}),
  uiPage: () => new UIPageStore({}),
  analyticsSearch: () => new AnalyticsSearchStore({})
};