export const SearchPageName = {
  SA: 'page.Hotel-Search',
  HC: 'page.Package.HC.Hotel-Search',
  FH: 'page.Package.FH.Hotel-Search',
  FHC: 'page.Package.FHC.Hotel-Search'
};
export var InfositePageName;
(function (InfositePageName) {
  InfositePageName["SA"] = "page.Hotels.Infosite.Information";
  InfositePageName["HC"] = "page.HC.Package.Infosite.Information";
  InfositePageName["FH"] = "page.FH.Package.Infosite.Information";
  InfositePageName["FHC"] = "page.FHC.Package.Infosite.Information";
  InfositePageName["FC"] = "";
  InfositePageName["HA"] = "HA";
})(InfositePageName || (InfositePageName = {}));
export const SEARCH_PAGE_EG_CLICKSTREAM_NAME = 'Search Results';
export const SearchClickstreamPageNameDetailed = {
  SA: 'Search Results Lodging Dated',
  HC: 'Search Results HC Package Hotel',
  FH: 'Search Results FH Package Hotel',
  FHC: 'Search Results FHC Package Hotel'
};
export var SearchMapClickstreamPageNameDetailed;
(function (SearchMapClickstreamPageNameDetailed) {
  SearchMapClickstreamPageNameDetailed["SA"] = "Search Results Map Lodging Dated";
  SearchMapClickstreamPageNameDetailed["HC"] = "Search Results Map HC Package Hotel";
  SearchMapClickstreamPageNameDetailed["FH"] = "Search Results Map FH Package Hotel";
  SearchMapClickstreamPageNameDetailed["FHC"] = "Search Results Map FHC Package Hotel";
})(SearchMapClickstreamPageNameDetailed || (SearchMapClickstreamPageNameDetailed = {}));
export const INFOSITE_PAGE_EG_CLICKSTREAM_NAME = 'Product Details';
export var DetailsClickstreamPageNameDetailed;
(function (DetailsClickstreamPageNameDetailed) {
  DetailsClickstreamPageNameDetailed["SA"] = "Product Details Lodging Dated";
  DetailsClickstreamPageNameDetailed["HC"] = "Product Details HC Package Hotel";
  DetailsClickstreamPageNameDetailed["FH"] = "Product Details FH Package Hotel";
  DetailsClickstreamPageNameDetailed["FHC"] = "Product Details FHC Package Hotel";
  DetailsClickstreamPageNameDetailed["FC"] = "";
  DetailsClickstreamPageNameDetailed["HA"] = "HA";
})(DetailsClickstreamPageNameDetailed || (DetailsClickstreamPageNameDetailed = {}));
// These names do not conform to what's found here: https://confluence.expedia.biz/display/DAPS/Clickstream+-+cross+brand+page+names
export var InfositePageEgClickstreamDetailedName;
(function (InfositePageEgClickstreamDetailedName) {
  InfositePageEgClickstreamDetailedName["SA"] = "Product Details Lodging";
  InfositePageEgClickstreamDetailedName["HC"] = "Product Details Package Lodging (Lodging+Car)";
  InfositePageEgClickstreamDetailedName["FH"] = "Product Details Package Lodging (Flight+Lodging)";
  InfositePageEgClickstreamDetailedName["FHC"] = "Product Details Package Lodging (Flight+Lodging+Car)";
})(InfositePageEgClickstreamDetailedName || (InfositePageEgClickstreamDetailedName = {}));
export const DEFAULT_HOTEL_SEARCH_PAGE_NAME = SearchPageName.SA;
export const DEFAULT_HOTEL_SEARCH_PAGE_ID = `${DEFAULT_HOTEL_SEARCH_PAGE_NAME},H,20`;
export const DEFAULT_HOTEL_INFOSITE_PAGE_NAME = InfositePageName.SA;
export const DEFAULT_HOTEL_INFOSITE_PAGE_ID = `${DEFAULT_HOTEL_INFOSITE_PAGE_NAME},H,30`;
export const DEFAULT_GT_SEARCH_PAGE_NAME = 'page.LX-GT-Search';
export const DEFAULT_GT_SEARCH_PAGE_ID = `${DEFAULT_GT_SEARCH_PAGE_NAME},GT,20`;
export const DEFAULT_CRUISE_SEARCH_PAGE_NAME = 'page.Cruise-Search';
export const DEFAULT_CRUISE_SEARCH_PAGE_ID = `${DEFAULT_CRUISE_SEARCH_PAGE_NAME},CR,20`;
export const DEFAULT_CRUISE_INFOSITE_PAGE_NAME = 'page.Cruise-Infosite';
export const DEFAULT_CRUISE_INFOSITE_PAGE_ID = `${DEFAULT_CRUISE_INFOSITE_PAGE_NAME},CR,30`;
export const DEFAULT_CAR_SEARCH_PAGE_NAME = 'page.Car-Search';
export const DEFAULT_CAR_SEARCH_PAGE_ID = `${DEFAULT_CAR_SEARCH_PAGE_NAME},C,20`;
// Verify if we will be supporting this in Shopping stack, and if so implement
export const DEFAULT_CAR_SEARCH_DROPOFF_PAGE_ID = `${DEFAULT_CAR_SEARCH_PAGE_NAME}.dropOff,C,20`;