export const bexApiDateTimeToTimeLocKeyMap = [[{
  hour: 0,
  minute: 0
}, '0000'], [{
  hour: 0,
  minute: 30
}, '0030'], [{
  hour: 1,
  minute: 0
}, '0100'], [{
  hour: 1,
  minute: 30
}, '0130'], [{
  hour: 2,
  minute: 0
}, '0200'], [{
  hour: 2,
  minute: 30
}, '0230'], [{
  hour: 3,
  minute: 0
}, '0300'], [{
  hour: 3,
  minute: 30
}, '0330'], [{
  hour: 4,
  minute: 0
}, '0400'], [{
  hour: 4,
  minute: 30
}, '0430'], [{
  hour: 5,
  minute: 0
}, '0500'], [{
  hour: 5,
  minute: 30
}, '0530'], [{
  hour: 6,
  minute: 0
}, '0600'], [{
  hour: 6,
  minute: 30
}, '0630'], [{
  hour: 7,
  minute: 0
}, '0700'], [{
  hour: 7,
  minute: 30
}, '0730'], [{
  hour: 8,
  minute: 0
}, '0800'], [{
  hour: 8,
  minute: 30
}, '0830'], [{
  hour: 9,
  minute: 0
}, '0900'], [{
  hour: 9,
  minute: 30
}, '0930'], [{
  hour: 10,
  minute: 0
}, '1000'], [{
  hour: 10,
  minute: 30
}, '1030'], [{
  hour: 11,
  minute: 0
}, '1100'], [{
  hour: 11,
  minute: 30
}, '1130'], [{
  hour: 12,
  minute: 0
}, '1200'], [{
  hour: 12,
  minute: 30
}, '1230'], [{
  hour: 13,
  minute: 0
}, '1300'], [{
  hour: 13,
  minute: 30
}, '1330'], [{
  hour: 14,
  minute: 0
}, '1400'], [{
  hour: 14,
  minute: 30
}, '1430'], [{
  hour: 15,
  minute: 0
}, '1500'], [{
  hour: 15,
  minute: 30
}, '1530'], [{
  hour: 16,
  minute: 0
}, '1600'], [{
  hour: 16,
  minute: 30
}, '1630'], [{
  hour: 17,
  minute: 0
}, '1700'], [{
  hour: 17,
  minute: 30
}, '1730'], [{
  hour: 18,
  minute: 0
}, '1800'], [{
  hour: 18,
  minute: 30
}, '1830'], [{
  hour: 19,
  minute: 0
}, '1900'], [{
  hour: 19,
  minute: 30
}, '1930'], [{
  hour: 20,
  minute: 0
}, '2000'], [{
  hour: 20,
  minute: 30
}, '2030'], [{
  hour: 21,
  minute: 0
}, '2100'], [{
  hour: 21,
  minute: 30
}, '2130'], [{
  hour: 22,
  minute: 0
}, '2200'], [{
  hour: 22,
  minute: 30
}, '2230'], [{
  hour: 23,
  minute: 0
}, '2300'], [{
  hour: 23,
  minute: 30
}, '2330']];
const emptyDate = {
  day: undefined,
  month: undefined,
  year: undefined
};
export const getLocKeyFromBexApiDateTime = bexApiDateTime => {
  var _a;
  const entry = bexApiDateTimeToTimeLocKeyMap.filter(([{
    hour,
    minute
  }]) => hour === bexApiDateTime.hour && minute === bexApiDateTime.minute);
  return entry && entry.length ? (_a = entry[0]) === null || _a === void 0 ? void 0 : _a[1] : null;
};
export const getBexApiDateTimeFromLocKey = (locKey, date = emptyDate) => {
  const entry = bexApiDateTimeToTimeLocKeyMap.filter(([, key]) => key === locKey);
  const config = entry && entry.length ? entry[0] : null;
  if (config) {
    return Object.assign(config[0], {
      second: 0
    }, Object.assign({}, date));
  }
  return null;
};