export var PackageType;
(function (PackageType) {
  PackageType["FC"] = "FC";
  PackageType["FH"] = "FH";
  PackageType["FHC"] = "FHC";
  PackageType["HC"] = "HC";
  PackageType["HA"] = "HA";
})(PackageType || (PackageType = {}));
export var MitStep;
(function (MitStep) {
  MitStep["HOTEL"] = "HOTEL";
  MitStep["ROOM"] = "ROOM";
  MitStep["DEPARTUREFLIGHT"] = "DEPARTUREFLIGHT";
  MitStep["RETURNFLIGHT"] = "RETURNFLIGHT";
  MitStep["CAR"] = "CAR";
})(MitStep || (MitStep = {}));
export var FCStep;
(function (FCStep) {
  FCStep[FCStep["DEPARTUREFLIGHT"] = 0] = "DEPARTUREFLIGHT";
  FCStep[FCStep["RETURNFLIGHT"] = 1] = "RETURNFLIGHT";
  FCStep[FCStep["CAR"] = 2] = "CAR";
})(FCStep || (FCStep = {}));
export var FHStep;
(function (FHStep) {
  FHStep[FHStep["HOTEL"] = 0] = "HOTEL";
  FHStep[FHStep["ROOM"] = 1] = "ROOM";
  FHStep[FHStep["DEPARTUREFLIGHT"] = 2] = "DEPARTUREFLIGHT";
  FHStep[FHStep["RETURNFLIGHT"] = 3] = "RETURNFLIGHT";
})(FHStep || (FHStep = {}));
export var FHCStep;
(function (FHCStep) {
  FHCStep[FHCStep["HOTEL"] = 0] = "HOTEL";
  FHCStep[FHCStep["ROOM"] = 1] = "ROOM";
  FHCStep[FHCStep["DEPARTUREFLIGHT"] = 2] = "DEPARTUREFLIGHT";
  FHCStep[FHCStep["RETURNFLIGHT"] = 3] = "RETURNFLIGHT";
  FHCStep[FHCStep["CAR"] = 4] = "CAR";
})(FHCStep || (FHCStep = {}));
export var HCStep;
(function (HCStep) {
  HCStep[HCStep["HOTEL"] = 0] = "HOTEL";
  HCStep[HCStep["ROOM"] = 1] = "ROOM";
  HCStep[HCStep["CAR"] = 2] = "CAR";
})(HCStep || (HCStep = {}));