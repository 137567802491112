import { CustomerNotificationLocation } from '@shared-ui/customer-notifications';
export const listViewTemplate = {
  id: 'list-view-large',
  version: '1',
  components: [{
    metadata: {
      id: 'ListViewLayout'
    },
    type: 'ListViewLayout',
    children: [{
      metadata: {
        id: 'LayoutGridItemMain'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutGridItem'
      },
      children: [{
        metadata: {
          id: 'toolbarRegion'
        },
        type: 'ToolbarRegion',
        children: [{
          metadata: {
            id: 'playback'
          },
          type: 'Playback'
        }]
      }, {
        metadata: {
          id: 'mainRegion'
        },
        type: 'MainRegion',
        children: [{
          metadata: {
            id: 'mitHeader'
          },
          type: 'MitHeader'
        }, {
          metadata: {
            id: 'customerNotificationsAfterHeader'
          },
          type: 'CustomerNotificationTemplateComponent',
          config: {
            location: CustomerNotificationLocation.AFTER_HEADER
          }
        }, {
          metadata: {
            id: 'resultsWrapper'
          },
          type: 'ResultsWrapper',
          config: {
            columns: 4,
            space: 'six'
          },
          children: [{
            metadata: {
              id: 'sidebarColumn'
            },
            type: 'EGDSComponent',
            config: {
              type: 'EGDSLayoutGridItem',
              colSpan: 1
            },
            children: [{
              metadata: {
                id: 'sidebar'
              },
              config: {
                withFilters: true
              },
              type: 'Sidebar'
            }]
          }, {
            metadata: {
              id: 'resultsColumn'
            },
            type: 'EGDSComponent',
            config: {
              type: 'EGDSLayoutGridItem',
              colSpan: 3
            },
            children: [{
              metadata: {
                id: 'results-header'
              },
              type: 'ResultsHeader',
              config: {
                withMapButton: true,
                withFilterButton: true
              }
            }, {
              metadata: {
                id: 'search-results'
              },
              type: 'SearchResultsWrapper'
            }]
          }]
        }]
      }]
    }, {
      metadata: {
        id: 'LayoutGridItemSiderail'
      },
      type: 'EGDSComponent',
      config: {
        type: 'EGDSLayoutGridItem'
      },
      children: [{
        metadata: {
          id: 'sideRailRegion'
        },
        type: 'SideRailRegion',
        children: [{
          metadata: {
            id: 'mesoSideRail'
          },
          type: 'MesoSideRail'
        }]
      }]
    }]
  }]
};