import { getDefaultRequestFields } from 'stores/adapters';
import { mapOmnitureData } from 'stores/adapters/bex-api/search/search-results-adapter';
export const adaptPropertySearch = (bexApiResults, multiItem) => {
  var _a, _b, _c, _d, _e, _f;
  if (!((_a = bexApiResults === null || bexApiResults === void 0 ? void 0 : bexApiResults.data) === null || _a === void 0 ? void 0 : _a.propertySearch)) {
    return {};
  }
  const {
    data: {
      propertySearch
    },
    extensions
  } = bexApiResults;
  const {
    summary
  } = propertySearch;
  let multiItemId = '';
  let pwaPropertySearchResponse = {};
  if (multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) {
    multiItemId = ((_c = (_b = propertySearch === null || propertySearch === void 0 ? void 0 : propertySearch.shoppingContext) === null || _b === void 0 ? void 0 : _b.multiItem) === null || _c === void 0 ? void 0 : _c.id) || '';
    pwaPropertySearchResponse = {
      dialogErrorResponse: propertySearch.dialogErrorResponse || undefined,
      multiItemSearchContext: propertySearch.multiItemSearchContext || undefined
    };
  }
  const loyaltyInfo = summary && summary.loyaltyInfo;
  return Object.assign({
    defaultRequestFields: propertySearch.criteria && getDefaultRequestFields(propertySearch.criteria, multiItemId, bexApiResults, multiItem) || undefined,
    propertySearchListings: propertySearch.propertySearchListings,
    propertyListingAdaptexAnalyticsSuccessEvents: (_d = propertySearch.propertyListingAdaptexAnalyticsSuccessEvents) !== null && _d !== void 0 ? _d : [],
    loyaltyMessagingModel: loyaltyInfo,
    omnitureData: [mapOmnitureData(extensions.analytics)],
    resultMessages: summary.resultMessages,
    messagingModule: summary.messagingModule || undefined,
    resultsHeading: summary.resultsHeading || undefined,
    totalPropertyCount: summary.matchedPropertiesSize,
    errorResponse: propertySearch.errorResponse || undefined,
    pagination: propertySearch.pagination || undefined,
    mapTrigger: propertySearch.mapTrigger || undefined,
    dynamicMap: propertySearch.dynamicMap || undefined,
    propertyListingToast: propertySearch.propertyListingsToast || undefined,
    header: propertySearch.summary.header || undefined,
    packageProductCard: (_e = propertySearch.packageProductCard) !== null && _e !== void 0 ? _e : undefined,
    shoppingSearchListingHeader: (_f = propertySearch.shoppingSearchListingHeader) !== null && _f !== void 0 ? _f : []
  }, pwaPropertySearchResponse);
};