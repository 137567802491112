var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
export class ChildModel extends Store {
  constructor(state = {}) {
    super({}, NOOP_LOGGER);
    this.age = 0;
    this.setState(state);
  }
  setState(state) {
    var _a;
    this.age = (_a = state.age) !== null && _a !== void 0 ? _a : ChildModel.DEFAULTS.age;
  }
  clone() {
    return new ChildModel({
      age: this.age
    });
  }
  hydrate(state) {
    this.setState(state);
  }
}
ChildModel.DEFAULTS = {
  age: 0
};
__decorate([observable], ChildModel.prototype, "age", void 0);