import { SrpTemplate } from '../stores/page-layout/page-layout-store-utils';
import { isMobile } from './is-mobile';
export const handleFilterPillRemoval = (pill, history) => {
  var _a;
  if (pill.__typename === 'BasicShoppingAppliedFilter' && ((_a = pill.linkUrl) === null || _a === void 0 ? void 0 : _a.resource.__typename) === 'HttpURI') {
    history.push(pill.linkUrl.resource.relativePath);
    window.scrollTo(0, 0);
  }
};
export const handleRemoveAllFilters = (action, analytics, history) => {
  var _a;
  if ((action === null || action === void 0 ? void 0 : action.button.__typename) === 'UISecondaryButton' && ((_a = action.action) === null || _a === void 0 ? void 0 : _a.resource.__typename) === 'HttpURI') {
    if (action === null || action === void 0 ? void 0 : action.button.analytics) {
      const {
        linkName,
        referrerId
      } = action.button.analytics;
      if (analytics === null || analytics === void 0 ? void 0 : analytics.clickTracker) {
        analytics.clickTracker(referrerId, linkName);
      }
    }
    if (action.action.resource.relativePath) {
      history.push(action.action.resource.relativePath);
      window.scrollTo(0, 0);
    }
  }
};
export const includeDynamicMapInSearchQuery = (contextStore, pageLayoutStore, experimentStore, isMapPwaOverlay) => {
  var _a, _b;
  if (isMobile(contextStore) && ((_b = (_a = experimentStore === null || experimentStore === void 0 ? void 0 : experimentStore.exposures) === null || _a === void 0 ? void 0 : _a.Exclude_map_from_search_query_vrbo_mobile) === null || _b === void 0 ? void 0 : _b.bucket) === 1) {
    return false;
  }
  // includeDynamicMap if full map pwa overlay is visible or on split view
  const isSplitView = (pageLayoutStore === null || pageLayoutStore === void 0 ? void 0 : pageLayoutStore.templateName) === SrpTemplate.SplitView;
  return Boolean(isSplitView || isMapPwaOverlay);
};