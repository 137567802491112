/* eslint-disable max-lines */
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { action, computed, observable } from 'mobx';
import { getBexApiContext } from 'bernie-context';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { CartTtl, CheckoutOptionType, FlightsAdvancedFilterType, FlightsCabinClass, FlightsTripType, TravelerDetailsType } from '__generated__/api';
import { PackageType } from 'models/multi-item/multi-item-types';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { getLocKeyFromBexApiDateTime } from 'components/multi-item/bex-api-date-time-to-time-loc-key';
import { cartItemToInCartProperty } from 'components/multi-item/cart-adapter';
import { DateUtils } from 'utils/date-utils';
import { buildMultiItemContext, clearMixAndMatchCartFromStorage, CollectionBusinessModel, INCOMPATIBLE_RATEPLAN, loadCartFromStorage, MultiItemStoreStatus, saveCartInStorage, updateStatus } from './multi-item-store-util';
export const packageTypeMap = {
  HOTEL_CAR: PackageType.HC,
  FLIGHT_HOTEL: PackageType.FH,
  FLIGHT_HOTEL_CAR: PackageType.FHC,
  FLIGHT_CAR: PackageType.FC,
  HOTEL_ACTIVITY: PackageType.HA
};
const emptyDate = {
  year: 0,
  month: 0,
  day: 0
};
export class MultiItemStore extends Store {
  constructor(bexApiSource, state = {}, logger = NOOP_LOGGER, l10n) {
    super(state, logger);
    this.itemCount = 0;
    this.items = [];
    this.cartInfo = null;
    this.multiItemContext = null;
    this.pageLoading = false;
    this.isMixMatchEnabled = false;
    this.bexApiSource = bexApiSource;
    this.l10n = l10n;
    if (!this.isServerSide() && !this.isPackageShoppingPath()) {
      this.cartInfo = loadCartFromStorage();
    }
    this.setError = this.setError.bind(this);
    this.setGenericError = this.setGenericError.bind(this);
    this.calculateTotalPrice = this.calculateTotalPrice.bind(this);
  }
  hydrate(data) {
    if (data) {
      delete data.bexApiSource;
      delete data.l10n;
      Object.assign(this, data);
    }
  }
  get multiItemSessionId() {
    var _a;
    return this.isPackageShoppingPath() ? (_a = this.multiItemContext) === null || _a === void 0 ? void 0 : _a.id : null;
  }
  mapCarTimeAndDateToDateTime(time, date = emptyDate) {
    return {
      hour: time ? Number(time.substring(0, 2)) : 0,
      minute: time ? Number(time.substring(2, 4)) : 0,
      second: 0,
      month: (date === null || date === void 0 ? void 0 : date.month) || 0,
      day: (date === null || date === void 0 ? void 0 : date.day) || 0,
      year: (date === null || date === void 0 ? void 0 : date.year) || 0
    };
  }
  getDefaultCarPickUpDropOffDateTime(date = emptyDate) {
    return {
      hour: 10,
      minute: 30,
      second: 0,
      month: (date === null || date === void 0 ? void 0 : date.month) || 0,
      day: (date === null || date === void 0 ? void 0 : date.day) || 0,
      year: (date === null || date === void 0 ? void 0 : date.year) || 0
    };
  }
  getCompressedCarTime(multiItemSearchContext, dateTimeField) {
    var _a, _b, _c;
    if ((_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.cars) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c[dateTimeField]) {
      return getLocKeyFromBexApiDateTime(multiItemSearchContext.cars[0].primary[dateTimeField]);
    }
    return null;
  }
  getFlightOrigin(multiItemSearchContext) {
    var _a, _b, _c, _d;
    if ((_d = (_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.flights) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.journeyCriterias) === null || _d === void 0 ? void 0 : _d[0]) {
      return multiItemSearchContext.flights[0].primary.journeyCriterias[0].origin;
    }
    return null;
  }
  getFlightNonStop(multiItemSearchContext) {
    var _a, _b, _c, _d, _e;
    return (_e = (_d = (_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.flights) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.searchPreferences) === null || _d === void 0 ? void 0 : _d.advancedFilters) === null || _e === void 0 ? void 0 : _e.includes(FlightsAdvancedFilterType.NONSTOP);
  }
  getFlightCabinClass(multiItemSearchContext) {
    var _a, _b, _c, _d, _e;
    return (_e = (_d = (_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.flights) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.searchPreferences) === null || _d === void 0 ? void 0 : _d.cabinClass) !== null && _e !== void 0 ? _e : null;
  }
  getFlightTripType(multiItemSearchContext) {
    var _a, _b, _c, _d;
    return (_d = (_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.flights) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.tripType) !== null && _d !== void 0 ? _d : null;
  }
  getInfantsInSeats(multiItemSearchContext) {
    var _a, _b, _c, _d;
    const infantSeats = (_d = (_c = (_b = (_a = multiItemSearchContext === null || multiItemSearchContext === void 0 ? void 0 : multiItemSearchContext.flights) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.travelers) === null || _d === void 0 ? void 0 : _d.filter(traveler => (traveler === null || traveler === void 0 ? void 0 : traveler.type) === TravelerDetailsType.INFANT_IN_SEAT);
    return Array.isArray(infantSeats) && infantSeats.length > 0 ? '1' : '0';
  }
  getPrimaryProductShoppingCriteriaInput(model) {
    var _a, _b, _c;
    const {
      properties,
      flights,
      cars
    } = this.buildMultiItemSearchContext(model);
    const propertyInput = (_a = properties === null || properties === void 0 ? void 0 : properties[0]) === null || _a === void 0 ? void 0 : _a.primary;
    const flightInput = (_b = flights === null || flights === void 0 ? void 0 : flights[0]) === null || _b === void 0 ? void 0 : _b.primary;
    const carInput = (_c = cars === null || cars === void 0 ? void 0 : cars[0]) === null || _c === void 0 ? void 0 : _c.primary;
    return {
      car: carInput,
      flight: flightInput,
      property: propertyInput
    };
  }
  buildMultiItemSearchContext(model) {
    var _a, _b;
    const startDate = model.startDate ? DateUtils.parseDateString(model.startDate) || emptyDate : emptyDate;
    const endDate = model.endDate ? DateUtils.parseDateString(model.endDate) || emptyDate : emptyDate;
    const rooms = model.rooms ? model.rooms.map(room => room.toJSON()) : [];
    const propertySearchCriteria = this.buildPropertySearchCriteria(startDate, endDate, model, rooms);
    const carsSearchCriteria = ((_a = this.multiItemContext) === null || _a === void 0 ? void 0 : _a.packageType) && this.multiItemContext.packageType !== PackageType.FH ? this.buildCarsSearchCriteria(model, startDate, endDate) : null;
    const flightsSearchCriteria = ((_b = this.multiItemContext) === null || _b === void 0 ? void 0 : _b.packageType) && this.multiItemContext.packageType !== PackageType.HC ? this.buildFlightsSearchCriteria(model, startDate, endDate) : null;
    return {
      properties: propertySearchCriteria,
      cars: carsSearchCriteria,
      flights: flightsSearchCriteria
    };
  }
  buildPropertySearchCriteria(startDate, endDate, model, rooms) {
    let newStartDate = startDate || emptyDate;
    let newEndDate = endDate || emptyDate;
    if (model.isPartialStay) {
      newEndDate = model.partialStayEndDate ? DateUtils.parseDateString(model.partialStayEndDate) || emptyDate : newEndDate;
      newStartDate = model.partialStayStartDate ? DateUtils.parseDateString(model.partialStayStartDate) || emptyDate : newStartDate;
    }
    return [{
      primary: {
        dateRange: {
          checkInDate: newStartDate,
          checkOutDate: newEndDate
        },
        destination: {
          regionName: model.destination || null,
          regionId: model.regionId || null
        },
        rooms
      }
    }];
  }
  buildCarsSearchCriteria(model, startDate, endDate) {
    return [{
      primary: {
        pickUpDateTime: model.pickUpTime ? this.mapCarTimeAndDateToDateTime(model.pickUpTime, startDate) : this.getDefaultCarPickUpDropOffDateTime(startDate),
        dropOffDateTime: model.dropOffTime ? this.mapCarTimeAndDateToDateTime(model.dropOffTime, endDate) : this.getDefaultCarPickUpDropOffDateTime(endDate),
        pickUpLocation: {
          regionId: model.regionId || null
        }
      }
    }];
  }
  buildFlightsSearchCriteria(model, startDate, endDate) {
    const flightTripType = model.tripType ? FlightsTripType[model.tripType] : FlightsTripType.ROUND_TRIP;
    return [{
      primary: {
        tripType: flightTripType,
        travelers: this.buildFlightsTravelers(model),
        journeyCriterias: this.buildFlightsJourneyCriteria(model, startDate, endDate, flightTripType),
        searchPreferences: this.buildFlightsSearchPreferences(model)
      }
    }];
  }
  buildFlightsSearchPreferences(model) {
    return {
      cabinClass: this.getFlightsCabinClass(model.cabinClass),
      airline: model.airlineCode,
      advancedFilters: model.directFlights === 'true' ? [FlightsAdvancedFilterType.NONSTOP] : null
    };
  }
  buildFlightsJourneyCriteria({
    origin,
    destination
  }, startDate, endDate, flightTripType) {
    const journeyCriterias = [];
    const realDestination = this.ttla ? this.ttla : destination;
    journeyCriterias.push({
      departureDate: startDate || emptyDate,
      origin: origin !== null && origin !== void 0 ? origin : SearchCriteriaModel.DEFAULTS.origin,
      destination: realDestination
    });
    if (flightTripType === FlightsTripType.ROUND_TRIP) {
      journeyCriterias.push({
        departureDate: endDate || emptyDate,
        origin: realDestination,
        destination: origin !== null && origin !== void 0 ? origin : SearchCriteriaModel.DEFAULTS.origin
      });
    }
    return journeyCriterias;
  }
  getFlightsCabinClass(cabinClass) {
    return cabinClass ? FlightsCabinClass[cabinClass] : FlightsCabinClass.COACH;
  }
  buildFlightsTravelers(model) {
    var _a;
    const travelers = [];
    (_a = model.rooms) === null || _a === void 0 ? void 0 : _a.forEach(room => {
      for (let i = 0; i < room.adults; i++) {
        travelers.push({
          age: null,
          type: TravelerDetailsType.ADULT
        });
      }
      if (room.children) {
        const children = room.children.filter(child => child.age >= 2);
        const infants = room.children.filter(child => child.age < 2);
        if (children) {
          children.forEach(({
            age
          }) => travelers.push({
            age,
            type: TravelerDetailsType.CHILD
          }));
        }
        if (infants) {
          const type = model.infantsInSeats === '1' ? TravelerDetailsType.INFANT_IN_SEAT : TravelerDetailsType.INFANT_IN_LAP;
          infants.forEach(({
            age
          }) => travelers.push({
            age,
            type
          }));
        }
      }
    });
    return travelers;
  }
  selectProducts(context, sessionId, priceToken, properties, flights, cars, dealMarker) {
    return __awaiter(this, void 0, void 0, function* () {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCHING);
      this.pageLoading = true;
      const omitTypename = (key, value) => key === '__typename' ? undefined : value;
      return this.bexApiSource.selectProducts({
        context: getBexApiContext(context),
        sessionId,
        priceToken,
        properties: JSON.parse(JSON.stringify(properties), omitTypename),
        flights,
        cars,
        dealMarker
      }).then(({
        data,
        errors
      }) => {
        if ((data === null || data === void 0 ? void 0 : data.multiItemShopping.selectProducts.__typename) === 'MultiItemSearchContextCreatedResponse' && (data === null || data === void 0 ? void 0 : data.multiItemShopping.selectProducts.redirectUrl)) {
          this.status = this.updateStatusWrapper(MultiItemStoreStatus.SELECT_PRODUCTS_SUCCESS);
          return Promise.resolve(data.multiItemShopping.selectProducts.redirectUrl);
        } else if ((data === null || data === void 0 ? void 0 : data.multiItemShopping.selectProducts.__typename) === 'MultiItemSearchContextErrorResponse' && (data === null || data === void 0 ? void 0 : data.multiItemShopping.selectProducts.message)) {
          this.errorMessage = data.multiItemShopping.selectProducts.message;
          this.pageLoading = false;
          return Promise.reject(errors || ['error response returned from selectProducts']);
        }
        this.pageLoading = false;
        return Promise.reject(errors || ['error while selecting products']);
      }).catch(error => {
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.SELECT_PRODUCTS_FAILURE, error);
        this.pageLoading = false;
        return Promise.reject(error);
      });
    });
  }
  initiateMultiItemSession(context, model) {
    return __awaiter(this, void 0, void 0, function* () {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCHING);
      return this.bexApiSource.initiateMultiItemSession({
        context: getBexApiContext(context),
        searchContext: this.buildMultiItemSearchContext(model)
      }).then(({
        data,
        errors
      }) => {
        var _a, _b;
        if ((data === null || data === void 0 ? void 0 : data.multiItemShopping.initiate.__typename) === 'MultiItemSearchContextCreatedResponse' && ((_a = data === null || data === void 0 ? void 0 : data.multiItemShopping.initiate.shoppingContext.multiItem) === null || _a === void 0 ? void 0 : _a.id) && ((_b = data === null || data === void 0 ? void 0 : data.multiItemShopping.initiate.shoppingContext.multiItem) === null || _b === void 0 ? void 0 : _b.packageType)) {
          this.status = this.updateStatusWrapper(MultiItemStoreStatus.INITIATE_SESSION_SUCCESS);
          const {
            id,
            packageType
          } = data.multiItemShopping.initiate.shoppingContext.multiItem;
          if (this.multiItemContext) {
            Object.assign(this.multiItemContext, {
              id,
              packageType: packageTypeMap[packageType]
            });
          }
          return Promise.resolve(id);
        } else if ((data === null || data === void 0 ? void 0 : data.multiItemShopping.initiate.__typename) === 'MultiItemSearchContextErrorResponse' && (data === null || data === void 0 ? void 0 : data.multiItemShopping.initiate.message)) {
          return Promise.reject(errors || ['error response returned from initiate']);
        }
        return Promise.reject(errors || ['error while initiating multiitem session']);
      }).catch(error => {
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.INITIATE_SESSION_FAILURE, error);
        return Promise.reject(error);
      });
    });
  }
  createMultiItemSession(context, model) {
    return __awaiter(this, void 0, void 0, function* () {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCHING);
      return this.bexApiSource.createMultiItemSession({
        context: getBexApiContext(context),
        multiItemSearchContext: this.buildMultiItemSearchContext(model)
      }).then(({
        data,
        errors
      }) => {
        if (data && data.createMultiItemSession && data.createMultiItemSession.__typename === 'MultiItemSearchContextCreatedResponse' && data.createMultiItemSession.shoppingContext && data.createMultiItemSession.shoppingContext.multiItem) {
          this.status = this.updateStatusWrapper(MultiItemStoreStatus.CREATE_SESSION_SUCCESS);
          const {
            id,
            packageType
          } = data.createMultiItemSession.shoppingContext.multiItem;
          if (this.multiItemContext) {
            Object.assign(this.multiItemContext, {
              id,
              packageType: packageTypeMap[packageType]
            });
          }
          return Promise.resolve(id);
        }
        return Promise.reject(errors || 'error while creating multiitem session');
      }).catch(error => {
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.CREATE_SESSION_FAILURE, error);
        return Promise.reject(error);
      });
    });
  }
  getCartDetails(context) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.cartInfo || !this.cartInfo.id) {
        return null;
      }
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCHING);
      return this.bexApiSource.fetchCart({
        context: getBexApiContext(context),
        id: this.cartInfo.id
      }).then(({
        data: value
      }) => {
        if (!value || !value.cart || !value.cart.items) {
          return Promise.reject('no items returned');
        }
        this.itemCount = value.cart.itemCount;
        this.items = value.cart.items.map(cartItemToInCartProperty(this.isMixMatchEnabled));
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCH_SUCCESS);
        return Promise.resolve(this.items);
      }).catch(error => {
        if (this.logger && typeof this.logger.logEvent === 'function') {
          this.status = this.updateStatusWrapper(MultiItemStoreStatus.FETCH_FAILURE, error);
        }
        if (error.toString().includes('CHECK_IN_DATE_IN_PAST_ERROR')) {
          this.resetCart();
        }
        return Promise.reject(error);
      });
    });
  }
  addPropertyToCart(context, propertyNaturalKeyInputs, cartPropertySearchContextInput, businessModel, analytics, isNoCC, newRoomIndex) {
    var _a;
    this.status = this.updateStatusWrapper(MultiItemStoreStatus.ADDING_PROPERTY);
    return (_a = this.getOrCreateCart(context, businessModel, analytics, isNoCC)) === null || _a === void 0 ? void 0 : _a.then(cartInfo => {
      if (cartInfo && cartInfo.type !== CollectionBusinessModel.NA && cartInfo.type !== businessModel) {
        return Promise.reject(INCOMPATIBLE_RATEPLAN);
      }
      if (this.isMixMatchEnabled && this.cartInfo) {
        this.cartInfo.type = businessModel;
      }
      const offerIndex = this.isMixMatchEnabled && newRoomIndex !== null && typeof newRoomIndex !== 'undefined' ? newRoomIndex : this.itemCount;
      return this.bexApiSource.addPropertyToCart({
        id: cartInfo.id,
        context: getBexApiContext(context),
        propertyOffer: {
          offerIndex,
          naturalKeys: propertyNaturalKeyInputs
        },
        cartPropertySearchContextInput
      }).then(({
        data: response
      }) => {
        if (!response || !response.cartItems || !response.cartItems.addProperty) {
          Promise.reject('add property - failed to parse response');
        }
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.ADDING_PROPERTY_SUCCESS);
        this.itemCount = (response === null || response === void 0 ? void 0 : response.cartItems.addProperty.itemCount) || 0;
        saveCartInStorage(this.cartInfo);
        return response;
      }).catch(error => {
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.ADDING_PROPERTY_FAILURE, error);
        return Promise.reject(error);
      });
    });
  }
  removeItemFromCart(context, itemId) {
    if (!this.cartInfo || !this.cartInfo.id) {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.REMOVE_PROPERTY_FAILURE, 'No Cart has been initialized yet');
      return Promise.reject('No Cart has been initialized yet');
    }
    const deletedIndex = this.items.findIndex(obj => obj.id === itemId);
    const deletedItem = this.items[deletedIndex];
    this.items = [...this.items.slice(0, deletedIndex), ...this.items.slice(deletedIndex + 1)];
    this.itemCount--; // eslint-disable-line
    return this.bexApiSource.removeItemFromCart({
      cartId: this.cartInfo.id,
      context: getBexApiContext(context),
      itemId
    }).then(({
      data: response
    }) => {
      var _a, _b;
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.REMOVE_PROPERTY_SUCCESS);
      this.itemCount = ((_b = (_a = response === null || response === void 0 ? void 0 : response.cartItems) === null || _a === void 0 ? void 0 : _a.remove) === null || _b === void 0 ? void 0 : _b.itemCount) || 0;
      return Promise.resolve(response);
    }).catch(error => {
      deletedItem && this.items.splice(deletedIndex, 0, deletedItem);
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.REMOVE_PROPERTY_FAILURE, error);
      this.itemCount++; // eslint-disable-line
      return Promise.reject(error);
    });
  }
  emptyCart(context) {
    if (!this.cartInfo || !this.cartInfo.id) {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.EMPTY_CART_FAILURE, 'No Cart has been initialized yet');
      return Promise.reject('No Cart has been initialized yet');
    }
    return this.bexApiSource.emptyCart({
      id: this.cartInfo.id,
      context: getBexApiContext(context)
    }).then(({
      data: response
    }) => {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.EMPTY_CART_SUCCESS);
      this.itemCount = (response === null || response === void 0 ? void 0 : response.cartItems.emptyCart.itemCount) || 0;
      if (this.cartInfo) {
        this.cartInfo.type = this.getDefaultBusinessModel();
      }
      saveCartInStorage(this.cartInfo);
      return Promise.resolve(response);
    }).catch(error => {
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.EMPTY_CART_FAILURE, error);
      return Promise.reject(error);
    });
  }
  prepareCheckout(context, properties) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.bexApiSource.prepareCheckout({
        context: getBexApiContext(context),
        cookies: '',
        properties
      }).then(({
        data: value
      }) => {
        if (!value || !value.prepareCheckout || !value.prepareCheckout.checkoutUrl) {
          return Promise.reject('bad response from prepare checkout');
        }
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.PREPARE_CHECKOUT_SUCCESS);
        return Promise.resolve({
          checkoutUrl: value.prepareCheckout.checkoutUrl
        });
      }).catch(error => Promise.reject(error));
    });
  }
  prepareCheckoutByCartId(context) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.cartInfo || !this.cartInfo.id) {
        return Promise.reject('No Cart has been initialized yet');
      }
      this.status = this.updateStatusWrapper(MultiItemStoreStatus.PREPARE_CHECKOUT_PROCESSING);
      const marketingChannelId = this.items[0] && this.items[0].marketingChannelId;
      const prepareCheckoutVariables = {
        context: getBexApiContext(context),
        id: this.cartInfo.id,
        totalPrice: this.calculateTotalPrice(),
        checkoutOptions: this.getCheckoutOptions()
      };
      if (marketingChannelId) {
        prepareCheckoutVariables.marketingChannelId = marketingChannelId;
      }
      return this.bexApiSource.prepareCheckoutByCartId(prepareCheckoutVariables).then(({
        data: value
      }) => {
        if (value && value.prepareCheckoutByCartId && value.prepareCheckoutByCartId.failureReason) {
          const failureReason = value.prepareCheckoutByCartId.failureReason;
          this.status = this.updateStatusWrapper(MultiItemStoreStatus.PREPARE_CHECKOUT_FAILURE, 'max rooms', {
            message: failureReason.message,
            groupLink: failureReason.groupLinkMessage || ''
          });
          return;
        }
        if (!value || !value.prepareCheckoutByCartId || !value.prepareCheckoutByCartId.checkoutUrl) {
          return Promise.reject('bad response from prepare checkout');
        }
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.PREPARE_CHECKOUT_SUCCESS);
        return Promise.resolve({
          checkoutUrl: value.prepareCheckoutByCartId.checkoutUrl
        });
      }).catch(error => {
        this.status = this.updateStatusWrapper(MultiItemStoreStatus.PREPARE_CHECKOUT_FAILURE, error);
        return Promise.reject(error);
      });
    });
  }
  removeUnavailableAndPrepareCheckout(context) {
    return this.removeAllUnavailable(context).then(() => this.prepareCheckoutByCartId(context));
  }
  removeAllUnavailable(context) {
    if (!this.items) {
      return Promise.reject('There are no items in the cart');
    }
    return Promise.all(this.items.map(item => this.removeItemFromCart(context, item.id)));
  }
  getOrCreateCart(context, cartType, analytics, isNoCC) {
    if (this.isServerSide()) {
      return null;
    }
    if (!this.cartInfo || !this.cartInfo.id) {
      this.cartInfo = loadCartFromStorage();
    }
    if (this.cartInfo === null) {
      if (isNoCC && this.itemCount === 0) {
        return Promise.reject(INCOMPATIBLE_RATEPLAN);
      }
      return this.createSessionCart(context, analytics).then(cartId => {
        let identifier = 'multiProp';
        if (this.isMixMatchEnabled) {
          identifier = 'mixAndMatch';
        }
        this.cartInfo = {
          id: cartId,
          timestamp: new Date().valueOf(),
          type: cartType ? cartType : this.getDefaultBusinessModel(),
          identifier
        };
        saveCartInStorage(this.cartInfo);
        return this.cartInfo;
      });
    }
    return Promise.resolve(this.cartInfo);
  }
  isPackageShoppingPath() {
    return Boolean(this.multiItemContext && this.multiItemContext.id && this.multiItemContext.packageType);
  }
  getPackageType() {
    var _a;
    if (this.isPackageShoppingPath()) {
      return ((_a = this.multiItemContext) === null || _a === void 0 ? void 0 : _a.packageType) || null;
    }
    return null;
  }
  isFlightPackage() {
    var _a;
    return this.isPackageShoppingPath() && ((_a = this.getPackageType()) === null || _a === void 0 ? void 0 : _a.includes('F')) || false;
  }
  updateSessionId(id) {
    if (id && this.isPackageShoppingPath() && this.multiItemContext) {
      this.multiItemContext.id = id;
    }
  }
  getExistingItems(roomIndex) {
    return this.items && this.items.filter(item => item.offerIndex === roomIndex);
  }
  createSessionCart(context, analytics) {
    return this.bexApiSource.createSessionCart({
      context: getBexApiContext(context),
      cartOptions: {
        ttl: CartTtl.SEVEN_DAYS
      }
    }).then(({
      data: cartMutation
    }) => {
      var _a;
      if (analytics) {
        analytics.trackEvent('MultiProp.Create', 'MultiProp Cart Created', null, null, true);
      }
      return (_a = cartMutation === null || cartMutation === void 0 ? void 0 : cartMutation.createSessionCart) === null || _a === void 0 ? void 0 : _a.id;
    });
  }
  setError(error) {
    this.error = error;
  }
  setGenericError() {
    this.errorMessage = this.genericError;
  }
  setTtla(ttla) {
    this.ttla = ttla;
  }
  get genericError() {
    return `${this.l10n.formatText('hotels.infosite.errorMessage.text')} ${this.l10n.formatText('hotels.infosite.tryAgain.text')}`;
  }
  isServerSide() {
    return typeof window === 'undefined'; // eslint-disable-line
  }
  updateStatusWrapper(status, error, userError) {
    return updateStatus(this.logger, this.setError, status, this.l10n, error ? error.toString() : '', userError);
  }
  buildContext(query = {}) {
    this.multiItemContext = buildMultiItemContext(query);
  }
  get isFreshCart() {
    return this.cartInfo && (!this.items || this.items.length === 0);
  }
  get isTouchedCart() {
    return this.cartInfo && this.items && this.items.length > 0;
  }
  isDisambigSearch() {
    return typeof this.referrer !== 'undefined' && this.referrer.includes('Disambig');
  }
  get showMultiPropExperience() {
    return this.cartInfo && this.cartInfo.identifier !== 'mixAndMatch' && this.cartInfo.id;
  }
  get showMixMatchExperience() {
    return this.cartInfo && this.cartInfo.id && this.isMixMatchEnabled;
  }
  resetCart() {
    if (this.cartInfo) {
      this.cartInfo.type = this.getDefaultBusinessModel();
    }
    saveCartInStorage(this.cartInfo);
  }
  removeMixAndMatchCart() {
    clearMixAndMatchCartFromStorage();
    this.cartInfo = {
      id: '',
      timestamp: 0,
      type: CollectionBusinessModel.EC,
      identifier: ''
    };
    this.itemCount = 0;
  }
  calculateTotalPrice() {
    const amount = this.items.reduce(total => total, 0);
    return {
      amount
    };
  }
  getDefaultBusinessModel() {
    return this.isMixMatchEnabled ? CollectionBusinessModel.NA : CollectionBusinessModel.EC;
  }
  getCheckoutOptions() {
    return this.items && this.items[0] && [{
      type: CheckoutOptionType.FENCING_ATTRIBUTES,
      value: ''
    }];
  }
}
__decorate([observable], MultiItemStore.prototype, "itemCount", void 0);
__decorate([observable], MultiItemStore.prototype, "items", void 0);
__decorate([observable], MultiItemStore.prototype, "status", void 0);
__decorate([observable], MultiItemStore.prototype, "error", void 0);
__decorate([observable], MultiItemStore.prototype, "cartInfo", void 0);
__decorate([observable], MultiItemStore.prototype, "errorMessage", void 0);
__decorate([observable], MultiItemStore.prototype, "multiItemContext", void 0);
__decorate([observable], MultiItemStore.prototype, "ttla", void 0);
__decorate([observable], MultiItemStore.prototype, "pageLoading", void 0);
__decorate([computed], MultiItemStore.prototype, "multiItemSessionId", null);
__decorate([action], MultiItemStore.prototype, "selectProducts", null);
__decorate([action], MultiItemStore.prototype, "initiateMultiItemSession", null);
__decorate([action], MultiItemStore.prototype, "createMultiItemSession", null);
__decorate([action], MultiItemStore.prototype, "getCartDetails", null);
__decorate([action], MultiItemStore.prototype, "addPropertyToCart", null);
__decorate([action], MultiItemStore.prototype, "removeItemFromCart", null);
__decorate([action], MultiItemStore.prototype, "emptyCart", null);
__decorate([action], MultiItemStore.prototype, "prepareCheckout", null);
__decorate([action], MultiItemStore.prototype, "prepareCheckoutByCartId", null);
__decorate([action], MultiItemStore.prototype, "removeUnavailableAndPrepareCheckout", null);
__decorate([action], MultiItemStore.prototype, "updateSessionId", null);
__decorate([action], MultiItemStore.prototype, "setGenericError", null);
__decorate([action], MultiItemStore.prototype, "setTtla", null);
__decorate([computed], MultiItemStore.prototype, "isFreshCart", null);
__decorate([computed], MultiItemStore.prototype, "isTouchedCart", null);
__decorate([computed], MultiItemStore.prototype, "showMultiPropExperience", null);
__decorate([computed], MultiItemStore.prototype, "showMixMatchExperience", null);