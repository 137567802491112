var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { ChildModel } from './child-model';
export class RoomModel extends Store {
  constructor(state = {}) {
    super({}, NOOP_LOGGER);
    this.adults = 0;
    this.children = [];
    this.setState(state);
  }
  setState(state) {
    var _a, _b, _c;
    this.adults = (_a = state === null || state === void 0 ? void 0 : state.adults) !== null && _a !== void 0 ? _a : RoomModel.DEFAULTS.adults;
    this.children = (_c = (_b = state === null || state === void 0 ? void 0 : state.children) === null || _b === void 0 ? void 0 : _b.map(child => new ChildModel(child))) !== null && _c !== void 0 ? _c : RoomModel.DEFAULTS.children;
  }
  clone() {
    return new RoomModel({
      adults: this.adults,
      children: this.children
    });
  }
  hydrate(state) {
    this.setState(state);
  }
}
RoomModel.DEFAULTS = {
  adults: 2,
  children: []
};
__decorate([observable], RoomModel.prototype, "adults", void 0);
__decorate([observable], RoomModel.prototype, "children", void 0);